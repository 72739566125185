import { POST_QUESTIONNAIRES, PUT_QUESTIONNAIRES, GET_QUESTIONNAIRES, DELETE_QUESTIONNAIRES, CLEAR_QUESTIONNAIRES, CLEAR_QUESTIONNAIRES_SUCCESS, POST_QUESTIONNAIRE_ANSWERS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_QUESTIONNAIRES:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_QUESTIONNAIRES:
            return {
                ...state,
                success: `${POST_SUCCESS} question`
            }
        case POST_QUESTIONNAIRE_ANSWERS:
            return {
                ...state,
                success: `${POST_SUCCESS} question`
            }
        case PUT_QUESTIONNAIRES:
            return {
                ...state,
                success: `${PUT_SUCCESS} question`
            }
        case DELETE_QUESTIONNAIRES:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} question(s).`
            }
        case CLEAR_QUESTIONNAIRES:
            return {
                ...state,
                data: {},
                success: ""
            }
        case CLEAR_QUESTIONNAIRES_SUCCESS:
            return {
                ...state,
                success: ""
            }
        default:
            return state;
    }
}
import isEmpty from './isEmpty';

const buildQueryDelete = (ids) => {
  let query = [];

  if(!isEmpty(ids)) {
    ids.forEach(id => {
        query.push({ field: "id", value: id });
    });
  }

  return query;
}

export default buildQueryDelete;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import styles from './AdminNavBar.css';

class AdminNavBar extends Component {

     toggleAdminSideBar = (open) => () => {
         this.setState({
            adminSideBarOpen: open,
         });
         this.props.adminSideBarToggle(open);
     };

     render() {
         const { classes } = this.props;
         return (
             <div>
                 <AppBar position="static" className={classes.appBar} elevation={0}>
                     <Toolbar variant="dense">
                             {this.props.children} 
                     </Toolbar>
                 </AppBar>
             </div>
         );
     }
}

AdminNavBar.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    nav: state.nav
})

export default compose(
    withStyles(styles, { name: 'AdminNavBar' }),
    connect(mapStateToProps),
)(AdminNavBar);


import { CLEAR_ADDRESSES_SUCCESS, GET_ADDRESSES_SELECT, POST_ADDRESSES, PUT_ADDRESSES } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_ADDRESSES_SELECT:
            return {
                ...state,
                data: action.payload
            }
        case PUT_ADDRESSES:
            return {
                ...state,
                data: action.payload,
                success: PUT_SUCCESS
            }
        case POST_ADDRESSES:
            return {
                ...state,
                data: action.payload,
                success: POST_SUCCESS
            }
        case CLEAR_ADDRESSES_SUCCESS:
            return {
                ...state,
                success: ''
            }
        default:
            return state;
    }
}
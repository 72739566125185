import { GET_NOTE_MESSAGE_TYPES, CLEAR_NOTE_MESSAGE_TYPES_SUCCESS } from '../_actions/types';

const initialState = {
    data: {},
    success: null
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_NOTE_MESSAGE_TYPES:
            return {
                ...state,
                data: action.payload,
                success: true
            }
        case CLEAR_NOTE_MESSAGE_TYPES_SUCCESS: 
            return {
                ...state,
                data: {},
                success: null
            }
        default: 
            return state;
    }
}
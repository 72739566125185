import {
    GET_HUBS,
    POST_HUBS,
    PUT_HUBS,
    DELETE_HUBS,
    CLEAR_HUBS,
    CLEAR_HUBS_SUCCESS
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HUBS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_HUBS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} hub`
            }
        case PUT_HUBS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} hub`
            }
        case DELETE_HUBS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} hubs.`
            }
        case CLEAR_HUBS:
            return {
                ...state,
                data: {},
                success: ""
            }
        case CLEAR_HUBS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        default:
            return state;
    }
}
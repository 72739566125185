import isEmpty from './isEmpty';
import { PAGE_SIZE_DEFAULT } from '../_constants/apiConstants';

const buildQueryMeta = (meta) => {
  let query = [];

  if (isEmpty(meta)) return query;

  //by default we always fetch a page one with page size default if page or pagesize is empty
  let page = !isEmpty(meta.page) ? meta.page : 1;
  let pageSize = !isEmpty(meta.pageSize) ? meta.pageSize : PAGE_SIZE_DEFAULT;
  query.push({ field: "page", value: page });
  query.push({ field: "pageSize", value: pageSize });

  if (!isEmpty(meta.search)) {
    query.push({ field: "search", value: meta.search });
  }

  if (!isEmpty(meta.exportExcel)) {
    query.push({ field: "exportExcel", value: meta.exportExcel });
  }

  if (!isEmpty(meta.sortDirection) && !isEmpty(meta.sortBy)) {
    query.push({ field: "sortby", value: meta.sortBy });
    query.push({ field: "sortdirection", value: meta.sortDirection });
  }

  if (!isEmpty(meta.filters)) {
    meta.filters.forEach(filter => {
      if (!isEmpty(filter.value)) {
        query.push({ field: filter.field, value: filter.value });
      }
    });
  }
  return query;
}

export default buildQueryMeta;
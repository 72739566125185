import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { getCountries } from 'wumdrophubsreactshared/_actions/countries';
import isEmpty from "wumdrophubsreactshared/_utils/isEmpty";

const defaultCountry = { key: 'ZA', flag: '🇿🇦', value: '🇿🇦 South Africa (+27)' };
const CountryPicker = ({ countries, getCountries, onChange, name }) => {
    const [value, setValue] = useState(defaultCountry);
    const [callingCodes, setCallingCodes] = useState([]);
    const options = callingCodes.map(item => ({ ...item, label: item.value }));

    useEffect(() => {
        let array = [];
        if (!isEmpty(countries?.data)) {
            array = countries.data.map(country => {
                return {
                    name:name,
                    key: country.cca2,
                    flag: country.flag,
                    value: `${country.flag} ${country.commonName} (+${country.callingCode})`
                }
            });
            setCallingCodes(array);
        } else {
            getCountries();
        }
    }, [countries, getCountries, name]);

    const handleChange = (selected) => {
        setValue(selected);
        if (onChange)
            onChange(selected);
    }

    return (
        <Select
            name={name}
            options={options}
            isSearchable={true}
            styles={customSelectStyles}
            placeholder="Select"
            onChange={handleChange}
            value={{ ...value, label: value.flag }}
        />
    )
}

let customSelectStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: 0,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: state.isFocused ? '2px solid black !important' : '1px solid black',
        zIndex: state.isFocused ? 1000 : 0,
        marginBottom:10,
        boxShadow: '0 !important',
        width: 250
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    dropdownIndicator: (base) => ({
        ...base,
        display: 'none'
    }),
    // placeholder: (base) => ({
    //     ...base,
    //     color: 'rgba(0, 0, 0, 0.40)'
    // }),
    input: (base) => ({
        ...base,
        alignItems: 'center',
        display: 'flex',
        ':before': {
            content: '"  "',
            marginLeft: -3,
            height: 25,
            width: 25
        },
        '& input': {
            font: 'inherit',
        },
        marginBottom:-1,
    }),
    menu: base => ({
        ...base,
        zIndex: 1,
        minWidth: 350
    }),
    // option: (base) => ({
    //     ...base,
    //     paddingLeft: 5,
    //     textAlign: 'left',
    //     justContent: 'left'
    // }),
    // noOptionsMessage: (base) => ({
    //     ...base,
    //     color: 'rgba(0, 0, 0, 0.40)'
    // }),
    // loadingMessage: (base) => ({
    //     ...base
    // }),
    singleValue: (base) => ({
        ...base,
        marginLeft: -5,
        marginBottom: -5,
        fontSize: 20
    })
};


const mapStateToProps = (state) => ({
    countries: state.countries
})
export default connect(mapStateToProps, { getCountries })(CountryPicker);
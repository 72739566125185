import React, { Component } from 'react';
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import axios from 'axios';
import { API_BASE_URL } from '../../../wumdrophubsreactshared/_constants/apiConstants';
import { FONT_FAMILY, SMALL_FONT } from '../../../wumdrophubsreactshared/_constants/styleConstants';
import { MINIMUM_SEARCH_CHARACTERS } from '../../../wumdrophubsreactshared/_constants/selectConstants';
import withStyles from '@material-ui/core/styles/withStyles'
import _ from 'lodash';
import Icon from "@material-ui/core/Icon";
import styles from './TimePickerSelect.css';

let customSelectStyles = {
    control: (base, state) => ({
        ...base,
        fontFamily: FONT_FAMILY,
        borderRadius: 0,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: '1px solid hsl(0,0%,80%) !important',
        boxShadow: '0 !important'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    placeholder: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY,
        fontSize: SMALL_FONT,
        color: 'rgba(0, 0, 0, 0.40)',
        marginLeft: 23,
    }),
    input: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY,
        fontSize: SMALL_FONT,
        alignItems: 'center',
        display: 'flex',
        ':before': {
            content: '" "',
            marginLeft: -3,
            height: 25,
            width: 25
        },
    }),
    menu: base => ({
        ...base,
        fontFamily: FONT_FAMILY,
        zIndex: "2"
    }),
    option: (base) => ({
        ...base,
        paddingLeft: 25,
        fontFamily: FONT_FAMILY,
        textAlign: 'left',
        justContent: 'left'
    }),
    noOptionsMessage: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY,
        color: 'rgba(0, 0, 0, 0.40)'
    }),
    loadingMessage: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY
    }),
    singleValue: (base) => ({
        ...base,
        paddingLeft: 23,
        fontFamily: FONT_FAMILY,
        fontSize: SMALL_FONT
    })
};

const CancelToken = axios.CancelToken;
const source = CancelToken.source();


class TimePickerSelect extends Component {

    constructor(props) {
        super(props);
        let multi = false;
        if (!isEmpty(this.props.multiSelect)) {
            multi = this.props.multiSelect;
        }

        let value = "";
        if (!isEmpty(this.props.value)) {
            value = this.props.value;
        }

        let dKey = "key";
        let dValue = "value";
        if (!isEmpty(this.props.displayKey)) {
            dKey = this.props.displayKey;
        }

        if (!isEmpty(this.props.displayValue)) {
            dValue = this.props.displayValue;
        }

        let placeholder = "Search...";
        if (!isEmpty(this.props.placeholder)) {
            placeholder = this.props.placeholder;
        }


        this.state = {
            inputValue: '',
            data: [],
            noOptions: "Enter search term...",
            isMultiSelect: multi,
            value: value,
            displayKey: dKey,
            displayValue: dValue,
            placeholder: placeholder,
            menuIsOpen: !isEmpty(this.props.menuIsOpen) ? this.props.menuIsOpen : false
        }
    }

    componentWillUnmount() {
        source.cancel('Select to unmounted');
    }

    handleInputChange = (newValue) => {
        
        //const inputValue = newValue.replace(/\W/g, '');
        if (newValue.length === 0 || isEmpty(newValue)) {
            this.setState({ noOptions: "Enter search term..." });
        } else if (newValue.length < MINIMUM_SEARCH_CHARACTERS) {
            this.setState({ noOptions: "Minimum of " + MINIMUM_SEARCH_CHARACTERS + " characters for a search..." });
            return "";
        }
        this.setState({ newValue });
        return newValue;
    };

    handleChange = (selected) => {
        this.setState({ menuIsOpen: false }, function(){
            let filter = {};
            filter.field = this.props.fieldName;
            filter.value = !isEmpty(selected) ? selected[this.state.displayKey] : null;

            this.setState({ value: selected, tempValue: null }, function(){
                this.props.onSelect(filter, selected).then(() => {
                    this.closeAndBlur();
                });
            });
        });
    }

    UNSAFE_componentWillReceiveProps(nextValue) {
        this.setState({ data: nextValue.values })
        if (isEmpty(nextValue.values)) {
            this.setState({ noOptions: "No matching results." })
        }
        if (!isEmpty(nextValue.multiSelect) && nextValue.multiSelect !== this.props.multiSelect) {
            this.setState({ isMultiSelect: nextValue.multiSelect });
        }
    }

    openAndFocus = () =>
    {
      this.setState({ menuIsOpen: true }, function(){
          this.select.focus();
      });
    };
  
    closeAndBlur()
    {
      this.setState({ menuIsOpen: false }, function(){
          this.select.blur();
      });
    };

    loadOptions = (inputValue, callback) => {
        return this.getSelectData(inputValue, callback);
    };

    getSelectData = (inputValue, callback) => {
        // let filter = "";
        // if (!isEmpty(this.props.filter)) {
        //     filter = "&" + this.props.filter.key + "=" + this.props.filter.value;
        // }
        if(!isEmpty(this.props.date))
        {
            return axios.get(API_BASE_URL + "/" + this.props.apiEntity + `?localDate=${this.props.date.key}`)
            .then((res) => {
                if (isEmpty(res.data)) {
                    this.setState({ noOptions: "No matching results." });
                }
                callback(res.data);
            })
            .catch(err => {
                this.setState({ noOptions: "Not found! No matching results." });
                callback("");
            });
        }
        
    }

    onFocus(e)
    {
        const { value } = this.state;

        if(!isEmpty(value))
        {
            this.setState({ value: null, tempValue: value}, function(){
                this.setState({ menuIsOpen: true});
            });
        }
        else
        {
            this.setState({ menuIsOpen: true});
        }
    }

    onBlur(e)
    {
        const { value, tempValue } = this.state;

        if(isEmpty(value) && !isEmpty(tempValue))
        {
            this.setState({ value: tempValue, tempValue: null, menuIsOpen: false});
        }
        else
        {
            this.setState({ menuIsOpen: false });
        }
    }

    render() {
        const { classes, date, disabled = false } = this.props;
        const { noOptions, value, displayKey, displayValue, placeholder, menuIsOpen } = this.state;

        return (
            <div className={classes.selectContainer}>
                <Icon className={classes.inputIcon} fontSize="small" alt="Time Icon">{"access_time"}</Icon>
                <AsyncSelect
                    ref={ref => {
                        this.select = ref;
                    }}
                    key={JSON.stringify(date)}
                    isDisabled={isEmpty(this.props.date) || disabled}
                    isSearchable={false}
                    styles={customSelectStyles}
                    cacheOptions
                    menuIsOpen={menuIsOpen}
                    value={value}
                    loadOptions={_.debounce(this.loadOptions, 500)}
                    onInputChange={_.debounce(this.handleInputChange, 500)}
                    onChange={this.handleChange}
                    onBlur={this.onBlur.bind(this)}
                    onFocus={this.onFocus.bind(this)}
                    defaultOptions
                    placeholder={placeholder}
                    isMulti={this.state.isMultiSelect}
                    noOptionsMessage={() => noOptions}
                    getOptionLabel={(values) => values[displayValue]}
                    getOptionValue={(values) => values[displayKey]}
                    isClearable={true}
                />
                <section className={classes.underline} />
            </div>
        );
    }
}

TimePickerSelect.propTypes = {
    apiEntity: PropTypes.string.isRequired
};

export default withStyles(styles)(TimePickerSelect);
import isEmpty from '../_utils/isEmpty';
import { CONTACT_TYPE_ENUMS } from '../_constants/apiConstants';
import formatDisplayAddress from './formatDisplayAddress';

const mapQuoteToDetails = (quoteResponse) => {
    let detailsValues = {
        originAddressType: '',
        originContactName: '',
        originContactCountry: '',
        originPhoneNumber: '',
        originBuildingName: '',
        originBuildingUnit: '',
        originRemarks: '',
        destinationAddressType: '',
        destinationContactName: '',
        destinationContactCountry: '',
        destinationPhoneNumber: '',
        destinationBuildingName: '',
        destinationBuildingUnit: '',
        destinationRemarks: '',
        orderReference: '',
        customerIdentifier: '',
        originContactDetailsSameAsUser: true,
        destinationContactDetailsSameAsUser: false,
        originContactId: null,
        destinationContactId: null,
        originDisplayAddress: '',
        destinationDisplayAddress: '',
        originStoreId: null,
        destinationStoreId: null,
    }
    if (!isEmpty(quoteResponse)) {
        detailsValues = {
            deliveryId: quoteResponse.deliveryId,
            originAddressType: quoteResponse.originAddressType ? quoteResponse.originAddressType : CONTACT_TYPE_ENUMS.RESIDENTIAL,
            originContactName: quoteResponse.originContactName,
            originContactCountry: quoteResponse.originContactCountry,
            originPhoneNumber: quoteResponse.originPhoneNumber,
            originBuildingName: quoteResponse.originBuildingName,
            originBuildingUnit: quoteResponse.originBuildingUnit,
            originRemarks: quoteResponse.originRemarks,
            destinationAddressType: quoteResponse.destinationAddressType ? quoteResponse.destinationAddressType : CONTACT_TYPE_ENUMS.RESIDENTIAL,
            destinationContactName: quoteResponse.destinationContactName,
            destinationContactCountry: quoteResponse.destinationContactCountry,
            destinationPhoneNumber: quoteResponse.destinationPhoneNumber,
            destinationBuildingName: quoteResponse.destinationBuildingName,
            destinationBuildingUnit: quoteResponse.destinationBuildingUnit,
            destinationRemarks: quoteResponse.destinationRemarks,
            orderReference: quoteResponse.orderReference,
            customerIdentifier: quoteResponse.customerIdentifier,
            originContactDetailsSameAsUser: quoteResponse.originContactDetailsSameAsUser,
            destinationContactDetailsSameAsUser: quoteResponse.destinationContactDetailsSameAsUser,
            originContactId: quoteResponse.originContactId,
            destinationContactId: quoteResponse.destinationContactId,
            originDisplayAddress: quoteResponse.originAddress ? formatDisplayAddress(quoteResponse.originAddress) : '',
            destinationDisplayAddress: quoteResponse.destinationAddress ? formatDisplayAddress(quoteResponse.destinationAddress) : '',
            originStoreId: quoteResponse.originStoreId,
            destinationStoreId: quoteResponse.destinationStoreId
        }
    }

    return detailsValues;
}
export default mapQuoteToDetails;
export default theme => ({
    selectContainer: {
      margin: "0 0 15px 0",
      padding: 0
    },
    inputIcon: {
      height: "33px",
      position: "absolute",
      zIndex: "1",
      marginTop: 5
    }
  });


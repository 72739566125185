export const REPORT_CUSTOMER_DELIVERIES_NAME = "Customer Deliveries.xlsx";
export const REPORT_DELIVERY_EXCEPTIONS_NAME = "Delivery Exceptions.xlsx";
export const REPORT_GROSS_PROFITS_NAME = "Gross Profits.xlsx";
export const REPORT_DRIVER_STATISTICS_NAME = "Driver Stats.xlsx";
export const REPORT_DISTANCE_STATISTICS_NAME = "Distance Stats.xlsx";
export const REPORT_PARCEL_STATISTICS_NAME = "Parcel Stats.xlsx";
export const REPORT_SERVICE_LEVELS_NAME = "Service Levels.xlsx";
export const REPORT_DRIVER_PERFORMANCE_NAME = "KPI Performance.xlsx";
export const REPORT_DELIVERY_SUMMARY_NAME = "Delivery Summary.xlsx";
export const REPORT_DELIVERY_TERMINATION_NAME = "Delivery Termination.xlsx";
export const REPORT_DELIVERY_ARRIVAL_NAME = "Delivery Arrival.xlsx";
export const REPORT_DISTANCE_DISCOVERY_NAME = "Distance Discovery.xlsx";
export const REPORT_DRIVER_AUTO_ASSIGN_EFF_NAME = "Driver Auto Assign Efficiency.xlsx";
export const REPORT_DRIVER_ANALYSIIS_NAME = "Driver Analysis.xlsx";
export const REPORT_DRIVER_SCHEDULE = "Driver Schedule.xlsx";
export const REPORT_HUB_DELIVERY_MOVEMENT_NAME= "Hub Deliveries.xlsx";

export const ARCHIVE_CUSTOMER_DELIVERIES_NAME = "Archived Customer Deliveries";
export const ARCHIVE_PARTNER_DELIVERIES_NAME = "Archived Partner Deliveries";
export const ARCHIVE_PARTNER_JOBS_NAME = "Archived Partner Jobs";

export const TRANSACTIONS_NAME = "Transactions.xlsx";
export const RATES_NAME = "Rates.xlsx";
export const ACCOUNTS_NAME = "Accounts.xlsx";
export const DELIVERIES_NAME = "Deliveries.xlsx";
export const JOBS_NAME = "Jobs.xlsx";
export const COMPANIES_NAME = "Companies.xlsx";
export const STORES_NAME = "Stores.xlsx";
export const USERS_NAME = "Users.xlsx";
export const VEHICLES_NAME = "Vehicles.xlsx";
export const DRIVERS_NAME = "Drivers.xlsx";
export const ASSISTANTS_NAME = "Assistants.xlsx";
export const RATINGS_NAME = "Ratings.xlsx";
export const TRANSACTION_ITEMS_NAME = "Items.xlsx";
export const JOB_ROUTES_NAME = "JobRoutes";
export const NOTIFICATION_DEVICES_NAME = "NotificationDevices.xlsx";
export const BANK_ACCOUNTS_NAME = "BankAccounts.xlsx";
export const MOBILE_DEVICES_NAME = "MobileDevices.xlsx";
export const QUESTIONNAIRES_NAME = "Questionnaires.xlsx";
export const DRIVER_SCHEDULE_NAME = "Driver Schedule.xlsx";
export const TERRITORY_AREAS_NAME = "Territory Areas.xlsx";
export const DRIVER_VISIBILITY_NAME = "Driver_Visibility_Report.xlsx"

export const EXCEL_TYPE = { type: "application/ms-excel" };

export const EXCEL_EXTENSION = "xlsx";
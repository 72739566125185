import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../wumdrophubsreactshared/_constants/styleConstants';

export default theme => ({
    root: {
      flexGrow: 1,
    },
    flex: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
      '&:hover': {
        color: SECONDARY_COLOR
     },
     '&:focus': {
        outline: 'none'
      }
    },
    appBar: {
        backgroundColor: PRIMARY_COLOR
    },
    appBarButton: {
        '&:hover': {
           color: SECONDARY_COLOR
        },
        '&:focus': {
            outline: 'none'
        }
    },
    logoLink: {
        textDecoration: 'none'
    },
    flag:{
        position:'relative',
        left: '-5px',
        top: '-15px',
        textDecoration: 'none'
    }
});
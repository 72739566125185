import React, { Fragment } from 'react';
import { compose } from 'react-recompose';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import Icon from "@material-ui/core/Icon";

import withStyles from "@material-ui/core/styles/withStyles";
import combineStyles from "wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "App.css";
import styles from './ParcelItems.css';

import Grid from '@material-ui/core/Grid';
// core components
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ParcelIcon from '../../icons/ParcelIcon.svg';
import Divider from '@material-ui/core/Divider';
import { LOGISTIC_STATUS_ENUMS } from '../../../wumdrophubsreactshared/_constants/apiConstants';
import getGuid from '../../../wumdrophubsreactshared/_utils/getGuid';
import ParcelModal from 'components/delivery-wizard/step2/ParcelModal';
import { Box, CircularProgress, Tooltip } from '@material-ui/core';
import Barcode from 'react-barcode';
import getParcelsCountString from 'wumdrophubsreactshared/_utils/getParcelsCountString';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

class ParcelItems extends React.Component {

    constructor(props) {
        super(props);
        let customModalRef = getGuid();

        this.state = {
            children: [],
            activeTile: 0,
            customModalRef: customModalRef,
            customModalOpen: false,
            isCustom: true,
            refreshProps: false,
            loading: false,
            selectedParcel: {}
        }
    }

    parcelEditModalSubmit = (parcel) => {
        if (this.props.updateParcelDetails) {
            this.props.updateParcelDetails(parcel);
            this.setState({ customModalOpen: false, loading: true });
        }
    }

    parcelEditModalClose = () => {
        this.setState({ customModalOpen: false, selectedParcel: {} });
    }

    handleEditParcel = (parcel) => {
        parcel.deliveryItemName = parcel.name;
        //parcel.name = PARCEL_TYPES_ENUMS.CUSTOM;
        this.setState({ customModalOpen: true, isCustom: false, selectedParcel: parcel });
    }

    checkErrors(index, fieldName) {
        const { errors } = this.props;
        return !isEmpty(errors) ?
            !isEmpty(errors.parcels[index]) ?
                !isEmpty(errors.parcels[index][fieldName]) ?
                    true : false
                : false
            : false
    }

    displayParcel(parcel, index) {
        const { classes, handleBlur, readOnly, isForDetailsPanel, logisticStatusId, canEdit } = this.props;
        let refCode = "";
        if (!isEmpty(parcel.description)) {
            refCode = parcel.description;
        }

        return (
            <HtmlTooltip
                key={index}
                title={
                    parcel.barCode ?
                        <Fragment>
                            <Barcode value={parcel.barCode} height={50} width={1} fontSize={12} />
                        </Fragment>
                        : <></>
                }
            >
                <div key={index}>
                    {!readOnly &&
                        <Grid container justifyContent="center" className={classes.parcelContainer}>

                            <Grid item xs={2} sm={1} md={1} className={classes.parcelItem}>
                                <img src={ParcelIcon} alt="Parcel Icon" className={classes.parcelItemIcon} />
                            </Grid>

                            <Grid item xs={10} sm={11} md={3} className={classes.parcelItem}>
                                <Grid container >
                                    <Grid item md={12} className={classes.parcelItemText}>
                                        {parcel.name}
                                    </Grid>
                                    <Grid item md={12} className={classes.parcelItemSubText}>
                                        {parcel.length} x {parcel.width} x {parcel.height} (cm) • max: {parcel.weight} kg
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3} className={classes.parcelItem}>
                                <TextField
                                    id="description"
                                    name="description"
                                    error={this.checkErrors(index, "description")}
                                    value={refCode}
                                    variant="outlined"
                                    label="Description"
                                    className={classes.refCode}
                                    fullWidth
                                    onChange={(e) => this.props.handleParcelChange(e, index)}
                                    onBlur={handleBlur}
                                    margin="dense"
                                    InputProps={{
                                        classes: {
                                            input: classes.descriptionLabel
                                        }
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.descriptionLabel
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={9} md={3} className={classes.parcelItem}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="isFragile"
                                            name="isFragile"
                                            checked={parcel.isFragile}
                                            color="primary"
                                            onChange={(e) => this.props.handleCheckBoxChange(e, index)}
                                            onBlur={handleBlur}

                                        />
                                    }
                                    labelPlacement="start"
                                    label="Is it fragile:"
                                    classes={{ label: classes.fragileLabel }}
                                />
                            </Grid>

                            <Grid item xs={1} md={1} className={classes.parcelItem}>
                                <IconButton size="small" color="secondary" onClick={() => this.props.handleUpdateParcel(parcel)} className={classes.moreButton}>
                                    <Icon>edit</Icon>
                                </IconButton>
                            </Grid>

                            <Grid item xs={2} sm={1} md={1} className={classes.parcelItem}>
                                <IconButton size="small" color="secondary" onClick={() => this.props.handleRemoveParcel(parcel)} className={classes.deleteButton}>
                                    <Icon>remove_circle</Icon>
                                </IconButton>
                            </Grid>
                        </Grid>}

                    {readOnly &&
                        <Grid container className={classes.parcelContainer}>
                            <Grid item xs={2} sm={1} md={1} lg={1} className={isForDetailsPanel === true ? classes.parcelItemWidthSet : classes.parcelItemWidthAuto}>
                                <img src={ParcelIcon} className={classes.parcelItemIcon} style={{ paddingLeft: 0 }} alt="Parcel Icon" />
                            </Grid>
                            <Grid item xs={8} sm={9} md={9} lg={9} className={classes.parcelItem}>
                                <Grid container >
                                    <Grid item md={12} className={classes.parcelItemText}>
                                        {parcel.name} <span className={classes.parcelItemSubText}>{!isEmpty(parcel.description) && ` • ${parcel.description}`}{!isEmpty(parcel.barCode) && ` • ${parcel.barCode}`}</span>
                                    </Grid>
                                    <Grid item md={12} className={`${classes.parcelItemSubText} ${classes.parcelSummaryItemSubText}`}>
                                        {parcel.length} x {parcel.width} x {parcel.height} (cm) • max: {parcel.weight} kg
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} md={1} className={classes.parcelItem}>
                                {
                                    (logisticStatusId >= LOGISTIC_STATUS_ENUMS.READY && logisticStatusId !== LOGISTIC_STATUS_ENUMS.DELIVERED) && !isEmpty(canEdit) && canEdit &&  // only edit parcel ids 
                                    <IconButton size="small" color="secondary" onClick={() => this.handleEditParcel(parcel)} className={classes.moreButton}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                }

                            </Grid>
                        </Grid>
                    }
                    <Divider key={index + "d"} />
                </div>
            </HtmlTooltip>
        );
    }


    componentDidUpdate(prevProps) {
        if (!isEmpty(prevProps.parcels) && !isEmpty(this.props.parcels) && prevProps.parcels.length !== this.props.parcels.length) {
            this.setState({ loading: false });
        }

        if (!isEqual(prevProps.parcels, this.props.parcels)) {
            this.setState({ loading: false });
        }

        if (!isEqual(prevProps.errors, this.props.errors)) {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading } = this.state;

        const { classes, parcels, canEdit, isTrackingDelivery } = this.props;

        const { customModalOpen, selectedParcel, isCustom, customModalRef } = this.state;

        return (
            !isEmpty(parcels) && (
                <div>
                    {loading ?
                        <div className={classes.progress}>
                            <CircularProgress />
                        </div> :
                        isTrackingDelivery ?
                            <Box
                                display="flex"
                                direction="row"
                                justifyContent="flex-start"
                                marginTop={3}
                            >
                                <img src={ParcelIcon} alt="Parcel Icon" className={classes.parcelItemIconTracking} />
                                <Typography className={classes.parcelDetailsTracking}>
                                    <span>{parcels.length} parcel{parcels.length > 1 && "s"}</span> <span className={classes.parcelDetails}>( {getParcelsCountString(parcels)} )</span>
                                </Typography>
                            </Box> :
                            <div>
                                <Paper className={classes.parcelsContainer}>
                                    {parcels.map((parcel, index) => this.displayParcel(parcel, index))}
                                </Paper>

                                <Typography className={classes.parcelSummary}>{parcels.length} parcel{parcels.length > 1 && "s"} <span className={classes.parcelDetails}>({getParcelsCountString(parcels)})</span></Typography>
                            </div>
                    }
                    {
                        !isEmpty(canEdit) && canEdit === true &&
                        <ParcelModal
                            isCustom={isCustom}
                            customModalOpen={customModalOpen}
                            initialValues={selectedParcel}
                            customModalRef={customModalRef}
                            parcelModalClose={this.parcelEditModalClose}
                            parcelModalSubmit={this.parcelEditModalSubmit}
                            disableDimensionFields={true}
                        />
                    }

                </div>
            )
        );
    }
}


const combinedStyles = combineStyles(styles, appStyles);

export default compose(
    withStyles(combinedStyles, { withTheme: true }, { name: 'ParcelItems' }),
    withWidth(),
)(ParcelItems);

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { CALL_TO_ACTION_GREEN_COLOR, PRIMARY_COLOR, WHITE_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';

function ConfirmationDialog({ confirmDisabled, open, onCloseClick, onConfirmClick, title, children, loading, subtitle, fullWidth, maxWidth }) {
  const classes = useStyles();

  function handleConfirm() {
    onConfirmClick();
    if (isEmpty(loading))
      onCloseClick();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onCloseClick}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        fullWidth={!isEmpty(fullWidth) ? fullWidth : false}
        maxWidth={!isEmpty(maxWidth) ? maxWidth : false}
      >
        <DialogTitle className={classes.title}>{title} <span className={classes.subtitle}><br />{subtitle}</span></DialogTitle>

        <DialogContent className={classes.content}>
          {children}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button fullWidth color="primary" onClick={onCloseClick} variant="contained">
            No
          </Button>
          <Button fullWidth onClick={handleConfirm} disabled={isEmpty(confirmDisabled) ? loading : confirmDisabled} variant="contained" className={classes.btnYes}>
            {loading ? <CircularProgress size={25} /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles({
  dialog: {
    minWidth: 350
  },
  title: {
    color: WHITE_COLOR,
    background: PRIMARY_COLOR,
    fontWeight: "bold !important",
    textAlign: "center",
    padding: 20
  },
  content: {
    marginTop: 25
  },
  subtitle: {

  },
  actions: {
    justifyContent: "center",
    textAlign: "center",
    padding: 25
  },
  btnYes: {
    background: CALL_TO_ACTION_GREEN_COLOR,
    color: WHITE_COLOR
  }
});

export default ConfirmationDialog;
import {
    GET_HUB_DELIVERIES,
    CLEAR_HUB_DELIVERIES,
    POST_HUB_DELIVERIES,
    PUT_HUB_DELIVERIES,
    CLEAR_HUB_DELIVERIES_SUCCESS,
    DELETE_HUB_DELIVERIES
} from '../_actions/types';
import { DELETE_SUCCESS, POST_SUCCESS, PUT_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case POST_HUB_DELIVERIES:
            return {
                ...state,
                success: `${POST_SUCCESS} hub delviery(s)`
            }
        case PUT_HUB_DELIVERIES:
            return {
                ...state,
                success: `${PUT_SUCCESS} hub delviery(s)`
            }
        case DELETE_HUB_DELIVERIES:
            return {
                ...state,
                success: `${DELETE_SUCCESS} hub delviery`
            }
        case GET_HUB_DELIVERIES:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case CLEAR_HUB_DELIVERIES:
            return {
                ...state,
                data: {},
                success: ""
            }
        case CLEAR_HUB_DELIVERIES_SUCCESS:
            return {
                ...state,
                success: ''
            }
        default:
            return state;
    }
}
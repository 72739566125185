import React from 'react';
import { compose } from 'react-recompose';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import ItemsCarousel from 'react-items-carousel';
import Icon from "@material-ui/core/Icon";
import AddBoxIcon from '@material-ui/icons/AddBox';

import withStyles from "@material-ui/core/styles/withStyles";
import combineStyles from "wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "App.css";
import styles from './PackagePicker.css';

import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ParcelIcon from '../../icons/ParcelIcon.svg';
import Divider from '@material-ui/core/Divider';
import { PARCEL_TYPES_ENUMS } from '../../../wumdrophubsreactshared/_constants/apiConstants';
import { CircularProgress } from '@material-ui/core';
import getGuid from '../../../wumdrophubsreactshared/_utils/getGuid';
import ParcelModal from './ParcelModal';
import ParcelItems from '../../common/summary-panels/ParcelItems';
import { Alert } from '@material-ui/lab';

const customParcel = { id: 4, name: PARCEL_TYPES_ENUMS.CUSTOM, height: "?", width: "?", length: "?", weight: "?" };

class PackagePicker extends React.Component {

    constructor(props) {
        super(props);
        let customModalRef = getGuid();

        this.state = {
            activeTile: 0,
            customModalRef: customModalRef,
            customModalOpen: false,
            isCustom: true,
            refreshProps: false,
            selectedParcel: {}
        }
    }


    handleParcelChange = (event, index) => {
        const { values, setValues } = this.props;
        let newValues = { ...values };
        let newParcelBag = newValues.parcels.map(parcel => Object.assign({}, parcel));
        newParcelBag[index][event.target.name] = event.target.value;
        newValues.parcels = newParcelBag;

        if (setValues) {
            setValues(newValues);
        }
    }

    handleCheckBoxChange = (event, index) => {
        const { values, setValues } = this.props;
        let newValues = Object.assign({}, values);
        let newParcelBag = newValues.parcels.map(parcel => Object.assign({}, parcel));
        newParcelBag[index][event.target.name] = event.target.checked;
        newValues.parcels = newParcelBag;

        if (setValues) {
            setValues(newValues);
        }
    }

    handleParcelSelect = (parcel) => {
        if (parcel.name === PARCEL_TYPES_ENUMS.CUSTOM) {
            this.setState({ customModalOpen: true, isCustom: true, selectedParcel: { name: parcel.name } });
            return;
        }
        this.handleAddParcel(parcel);
    }

    handleAddParcel = (parcel) => {
        const { deliveryId, addParcel } = this.props;
        //NB! Here "parcel.name" refers to the parcelType.name (mapped from the parcelTypes key in redux)
        if (parcel.name === PARCEL_TYPES_ENUMS.CUSTOM) {
            //CUSTOM parcels
            parcel.parcelType = null;
            parcel.name = parcel.deliveryItemName;
        } else {
            //regular SMALL, MEDIUM, LARGE
            parcel.deliveryItemName = parcel.name;
            parcel.parcelType = parcel.name; //NB! Here "parcel.name" refers to the parcelType.name (mapped from the parcelTypes key in redux)
        }

        //nb! delivery ID needs to be added 
        parcel.deliveryId = deliveryId;

        if (!isEmpty(addParcel)) {
            addParcel(parcel, deliveryId);
        }
    }

    handleUpdateParcel = (parcel) => {
        parcel.deliveryItemName = parcel.name;
        //parcel.name = PARCEL_TYPES_ENUMS.CUSTOM;
        this.setState({ customModalOpen: true, isCustom: false, selectedParcel: parcel });
    }

    handleRemoveParcel = (parcel) => {
        const { deliveryId, removeParcel, isDetailsEditor } = this.props;
        if (!isEmpty(removeParcel)) {
            if (isEmpty(isDetailsEditor) || !isDetailsEditor) {
                removeParcel(deliveryId, parcel.id);
            } else {
                removeParcel(parcel);
            }

        }
    }

    parcelModalFormikSubmit = (values, formikActions) => {
        let barCodeExists = this.props.values.parcels.filter(parcel => !isEmpty(parcel.barCode) && parcel.barCode === values.barCode && parcel.id !== values.id);
        if (!isEmpty(barCodeExists)) {
            formikActions.setErrors({ barCode: "error", barCodeExist: "Barcode already added." });
            return;
        }
        values.name = PARCEL_TYPES_ENUMS.CUSTOM;
        if (this.state.isCustom) {
            this.handleAddParcel(values);
        } else {
            if (!isEmpty(this.props.updateParcel)) {
                if (values.parcelType === PARCEL_TYPES_ENUMS.CUSTOM) {
                    //CUSTOM parcels
                    values.parcelType = null;
                }
                values.name = values.deliveryItemName;

                this.props.updateParcel(values);
            }
        }
        this.setState({ customModalOpen: false });
    }

    parcelModalClose = () => {
        this.setState({ customModalOpen: false, selectedParcel: {} });
    }

    checkErrors(index, fieldName) {
        const { errors } = this.props;
        return !isEmpty(errors) ?
            !isEmpty(errors.parcels[index]) ?
                !isEmpty(errors.parcels[index][fieldName]) ?
                    true : false
                : false
            : false
    }

    displayParcel(parcel, index) {
        const { classes, handleBlur } = this.props;
        let refCode = "";
        if (!isEmpty(parcel.description)) {
            refCode = parcel.description;
        }

        return (
            <div key={index}>
                <Grid container justifyContent="center" className={classes.parcelContainer}>

                    <Grid item xs={2} sm={1} md={1} className={classes.parcelItem}>
                        <img src={ParcelIcon} alt="Parcel Icon" className={classes.parcelItemIcon} />
                    </Grid>

                    <Grid item xs={10} sm={11} md={3} className={classes.parcelItem}>
                        <Grid container >
                            <Grid item md={12} className={classes.parcelItemText}>
                                {parcel.name}
                            </Grid>
                            <Grid item md={12} className={classes.parcelItemSubText}>
                                {parcel.width} x {parcel.height} x {parcel.length} (cm) • max: {parcel.weight} kg
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={3} className={classes.parcelItem}>
                        <TextField
                            id={`description${index}`}
                            name="description"
                            error={this.checkErrors(index, "description")}
                            value={refCode}
                            variant="outlined"
                            label="Description"
                            className={classes.refCode}
                            fullWidth
                            onChange={(e) => this.handleParcelChange(e, index)}
                            onBlur={handleBlur}
                            margin="dense"
                            InputProps={{
                                classes: {
                                    input: classes.descriptionLabel
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.descriptionLabel
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={9} md={3} className={classes.parcelItem}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={`isFragile${index}`}
                                    name="isFragile"
                                    checked={parcel.isFragile}
                                    color="primary"
                                    onChange={(e) => this.handleCheckBoxChange(e, index)}
                                    onBlur={handleBlur}

                                />
                            }
                            labelPlacement="start"
                            label="Is it fragile:"
                            classes={{ label: classes.fragileLabel }}
                        />
                    </Grid>

                    <Grid item xs={1} md={1} className={classes.parcelItem}>
                        <IconButton size="small" color="secondary" onClick={() => this.handleUpdateParcel(parcel)} className={classes.moreButton}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </Grid>

                    <Grid item xs={2} sm={1} md={1} className={classes.parcelItem}>
                        <IconButton size="small" color="secondary" onClick={() => this.handleRemoveParcel(parcel)} className={classes.deleteButton}>
                            <Icon>remove_circle</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider key={index + "d"} />
            </div>

        );
    }

    changeActiveTile = (activeTile) => {
        this.setState({ activeTile })
    }

    buildParcelSummary(parcels) {
        let packages = {
            small: 0,
            medium: 0,
            large: 0,
            custom: 0
        };

        if (!isEmpty(parcels)) {
            parcels.forEach((parcels) => {
                if (parcels.parcelType === PARCEL_TYPES_ENUMS.SMALL) {
                    packages.small += 1;
                }
                if (parcels.parcelType === PARCEL_TYPES_ENUMS.MEDIUM) {
                    packages.medium += 1;
                }
                if (parcels.parcelType === PARCEL_TYPES_ENUMS.LARGE) {
                    packages.large += 1;
                }
                if (parcels.parcelType === PARCEL_TYPES_ENUMS.CUSTOM) {
                    packages.custom += 1;
                }
            })
        }

        let packageDetailsLabel = "";

        if (packages.small > 0 || packages.medium > 0 || packages.large > 0 || packages.custom > 0) {

            //package details subtext
            if (packages.small > 0) {
                packageDetailsLabel += `${packages.small} small`;
                if (packages.medium > 0 || packages.large > 0 || packages.custom > 0) {
                    packageDetailsLabel += ", ";
                }
            }

            if (packages.medium > 0) {
                packageDetailsLabel += `${packages.medium} medium`;
                if (packages.large > 0 || packages.custom > 0) {
                    packageDetailsLabel += ", ";
                }
            }

            if (packages.large > 0) {
                packageDetailsLabel += `${packages.large} large`;
                if (packages.custom > 0) {
                    packageDetailsLabel += ", ";
                }
            }

            if (packages.custom > 0) {
                packageDetailsLabel += `${packages.custom} custom`;
            }
        }

        return packageDetailsLabel;
    }

    render() {
        const {
            activeTile,
            isCustom,
            customModalOpen,
            customModalRef,
            selectedParcel
        } = this.state;

        const {
            classes,
            values,
            errors,
            parcelTypes,
            loading } = this.props;

        let numberOfCards = 3;
        if (isWidthUp('sm', this.props.width))
            numberOfCards = 4;

        let parcelTypesWithCustom = !isEmpty(parcelTypes) && !parcelTypes.loading ? parcelTypes.data.concat([customParcel]) : [];

        return (

            <div>
                {
                    <div>
                        {!isEmpty(parcelTypesWithCustom) &&
                            <Typography className={classes.deliveryStepHeader}>
                                Add your consignment
                            </Typography>
                        }

                        <ItemsCarousel
                            // Carousel configurations
                            //gutter={1}
                            numberOfCards={numberOfCards}
                            showSlither={true}
                            firstAndLastGutter={true}
                            freeScrolling={false}

                            // Active item configurations
                            requestToChangeActive={this.changeActiveTile}
                            activeItemIndex={activeTile}
                            activePosition={'center'}

                            chevronWidth={24}
                            rightChevron={<IconButton className={classes.tileButton}><Icon>navigate_next</Icon></IconButton>}
                            leftChevron={<IconButton className={classes.tileButton}><Icon>navigate_before</Icon></IconButton>}
                            outsideChevron={false}

                        // enablePlaceholder={parcelTypes.loading}
                        // placeholderItem={<div className={classes.progress}> <CircularProgress /> </div>}
                        >
                            {
                                parcelTypesWithCustom.map((parcel, index) =>
                                    <Button key={index} variant="outlined" onClick={() => this.handleParcelSelect(parcel)} className={classes.sliderTile} disabled={loading}>
                                        <Grid container justifyContent="center">
                                            <Grid item xs={12}>
                                                <AddBoxIcon className={!loading ? classes.addIcon : classes.addIconGrey} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <img src={ParcelIcon} alt="Parcel Icon" className={classes.tileIcon} />
                                            </Grid>
                                            <Grid item xs={10} className={classes.tileHeading}>
                                                {parcel.name}
                                            </Grid>
                                            <Grid item xs={12} className={classes.tileText} style={{ textTransform: "lowercase" }}>
                                                {parcel.width} x {parcel.height} x {parcel.length} (cm)
                                                <div />Max: {parcel.weight} kg
                                            </Grid>
                                        </Grid>
                                    </Button>
                                )
                            }
                        </ItemsCarousel>


                        <div className={classes.parcelContent}>

                            {
                                loading ?
                                    <div className={classes.progress}>
                                        <CircularProgress />
                                    </div>
                                    :
                                    !isEmpty(values.parcels) &&
                                    <ParcelItems
                                        readOnly={false}
                                        parcels={values.parcels}
                                        errors={this.props.errors}
                                        handleParcelChange={this.handleParcelChange}
                                        handleCheckBoxChange={this.handleCheckBoxChange}
                                        handleUpdateParcel={this.handleUpdateParcel}
                                        handleRemoveParcel={this.handleRemoveParcel}
                                    />
                            }

                            {!isEmpty(errors) && isEmpty(values.parcels) && !loading &&
                                <Alert severity="error">
                                    {errors.parcels}
                                </Alert>
                            }
                            {isEmpty(errors) && isEmpty(values.parcels) && !loading &&
                                <Alert severity="info">
                                    Please select a parcel size.
                                </Alert>
                            }

                            <ParcelModal isCustom={isCustom} customModalOpen={customModalOpen} initialValues={selectedParcel} customModalRef={customModalRef} parcelModalClose={this.parcelModalClose} parcelModalSubmit={this.parcelModalFormikSubmit} parcelTypesData={this.props.parcelTypes.data} />
                        </div>
                    </div>}
            </div>
        );
    }
}

const combinedStyles = combineStyles(styles, appStyles);

export default compose(
    withStyles(combinedStyles, { withTheme: true }, { name: 'PackagePicker' }),
    withWidth()
)(PackagePicker);

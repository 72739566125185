import { GET_COUNTRY_BY_COUNTRYCODE } from '../_actions/types';

const initialState = {
    data: {}
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_COUNTRY_BY_COUNTRYCODE:
            return {    
                ...state,
                data: action.payload
            }
        default: 
            return state;
    }
}
import { GET_DASHBOARD_DRIVER_SORTS } from '../_actions/types';

const initialState = {
    data: {}
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_DASHBOARD_DRIVER_SORTS:
            return {
                ...state,
                data: action.payload
            }
        default: 
            return state;
    }
}
import { GET_WEBHOOKS, POST_WEBHOOKS, PUT_WEBHOOKS, CLEAR_WEBHOOKS, DELETE_WEBHOOKS, CLEAR_WEBHOOKS_SUCCESS, PING_WEBHOOKS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';


const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_WEBHOOKS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case PING_WEBHOOKS:
            return {
                ...state,
                success: action.payload
            }
        case POST_WEBHOOKS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} webhook`
            }
        case PUT_WEBHOOKS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} webhook`
            }
        case DELETE_WEBHOOKS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} webhooks.`
            }
        case CLEAR_WEBHOOKS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_WEBHOOKS:
            return {
                ...state,
                data: {},
                success: ''
            }
        default:
            return state;
    }
}
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, hasPermission, ...rest }) => (

    <Route {...rest} render={props => {

        // if you are not authenticated we reroute you to the login page 
        // with the route that you came from in the url so we can bring back once you've logged in
        const prevLocation = (props.history && props.history.location) ? { ...props.history.location } : null;
        const prevRoute = prevLocation && prevLocation.pathname ? prevLocation.pathname : "";

        return (
            localStorage.getItem('jwtToken') ?
                hasPermission === true ?
                    <Component {...props} />
                    :
                    hasPermission === undefined ?
                        <Redirect to={{ pathname: '/confirm-email', state: { from: props.location } }} />
                        :
                        <Redirect to={{ pathname: '/forbidden', state: { from: props.location } }} />
                : <Redirect to={{ pathname: `/login${prevRoute}`, state: { from: props.location } }} />
        )
    }} />
)

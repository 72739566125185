import { GET_SUPERSET_DASHBOARD_LIST, GET_SUPERSET_GEUST_TOKEN, GET_SUPERSET_EMBEDDED_ID } from '../_actions/types';

const initialState = {
    supersetDashboardList: null,
    setelctedDashboardId: null,
    guestToken: null,
    embeddedId: null,
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_SUPERSET_DASHBOARD_LIST:
            return {
                ...state,
                supersetDashboardList: action.payload
            }
        case GET_SUPERSET_GEUST_TOKEN:
            return {
                ...state,
                guestToken: action.payload
            }
        case GET_SUPERSET_EMBEDDED_ID:
            return {
                ...state,
                embeddedId: action.payload
            }
        default:
            return state;
    }
}
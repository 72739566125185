import { FONT_FAMILY } from 'wumdrophubsreactshared/_constants/styleConstants';
import { COMPLEMENTARY_5_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';
import { PRIMARY_COLOR } from "../../wumdrophubsreactshared/_constants/styleConstants";

const primaryColor = [
  PRIMARY_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR,
];
const secondaryColor = ["#FF5153"];
const warningColor = [
  "#ff9800",
  "#ffa726",
  "#fb8c00",
  "#ffa21a",
  "#fcf8e3",
  "#faf2cc",
  "#ffe0b2",
  "#ffb74d"
];
const dangerColor = [
  "#f44336",
  "#ef5350",
  "#e53935",
  "#f55a4e",
  "#f2dede",
  "#ebcccc",
  "ef9a9a",
  "#ef5350"
];
const successColor = [
  "#4caf50",
  "#66bb6a",
  "#43a047",
  "#5cb860",
  "#dff0d8",
  "#d0e9c6",
  "#a5d6a7",
  "#66bb6a"
];
const infoColor = [
  COMPLEMENTARY_5_COLOR,
  COMPLEMENTARY_5_COLOR,
  COMPLEMENTARY_5_COLOR,
  COMPLEMENTARY_5_COLOR,
  COMPLEMENTARY_5_COLOR,
  COMPLEMENTARY_5_COLOR,
  COMPLEMENTARY_5_COLOR,
  COMPLEMENTARY_5_COLOR
];
const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#f8bbd0", "#f06292"];
const grayColor = [
  "#999",
  "#3C4858",
  "#eee",
  "#343434",
  "#585858",
  "#232323",
  "#ddd",
  "#6c757d",
  "#333",
  "#212121",
  "#777",
  "#D2D2D2",
  "#AAA",
  "#495057",
  "#e5e5e5",
  "#555",
  "#f9f9f9",
  "#ccc",
  "#444",
  "#f2f2f2",
  "#89229b",
  "#c0c1c2",
  "#9a9a9a",
  "#f5f5f5",
  "#505050",
  "#1f1f1f"
];
const whiteColor = "#FFF";
const blackColor = "#000";
const twitterColor = "#55acee";
const facebookColor = "#3b5998";
const googleColor = "#dd4b39";
const linkedinColor = "#0976b4";
const pinterestColor = "#cc2127";
const youtubeColor = "#e52d27";
const tumblrColor = "#35465c";
const behanceColor = "#1769ff";
const dribbbleColor = "#ea4c89";
const redditColor = "#ff4500";
const instagramColor = "#125688";

const feedback = {
  color: dangerColor[0] + " !important",
  fontFamily: FONT_FAMILY + " !important",
  fontSize: "10px",
  marginTop: "-10px"
}


export {
  //variables
  primaryColor,
  secondaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  whiteColor,
  blackColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  instagramColor,
  feedback
};


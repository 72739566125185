import { GET_TRANSACTION_ITEMS, CLEAR_TRANSACTION_ITEMS, CLEAR_TRANSACTION_ITEMS_SUCCESS, POST_TRANSACTION_ITEMS, SET_LOADING_TRANSACTION_ITEMS } from '../_actions/types';
import { POST_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: "",
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TRANSACTION_ITEMS:
            return {
                ...state,
                data: action.payload,
                search: action.search,
                loading: false
            }
        case POST_TRANSACTION_ITEMS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} transaction item`,
                loading: false
            }
        case CLEAR_TRANSACTION_ITEMS:
            return {
                ...state,
                data: {},
                loading: false
            }
        case CLEAR_TRANSACTION_ITEMS_SUCCESS:
            return {
                ...state,
                success: "",
            }

        case SET_LOADING_TRANSACTION_ITEMS:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
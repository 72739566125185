import {
  BLACK_COLOR,
  SMALL_FONT,
  SLATE_COLOR,
  TERTIARY_COLOR,
  LARGE_FONT,
  FONT_FAMILY_HEADER,
  PRIMARY_COLOR,
  FONT_FAMILY,
  TINY_FONT} from "wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from '@material-ui/core/styles/colorManipulator';
export default theme => ({
 
  //Step header for mobile
  deliveryStepHeader: {
    fontFamily: FONT_FAMILY_HEADER,
    fontWeight: "200",
    fontSize: SMALL_FONT,
    paddingBottom: 0,
    color: PRIMARY_COLOR,
    fontStyle: "italic",
    marginBottom: 9,
    // [theme.breakpoints.up('md')]: {
    //   display: 'none'
    // }
  },
  stepHeader: {
    fontFamily: FONT_FAMILY_HEADER,
    fontWeight: "bold",
    paddingBottom: 20,
    paddingLeft: 6,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  stepTitle: {
    color: TERTIARY_COLOR
  },
  price: {
    color: BLACK_COLOR,
    fontSize: LARGE_FONT,
    fontWeight: "bold"
  },
  expiryText: {
    color: TERTIARY_COLOR,
    fontSize: TINY_FONT
  },
  timeIcon: {
    color: lighten(BLACK_COLOR, 0.1),
    fontSize: LARGE_FONT,
    marginRight: 2,
    marginLeft: 2,
    marginBottom: -3
  },
  timeHours: {
    color: PRIMARY_COLOR,
    fontSize: TINY_FONT,
    fontFamily: FONT_FAMILY
  },
  timeHoursLarge: {
    color: PRIMARY_COLOR,
    fontSize: SMALL_FONT,
    fontFamily: FONT_FAMILY
  },
  timeDetailsHours: {
    minWidth: 220,
    color: PRIMARY_COLOR,
    fontSize: TINY_FONT,
    fontFamily: FONT_FAMILY
  },
  deliveryRemarks: {
    fontFamily: FONT_FAMILY,
    fontSize: TINY_FONT
  },
  slaTitle: {
    fontWeight: 600,
    color: SLATE_COLOR,
    [theme.breakpoints.down('sm')]: {
      lineHeight: "30px"
    }
  },
  dateText: {
    fontSize: SMALL_FONT,
    color: lighten(BLACK_COLOR, 0.4),
    marginTop: 2
  },
  displayAddress: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    //margin: '5px 0px 0px 5px',
    fontSize: "0.85em",
    fontWeight: 600
  },
  displayAddressWrap: {
    fontSize: SMALL_FONT,
    fontWeight: 600,
    paddingRight: 12,
  },
  displayDetailsAddressWrap: {
    fontSize: TINY_FONT,
    fontWeight: 700,
  },
  inputIconsColor: {
    color: BLACK_COLOR,
    fontSize: "17px",
    margin: "5px 5px -5px 0"
  },
  infoIcons: {
    color: BLACK_COLOR,
    fontSize: "17px",
  },
  marginTop15: {
    marginTop: 15,
  },
  textTotal:{
    color: BLACK_COLOR,
    fontFamily: FONT_FAMILY,
    fontSize: TINY_FONT,
    fontWeight: 100,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
    }
  },
  textValue: {
    float: "right",
    [theme.breakpoints.down('xs')]: {
      paddingRight: 15,
      float: "right",
    }
  },
  textPadding: {
    paddingLeft: "0 !important",
  },
  hideElement: {
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  },
  deliveryTitle: {
    fontWeight: "bold",
    padding: "17px 0 12px 9px",
  },
  deliverySubTitle: {
    color: TERTIARY_COLOR,
    fontWeight: 600,
  },
  stepSubTitle: {
    fontSize: SMALL_FONT,
  },
  gridItemPadding: {
    paddingRight: 45,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    }
  },
  setGridWidthAsIs: {
    //leave class here, must be empty
  },
  maxGridWidth: {
    maxWidth: '100%',
    flexBasis: '100%'
  }
});
// utils/GoogleAnalytics.js
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { Route } from "react-router-dom";
import { getConfig } from "./config";
const config = getConfig();
const gaId = `${config.REACT_APP_GOOGLE_ANALYTICS_ID}`;

class GoogleAnalytics extends Component {
    componentDidMount() {
        this.logPageChange(
            this.props.location.pathname,
            this.props.location.search
        );
    }

    componentDidUpdate({ location: prevLocation }) {
        const {
            location: { pathname, search },
        } = this.props;
        const isDifferentPathname = pathname !== prevLocation.pathname;
        const isDifferentSearch = search !== prevLocation.search;

        if (isDifferentPathname || isDifferentSearch) {
            this.logPageChange(pathname, search);
        }
    }

    logPageChange(pathname, search = "") {
        const page = pathname + search;
        const { location } = window;
        ReactGA.set({
            page,
            location: `${location.origin}${page}`,
            ...this.props.options,
        });
        ReactGA.send({ hitType: "pageview", page: `${location.origin}${page}`, title: page});
    }

    render() {
        return null;
    }
}

GoogleAnalytics.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
    }).isRequired,
    options: PropTypes.object,
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const Event = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

const init = (options = {}) => {
    
    ReactGA.initialize(gaId);

    return true;
};

export default {
    GoogleAnalytics,
    RouteTracker,
    init,
    Event,
};

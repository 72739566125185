const toRad = function (number) {
   return number * Math.PI / 180;
}

const toDeg = function (number) {
   return number * 180 / Math.PI;
}

const getPositionPoint = (coordinate, brng, dist) => {
   dist = dist / 6371;
   brng = toRad(brng);

   let lat1 = toRad(coordinate.lat), lon1 = toRad(coordinate.lng);

   let lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) +
      Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));

   let lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(dist) *
      Math.cos(lat1),
      Math.cos(dist) - Math.sin(lat1) *
      Math.sin(lat2));

   if (isNaN(lat2) || isNaN(lon2)) return null;

   return { lat: toDeg(lat2), lng: toDeg(lon2) };
}

export default getPositionPoint;
import { GET_PARTNER_APPLICATIONS, CLEAR_PARTNER_APPLICATIONS, CLEAR_PARTNER_APPLICATIONS_SUCCESS, DELETE_PARTNER_COMPANIES } from '../_actions/types';
import { DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_PARTNER_APPLICATIONS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case CLEAR_PARTNER_APPLICATIONS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_PARTNER_APPLICATIONS:
            return {
                ...state,
                data: {},
                success: ''
            }
        case DELETE_PARTNER_COMPANIES:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} partner companies.`
            }
        default:
            return state;
    }
}
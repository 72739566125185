import isEmpty from './isEmpty';
import { PAGE_SIZE_DEFAULT } from '../_constants/apiConstants';

const buildPageMeta = (
    entity = {},
    filters = [],
    page = 1,
    pageSize = PAGE_SIZE_DEFAULT,
    search = "",
    sortDirection = "",
    sortBy = "",
) => {
    if (!isEmpty(entity) && !isEmpty(entity.data)) {
        const { paging, sorting, search: entitySearch, filters: entityFilters } = entity.data;
        
        page = getPage(paging, page);
        pageSize = getPageSize(paging, pageSize);
        sortBy = getSortBy(sorting, sortBy);
        sortDirection = getSortDirection(sorting, sortDirection);
        search = getSearch(entitySearch, search);
        filters = getFilters(entityFilters, filters);
    }

    return {
        page: page || 1,
        pageSize: pageSize || PAGE_SIZE_DEFAULT,
        sortDirection: sortDirection || "asc",
        sortBy: sortBy || "",
        search: search || "",
        filters: filters || []
    };
};

const getPage = (paging, defaultPage) => {
    return !isEmpty(paging) && !isEmpty(paging.page) ? paging.page : defaultPage;
};

const getPageSize = (paging, defaultPageSize) => {
    return !isEmpty(paging) && !isEmpty(paging.pageSize) ? paging.pageSize : defaultPageSize;
};

const getSortBy = (sorting, defaultSortBy) => {
    return !isEmpty(sorting) && !isEmpty(sorting.sortBy) ? sorting.sortBy : defaultSortBy;
};

const getSortDirection = (sorting, defaultSortDirection) => {
    return !isEmpty(sorting) && !isEmpty(sorting.sortDirection) ? sorting.sortDirection : defaultSortDirection;
};

const getSearch = (entitySearch, defaultSearch) => {
    return !isEmpty(entitySearch) ? entitySearch : defaultSearch;
};

const getFilters = (entityFilters, defaultFilters) => {
    return !isEmpty(entityFilters) && isEmpty(defaultFilters) ? entityFilters : defaultFilters;
};

export default buildPageMeta;

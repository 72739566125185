import buildPageMeta from "wumdrophubsreactshared/_utils/buildPageMeta";
import isEmpty from "wumdrophubsreactshared/_utils/isEmpty";

export function reloadPagedData(props, func, tableType) {
    let adminTableFilter = props.nav.adminTableMeta[tableType];
    let meta = buildPageMeta();
    if (!isEmpty(adminTableFilter)) {
        meta = buildPageMeta({},
            adminTableFilter.filters,
            adminTableFilter.page,
            adminTableFilter.pageSize,
            adminTableFilter.search,
            adminTableFilter.sortDirection,
            adminTableFilter.sortBy);
    }
    func(meta);
}

import { PUT_TASKS_WORKFLOW, GET_TASKS_WORKFLOW, CLEAR_TASKS_WORKFLOW_SUCCESS } from '../_actions/types';
import { PUT_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_TASKS_WORKFLOW:
            return {
                ...state,
                data: action.payload
            }
        case PUT_TASKS_WORKFLOW:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} task`
            }
        case CLEAR_TASKS_WORKFLOW_SUCCESS:
            return {
                ...state,
                success: ''
            }
        default: 
            return state;
    }
}
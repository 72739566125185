import { GET_REPORT_PARCEL_STATISTICS, CLEAR_REPORT_PARCEL_STATISTICS} from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_REPORT_PARCEL_STATISTICS:
            return {
                ...state,
                data: action.payload
            }
        case CLEAR_REPORT_PARCEL_STATISTICS:
            return {
                ...state,
                data: {}
            }
        default:
            return state;
    }
}
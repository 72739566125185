
import { SET_NOTICATIONS, SET_NOTICATIONS_IS_LOADING, SET_NOTICATIONS_IS_FETCHING, SET_NOTICATIONS_IS_COMPLETE, SET_NOTICATIONS_PAGE, CLEAR_NOTIFICATIONS, REMOVE_ITEM_AT_INDEX, UPDATE_ITEM_COUNT, PLAY_SOUND, IS_PLAYING, MUTE, WEB_NOTIFICATION_TYPES   } from '../_actions/types';

const initialState = {
    data: [],
    totalPages: 0,
    totalCurrentItemCount: 0,
    totalItemCount: 0,
    isLoading: true,
    isFetching: false,
    isComplete: false,
    page: 1,
    success: "",
    playSound: false,
    isPlaying: false,
    mute: false,
    webNotificationTypes: [],
    aggregationDictionary: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_NOTICATIONS:
            return {
                ...state,
                data: [...state.data, ...action.payload.items],
                totalPages: action.payload.totalPages,
                totalCurrentItemCount: action.payload.totalCurrentItemCount,
                aggregationDictionary: action.payload.aggregationDictionary
            }
        case SET_NOTICATIONS_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_NOTICATIONS_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload,
            }
        case SET_NOTICATIONS_IS_COMPLETE:
            return {
                ...state,
                isComplete: action.payload
            }
        case SET_NOTICATIONS_PAGE:
            return {
                ...state,
                page: action.payload
            }
        case CLEAR_NOTIFICATIONS:
            return {...initialState, totalItemCount: state.totalItemCount, mute: state.mute, webNotificationTypes: state.webNotificationTypes};
        case REMOVE_ITEM_AT_INDEX:
            return {
                ...state,
                data: [...state.data.slice(0, action.payload), ...state.data.slice(action.payload + 1)]
            }
        case UPDATE_ITEM_COUNT:
            return {
                ...state,
                totalItemCount: action.payload.totalItemCount
            }
        case PLAY_SOUND:
            if(!state.mute)
            {
                return {
                    ...state,
                    playSound: action.payload
                }
            }
            return state;
        case IS_PLAYING:
            return {
                ...state,
                isPlaying: action.payload
            }
        case MUTE:
            return {
                ...state,
                mute: action.payload
            }
        case WEB_NOTIFICATION_TYPES:
            return {
                ...state,
                webNotificationTypes: action.payload
            }
        default:
            return state;
    }
}
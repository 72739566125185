import {
  GET_DELIVERY_STATS,
  GET_ACCEPTANCE_STATS,
  GET_OTDS_STATS,
  GET_PICKUP_COMPLIANCE_STATS,
  GET_DROPOFF_COMPLIANCE_STATS,
  GET_PICKUP_EXCEPTIONS_STATS,
  GET_DROPOFF_EXCEPTIONS_STATS,
  CLEAR_STATS,
} from '../_actions/types';

const initialState = {
  deliveries: 0,
  acceptance: {"amountCompleted": "0/0", "percentage": 0},
  otds: {"amountCompleted": "0/0", "percentage": 50},
  pickupCompliance: {"amountCompleted": "0/0", "percentage": 0},
  dropoffCompliance: {"amountCompleted": "0/0", "percentage": 0},
  pickupExceptions: {"amountCompleted": "0/0", "percentage": 0},
  dropoffExceptions: {"amountCompleted": "0/0", "percentage": 0},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DELIVERY_STATS:
      return {
        ...state,
        deliveries: action.payload,
      };
    case GET_ACCEPTANCE_STATS:
      return {
        ...state,
        acceptance: action.payload,
      };
    case GET_OTDS_STATS:
      return {
        ...state,
        otds: action.payload,
      };
    case GET_PICKUP_COMPLIANCE_STATS:
      return {
        ...state,
        pickupCompliance: action.payload,
      };
    case GET_DROPOFF_COMPLIANCE_STATS:
      return {
        ...state,
        dropoffCompliance: action.payload,
      };
      case GET_PICKUP_EXCEPTIONS_STATS:
      return {
        ...state,
        pickupExceptions: action.payload,
      };
    case GET_DROPOFF_EXCEPTIONS_STATS:
      return {
        ...state,
        dropoffExceptions: action.payload,
      };
    case CLEAR_STATS:
      return state;
    default:
      return state;
  }
}

import { GET_QUOTES, CLEAR_QUOTES, CLEAR_QUOTES_SUCCESS, RELOAD_QUOTE, RELOAD_QUOTE_CLEAR, GET_QUOTE_DETAILS, CLEAR_QUOTE_DETAILS, GET_QUOTE_ACCOUNTS, SET_LOADING_QUOTES } from '../_actions/types';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    data: {},
    success: null,
    accounts: null,
    details: null,
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_QUOTES:
            return {
                ...state,
                data: action.payload,
                success: true,
                reloadQuoteId: null,
                reloadQuoteXmin: null,
                loading: false
            }
        case CLEAR_QUOTES:
            return {
                ...state,
                data: {},
                success: null,
                loading: false
            }
        case CLEAR_QUOTES_SUCCESS:
            return {
                ...state,
                success: null
            }
        case RELOAD_QUOTE:
            return {
                ...state,
                reloadQuoteId: action.payload.entityId,
                reloadQuoteXmin: action.payload.xmin
            }
        case RELOAD_QUOTE_CLEAR:
            return {
                ...state,
                reloadQuoteId: null,
                reloadQuoteXmin: null
            }
        case GET_QUOTE_DETAILS:
            let quoteResponse = { ...action.payload };
            if (!isEmpty(quoteResponse) && !isEmpty(quoteResponse.deliveryItems)) {
                quoteResponse.deliveryItems.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
            }

            return {
                ...state,
                details: quoteResponse
            }
        case GET_QUOTE_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            }
        case CLEAR_QUOTE_DETAILS:
            return {
                ...state,
                details: {}
            }
        case SET_LOADING_QUOTES:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
import {
    CONNECT_TO_SIGNALR,
    DISCONNECT_FROM_SIGNALR,
    SUBSCRIBE_TO_COORDINATE_UPDATES,
    UNSUBSCRIBE_FROM_COORDINATE_UPDATES,
    SUBSCRIBE_TO_DELIVERIES_GROUP,
    UNSUBSCRIBE_FROM_DELIVERIES_GROUP,
    SUBSCRIBE_TO_JOBS_GROUP,
    UNSUBSCRIBE_FROM_JOBS_GROUP,
    RESUBSCRIBE_TO_COORDINATE_UPDATES,
    SUBSCRIBE_TO_USERS_GROUP,
    UNSUBSCRIBE_FROM_USERS_GROUP,
    SUBSCRIBE_TO_BULKINDEX_GROUP,
    UNSUBSCRIBE_FROM_BULKINDEX_GROUP,
    SUBSCRIBE_TO_JOB_TASK_UPDATES,
    UNSUBSCRIBE_FROM_JOB_TASK_UPDATES,
    RESUBSCRIBE_TO_JOB_TASK_UPDATES,
    UNSUBSCRIBE_FROM_WEB_NOTIFICATIONS_GROUP,
    SUBSCRIBE_TO_WEB_NOTIFICATIONS_GROUP
} from '../_actions/types';

const initialState = {
    connected: false,
    wasConnected: false,
    coordinateUpdates: [],
    deliveriesGroup: false,
    jobsGroup: false,
    usersGroup: false,
    bulkIndexerGroup: false,
    webNotificationGroup: false,
    jobTaskUpdates: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CONNECT_TO_SIGNALR: {
            return {
                ...state,
                connected: action.payload,
                wasConnected: true
            }
        }
        case DISCONNECT_FROM_SIGNALR: {
            return {
                ...state,
                connected: action.payload,
                coordinateUpdates: [],
                deliveriesGroup: false,
                jobsGroup: false,
                usersGroup: false
            }
        }
        case SUBSCRIBE_TO_COORDINATE_UPDATES:
            return {
                ...state,
                coordinateUpdates: action.payload
            }
        case RESUBSCRIBE_TO_COORDINATE_UPDATES:
            return {
                ...state,
                coordinateUpdates: action.payload
            }
        case UNSUBSCRIBE_FROM_COORDINATE_UPDATES:
            return {
                ...state,
                coordinateUpdates: []
            }
        case SUBSCRIBE_TO_JOB_TASK_UPDATES:
            return {
                ...state,
                jobTaskUpdates: action.payload
            }
        case RESUBSCRIBE_TO_JOB_TASK_UPDATES:
            return {
                ...state,
                jobTaskUpdates: action.payload
            }
        case UNSUBSCRIBE_FROM_JOB_TASK_UPDATES:
            return {
                ...state,
                jobTaskUpdates: []
            }
        case SUBSCRIBE_TO_DELIVERIES_GROUP:
            return {
                ...state,
                deliveriesGroup: action.payload
            }
        case UNSUBSCRIBE_FROM_DELIVERIES_GROUP:
            return {
                ...state,
                deliveriesGroup: action.payload
            }
        case SUBSCRIBE_TO_JOBS_GROUP:
            return {
                ...state,
                jobsGroup: action.payload
            }
        case UNSUBSCRIBE_FROM_JOBS_GROUP:
            return {
                ...state,
                jobsGroup: action.payload
            }
        case SUBSCRIBE_TO_USERS_GROUP:
            return {
                ...state,
                usersGroup: action.payload
            }
        case UNSUBSCRIBE_FROM_USERS_GROUP:
            return {
                ...state,
                usersGroup: action.payload
            }
        case SUBSCRIBE_TO_BULKINDEX_GROUP:
            return {
                ...state,
                bulkIndexerGroup: action.payload
            }
        case UNSUBSCRIBE_FROM_BULKINDEX_GROUP:
            return {
                ...state,
                bulkIndexerGroup: action.payload
            }
        case SUBSCRIBE_TO_WEB_NOTIFICATIONS_GROUP:
            return {
                ...state,
                webNotificationGroup: action.payload
            }
        case UNSUBSCRIBE_FROM_WEB_NOTIFICATIONS_GROUP:
            return {
                ...state,
                webNotificationGroup: action.payload
            }
        default:
            return state;
    }
}
import { GET_STORES, SET_STORE_FOR_EDIT, POST_STORES, PUT_STORES, CLEAR_STORES, DELETE_STORES, CLEAR_STORES_SUCCESS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    selectedStore:{},
    success: ""
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_STORES:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }   
        case POST_STORES:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} store`
            }
        case PUT_STORES:
            return {
                ...state,
                ...state.data,
                selectedStore: action.payload,
                success: `${PUT_SUCCESS} store`
            }
        case DELETE_STORES:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} stores.`
            }
        case CLEAR_STORES_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_STORES: 
            return {
                ...state,
                data: {},
                success: ''
            }
        case SET_STORE_FOR_EDIT:
            return {
                ...state,
                ...state.data,
                selectedStore: action.payload
                }     
        default: 
            return state;
    }
}
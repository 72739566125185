import { feedback } from "assets/jss/material-kit-pro-react.jsx";
import {
    BLACK_COLOR,
    SMALL_FONT,
    PRIMARY_COLOR,
    SLATE_COLOR,
    TERTIARY_COLOR,
    COMPLEMENTARY_2_COLOR,
    COMPLEMENTARY_5_COLOR,
    LARGE_FONT, LIGHTGREY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from '@material-ui/core/styles/colorManipulator';
export default theme => ({
    feedback: {
        ...feedback,
        marginTop: 10
    }, 
    root: {
        backgroundColor: theme.palette.background.paper
    },
    radioContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    radioButton: {
        padding: 0
    },
    priceAlignment: {
        [theme.breakpoints.down('sm')]: {
            float: 'right' 
        }
    },
    helpButton: {
        padding: 3,
        float: "right",
        marginTop: -5
    },
    helpIcon: {
        width: "0.95em",
        height: "0.95em"
    },
    listItem: {
        color: SLATE_COLOR,
        border: "1px solid",
        borderColor: LIGHTGREY_COLOR,
        borderRadius: 4,
        padding: 15,
        marginBottom: 10,
        "&:hover": {
            background: lighten(COMPLEMENTARY_2_COLOR, 0.85),
        }
    },
    selectedText: {
        fontWeight: "bold",
        color: lighten(BLACK_COLOR, 0.15)
    },
    listItemText: {
        color: "inherit"
    },
    detailsHeading: {
        color: COMPLEMENTARY_5_COLOR,
        fontWeight: "bold",
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: SMALL_FONT,
        [theme.breakpoints.down('sm')]: {
            
        }
    },
    progress: {
        width: "100%",
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    slaTitle: {
        fontWeight: 600,
        color: SLATE_COLOR,
        [theme.breakpoints.down('sm')]: {
            lineHeight: "30px"
        }
    },
    titleAlignment: {
        paddingTop: 7,
    },
    slaSubtext: {
        fontWeight: 400,
        fontSize: 15,
        color: SLATE_COLOR
    },
    dot: {
        fontWeight: 600
    },
    timeWindowText: {
        fontSize: SMALL_FONT,
        color: lighten(BLACK_COLOR, 0.4),
        //marginTop: 5
    },
    timeWindowSection: {
        [theme.breakpoints.up('md')]: {
            marginTop: 8,
            marginBottom: 6
        }
    },
    checkMark: {
        float: 'right',
        display: 'inline-block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        color: PRIMARY_COLOR,
        marginTop: 7
    },
    slaDescription: {
        display: 'inline-block'
    },
    icon: {
        color: lighten(BLACK_COLOR, 0.4),
        fontSize: LARGE_FONT,
        marginRight: 2,
        marginBottom: -4
    },
    timeIcon: {
        color: lighten(BLACK_COLOR, 0.1),
        fontSize: LARGE_FONT,
        marginRight: 2,
        marginLeft: 2,
        marginBottom: -3
    },
    timeHours: {
        color: PRIMARY_COLOR
    },
    marginZero: {
        marginBottom: 2,
    },
    dateText: {
        fontSize: SMALL_FONT,
        color: lighten(BLACK_COLOR, 0.4),
        marginTop: 2
    },
    deliveryDate: {
        color: COMPLEMENTARY_5_COLOR,
        fontWeight: "bold"
    },
    changeOrderText: {
        fontSize: SMALL_FONT,
        float: 'right',
        position: 'absolute',
        bottom: 75
    },
    changeOrderLink: {
        color: TERTIARY_COLOR,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    timePickerContainer: {
        marginBottom: 10
    },
    doubleCharge: {
        color: PRIMARY_COLOR,
        fontSize: 11,
        marginTop: '-9px',
        fontWeight: 'bold',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        }
    },
    highRisk:
    {
        color: PRIMARY_COLOR,
        fontSize: 11,
        marginTop: '-9px',
        fontWeight: 'bold',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        }
    }
});
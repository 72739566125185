import { GET_TRANSACTIONS, CLEAR_TRANSACTIONS, POST_TRANSACTIONS, REVERSE_TRANSACTIONS, CLEAR_TRANSACTIONS_SUCCESS, CANCEL_TRANSACTIONS, COMPLETE_TRANSACTIONS, SET_LOADING_TRANSACTIONS } from '../_actions/types';
import { POST_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: "",
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TRANSACTIONS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case CLEAR_TRANSACTIONS:
            return {
                ...state,
                data: {},
                success: ""
            }
        case CLEAR_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                success: ""
            }
        case POST_TRANSACTIONS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} transactions`
            }
        case REVERSE_TRANSACTIONS:
            return {
                ...state,
                data: action.payload
            }
        case COMPLETE_TRANSACTIONS:
            return {
                ...state,
                data: {},
                success: `Successsfully completed transactions`
            }
        case CANCEL_TRANSACTIONS:
            return {
                ...state,
                data: {},
                success: `Successsfully cancelled transactions`
            }
        case SET_LOADING_TRANSACTIONS:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
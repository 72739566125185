import { GET_TRACKING, SEARCH_TRACKING, CLEAR_TRACKING, CLEAR_TRACKING_DATA, CLEAR_TRACKING_SEARCH, CHANGE_TRACKING_COORDS } from '../_actions/types';

const initialState = {
    data: {},
    searchResult: null
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_TRACKING:
            return {
                ...state,
                data: action.payload
            }
        case SEARCH_TRACKING:
            return {
                ...state,
                searchResult: action.payload
            }
        case CLEAR_TRACKING:
            return {
                ...state,
                data: {},
                searchResult: null
            }
        case CLEAR_TRACKING_DATA: {
            return {
                ...state,
                data: {}
            }
        }
        case CLEAR_TRACKING_SEARCH: {
            return {
                ...state,
                searchResult: null
            }
        }
        case CHANGE_TRACKING_COORDS:
            let newDriver = { ...state.data.tracking.driver };
            newDriver.lastKnownLocation = action.payload;
            return {
                ...state,
                data: { ...state.data, tracking: { ...state.data.tracking, driver: newDriver } }
            }
        default:
            return state;
    }
}
import axios from 'axios';
import { getConfigAsync } from '../_utils/config';
import { clearTokens, getJwtToken, gotoLogin } from '../../_tokenService';
import isEmpty from '../_utils/isEmpty';
import refreshAccessToken from '../_utils/refreshAccessToken';
import isJwtTokenAboutToExpire from '../_utils/isJwtTokenAboutToExpire';
import isJwtTokenExpired from '../_utils/isJwtTokenExpired';

const createAxiosInstance = async (baseUrl) => {
    let conf = await getConfigAsync();
    const instance = axios.create({
        baseURL: !isEmpty(baseUrl) ? baseUrl : `${conf.REACT_APP_HOST_PROTOCOL}://${conf.REACT_APP_API_HOST}/${conf.REACT_APP_API_PREFIX}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    // Request interceptor to add the access token to headers
    instance.interceptors.request.use(
        async (config) => {
            let jwtToken = await getJwtToken();
            if (isJwtTokenAboutToExpire(jwtToken) && !isJwtTokenExpired(jwtToken)) {
                console.debug(">>>>> AXIOS REFRESHING TOKEN <<<<<<");
                try {
                    jwtToken = await refreshAccessToken();
                } catch (error) {
                    console.error("Token refresh failed", error);
                    throw new axios.Cancel('Token refresh failed');
                }
            }
            if (jwtToken) {
                config.headers.Authorization = `Bearer ${jwtToken}`;
            }
            return config;
        },
        (error) => {
            console.error(error);
            return Promise.reject(error);
        }
    );

    // Response interceptor to handle errors
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            if (error.response.status === 401 || error.response.status === 440) {
                await clearTokens().then(gotoLogin);
            }
            if (axios.isCancel(error)) {
                console.error("Request cancelled due to token refresh failure");
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

export default createAxiosInstance;

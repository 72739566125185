import { GET_COMPANIES, POST_COMPANIES, PUT_COMPANIES, CLEAR_COMPANIES, DELETE_COMPANIES, CLEAR_COMPANIES_SUCCESS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_COMPANIES:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_COMPANIES:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} company`
            }
        case PUT_COMPANIES:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} company`
            }
        case DELETE_COMPANIES:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} companies.`
            }
        case CLEAR_COMPANIES_SUCCESS:
            return {
                ...state,
                success: '',
                notificationsSuccess: ''
            }
        case CLEAR_COMPANIES: 
            return {
                ...state,
                data: {},
                success: '',
                notificationsSuccess: ''
            }
        default: 
            return state;
    }
}
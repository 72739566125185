import {
    BLACK_COLOR, SMALL_FONT,
    COMPLEMENTARY_3_COLOR, WHITE_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";
import { TERTIARY_COLOR } from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from "@material-ui/core/styles";

export default theme => ({
    deliveryContainer: {
        backgroundColor: COMPLEMENTARY_3_COLOR,
        borderRadius: 4,
        padding: "16px 24px 15px 24px",
        minHeight: "130px",
        [theme.breakpoints.up('sm')]: {
            marginRight: 15,
            marginLeft: 13,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
            padding: "16px 24px",
            minHeight: "auto",
        }
    },
    detailContainer: {
        backgroundColor: WHITE_COLOR,
    },
    priceAlignment: {
        [theme.breakpoints.down('sm')]: {
            float: 'left',
        }
    },
    deliveryInfo: {
        paddingBottom: 1,
        paddingLeft: 12,
    },
    displayAddressContainer: {
        display: 'flex',
    },
    deliveryDay: {
        color: TERTIARY_COLOR,
        fontWeight: 600,
        wordBreak: 'break-word',
    },
    addressTitle: {
        color: TERTIARY_COLOR,
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "22px"
    },
    timeWindowText: {
        fontSize: SMALL_FONT,
        color: lighten(BLACK_COLOR, 0.4),
        //marginTop: 5
    },
    timeIcon: {
        color: "#333333 !important",
    },
    detailsSummaryStepper: {
        paddingTop: 0,
        paddingBottom: 25,
    },
    twoColumn1: {
        display: "inline-block",
        width: "45%",
        verticalAlign: "top",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            display: "block"
        }
    },
    twoColumn2: {
        display: "inline-block",
        width: "55%",
        verticalAlign: "top",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            display: "block"
        }
    },
    heading: {
        fontSize: SMALL_FONT,
        fontWeight: 600,
        paddingBottom: 5,
    },
    statusContainer: {
        backgroundColor: COMPLEMENTARY_3_COLOR,
        borderRadius: 4,
        padding: "5px 0px 5px 0px",
        lineHeight: "16px",
        marginBottom: 15
    },
});
import React, { Component, lazy } from 'react';
import { getMobileDevicesPage, updateMobileDevice, clearMobileDevicesSuccess, clearMobileDevices, exportMobileDevices, deleteMobileDevices, notifyMobileDevice } from 'wumdrophubsreactshared/_actions/mobileDevices';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import AdminContainer from '../AdminContainer';
import { MOBILE_DEVICES, USERS } from 'wumdrophubsreactshared/_constants/typeConstants';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import { SELECT_DATE_RANGE, SELECT_IS_ACTIVE, SELECT_USER } from 'wumdrophubsreactshared/_constants/selectConstants';
import { saveAs } from 'file-saver';
import { Button, Typography } from '@material-ui/core';
import hasPermission from 'wumdrophubsreactshared/_utils/hasPermission';
import ConfirmationDialog from 'components/common/dialogs/ConfirmationDialog';
import { reloadPagedData } from 'components/tables/admin-table/reloadPagedData';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import DelinkIcon from '@material-ui/icons/PhonelinkErase';
import hasBetaPermission from 'wumdrophubsreactshared/_utils/hasBetaPermission';

const AdminTable = lazy(() => import('../../tables/admin-table/AdminTable'));

class MobileDevicesTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notifyOpen: false,
            delinkOpen: false,
            selectedMobileDevice: null,
            selectedMobileDeviceId: null
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEmpty(this.props.errors) && !isEqual(this.props.errors, prevProps.errors)) {
            if (this.props.errors.status === 404) {
                this.props.history.push('/404');
            }
            this.setState({ loading: false, delinkOpen: false, notifyOpen: false });
        }

        if (!isEqual(this.props.mobileDevices, prevProps.mobileDevices) && this.props.mobileDevices.success) {
            this.props.clearMobileDevicesSuccess();
            this.setState({ loading: false, delinkOpen: false, notifyOpen: false });
        }

        if (!isEmpty(this.props.mobileDevices.success)) {
            reloadPagedData(this.props, this.props.getMobileDevicesPage, MOBILE_DEVICES);
        }
    }

    handleDownloadPage = (meta) => {
        return this.props.exportMobileDevices(meta, saveAs);
    }

    handleDeviceNotifyClose = () => this.setState({ notifyOpen: false });
    handleDeviceNotifyOpen = (mobileDevice) => this.setState({ selectedMobileDevice: mobileDevice, notifyOpen: true });

    handleDeviceDelinkClose = () => this.setState({ delinkOpen: false });
    handleDeviceDelinkOpen = (mobileDevice) => this.setState({ selectedMobileDevice: mobileDevice, delinkOpen: true });

    handleDelinkMobileDevice = () => {
        const { selectedMobileDevice } = this.state;
        let request = { ...selectedMobileDevice, isActive: false };
        if (!isEmpty(request)) {
            this.setState({ loading: true });
            this.props.updateMobileDevice(selectedMobileDevice.id, request);
        }
    }

    handleDeviceNotification = () => {
        const { selectedMobileDevice } = this.state;
        if (selectedMobileDevice?.id > 0) {
            this.props.notifyMobileDevice(selectedMobileDevice.id, {}).then(this.handleDeviceNotifyClose);
        }
    }

    customActionColumn = (row) => {
        let isBeta = hasBetaPermission(this.props.auth);
        return (
            <>
                {
                    isBeta &&
                    <Button startIcon={<NotifyIcon />} size="small" variant="contained" disabled={!row.isActive} color="primary" onClick={() => this.handleDeviceNotifyOpen(row)} style={{ marginRight: 5 }}>
                        Notify
                    </Button>
                }

                <Button startIcon={<DelinkIcon />} size="small" variant="contained" disabled={!row.isActive} color="secondary" onClick={() => this.handleDeviceDelinkOpen(row)}>
                    DeLink
                </Button>
            </>
        )
    }

    render() {
        const { portal, auth } = this.props;
        const { notifyOpen, delinkOpen, loading, selectedMobileDevice } = this.state;

        let portalCode = "";
        if (!isEmpty(portal) && !isEmpty(portal.data)) {
            portalCode = portal.data.cca2
        }

        //setup filters
        let filters = [];

        let ratedUserFilter = { ...SELECT_USER };
        ratedUserFilter.label = "Owner";
        ratedUserFilter.fieldName = "appUserId";
        ratedUserFilter.apiEntity = `${USERS}/${portalCode}`;
        filters.push(ratedUserFilter);

        let trustedFilter = { ...SELECT_IS_ACTIVE };
        trustedFilter.label = "Trusted";
        filters.push(trustedFilter);
        filters.push({ field: "isActive", value: true });

        let dateRangeFilters = { ...SELECT_DATE_RANGE };
        dateRangeFilters.label = "Created date";
        filters.push(dateRangeFilters);

        let customColumns = [{ headerText: "", render: this.customActionColumn }];

        let canDelete = hasPermission(auth, { isAdministrator: true });

        return (
            <AdminContainer
                type={MOBILE_DEVICES}
                linkText="Mobile Devices"
                isReadOnly={true}
            >
                <AdminTable
                    type={MOBILE_DEVICES}
                    customColumns={customColumns}
                    tableData={this.props.mobileDevices}
                    exportData={this.handleDownloadPage}
                    getPage={this.props.getMobileDevicesPage}
                    filters={filters}
                    clearSuccess={this.props.clearMobileDevicesSuccess}
                    clearData={this.props.clearMobileDevices}
                    delete={this.props.deleteMobileDevices}
                    isSearchable={true}
                    isRowSelectable={false}
                    isEditable={false}
                    isDeletable={canDelete}
                />

                <ConfirmationDialog
                    open={notifyOpen}
                    onConfirmClick={this.handleDeviceNotification}
                    onCloseClick={this.handleDeviceNotifyClose}
                    title="Notify mobile device"
                    loading={loading}
                >
                    <Typography>
                        Are you sure you want to send a notification to {selectedMobileDevice?.userFullName}'s {selectedMobileDevice?.deviceBrand} {selectedMobileDevice?.deviceModel}?
                    </Typography>
                </ConfirmationDialog>

                <ConfirmationDialog
                    open={delinkOpen}
                    onConfirmClick={this.handleDelinkMobileDevice}
                    onCloseClick={this.handleDeviceDelinkClose}
                    title="Delink mobile device"
                    loading={loading}
                >
                    <Typography>
                        Are you sure you want to delink {selectedMobileDevice?.userFullName}'s {selectedMobileDevice?.deviceBrand} {selectedMobileDevice?.deviceModel}?
                    </Typography>
                </ConfirmationDialog>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    nav: state.nav,
    errors: state.errors,
    portal: state.portal,
    mobileDevices: state.mobileDevices
});

export default compose(
    connect(mapStateToProps, { getMobileDevicesPage, updateMobileDevice, clearMobileDevicesSuccess, clearMobileDevices, exportMobileDevices, deleteMobileDevices, notifyMobileDevice }),
)(MobileDevicesTable);
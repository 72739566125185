import React, { Component } from 'react';
import BasicMap from './BasicMap';

class DeliveryMap extends Component {

    constructor(props) {
        super(props);
        this.googleMapRef = React.createRef(); // FIXME use another method
        this.state = {
            dateValue: {},
            isCentred: true
        }
    }

    render() {
        const { originAddress, destinationAddress } = this.props;
        return (
            <BasicMap
                googleMapRef={this.googleMapRef} // FIXME what to use
                originAddress={originAddress}
                destinationAddress={destinationAddress}
                territories={this.props.territories}
                nav={this.props.nav} 
            />
        );
    }
}

export default DeliveryMap;


import { CLEAR_PAYMENT_CARDS, CLEAR_PAYMENT_CARD_SUCCESS, DELETE_PAYMENT_CARD, GET_PAYMENT_CARDS } from '../_actions/types';
import { DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_CARDS:
            return {
                ...state,
                data: action.payload
            }
        case DELETE_PAYMENT_CARD:
            return {
                ...state,
                success: `${DELETE_SUCCESS} transactions`
            }
        case CLEAR_PAYMENT_CARD_SUCCESS:
            return {
                ...state,
                success: ""
            }
        case CLEAR_PAYMENT_CARDS:
            return {
                ...state,
                data:{},
                success: ""
            }
        default:
            return state;
    }
}
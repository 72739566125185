import {
    GET_TERRITORIES_EXCEPTIONS,
    POST_TERRITORIES_EXCEPTIONS,
    PUT_TERRITORIES_EXCEPTIONS,
    DELETE_TERRITORIES_EXCEPTIONS,
    CLEAR_TERRITORIES_EXCEPTIONS,
    CLEAR_TERRITORIES_EXCEPTIONS_SUCCESS
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_TERRITORIES_EXCEPTIONS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_TERRITORIES_EXCEPTIONS:
            return {
                ...state,
                success:`${POST_SUCCESS} territory exception.`
            }
        case PUT_TERRITORIES_EXCEPTIONS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} area: ${action.payload.areaName}`
            }
        case DELETE_TERRITORIES_EXCEPTIONS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} territory exceptions.`
            }
        case CLEAR_TERRITORIES_EXCEPTIONS:
            return {
                ...state,
                data: {},
                success: ''
            }
        case CLEAR_TERRITORIES_EXCEPTIONS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        default: 
            return state;
    }
}
import { feedback } from "assets/jss/material-kit-pro-react.jsx";
import {
    LIGHTGREY_COLOR,
    SECONDARY_COLOR,
    PRIMARY_COLOR
} from "../../../wumdrophubsreactshared/_constants/styleConstants";
export default theme => ({
    feedback: {
        ...feedback
    },
    deliveryMap: {
        height: 215,
        margin: "5px 0px 10px 0px",
        border: `1px solid ${LIGHTGREY_COLOR}`
    },
    storesSwitch: {
        marginTop: 5
    },
    button: {
        padding: 9,
        marginTop: 4,
        marginLeft: -5
    },
    buttonChecked: {
        padding: 9,    
        marginTop: 4,
        color: SECONDARY_COLOR,
        marginLeft: -5
    },
    leadControl: {
        margin: "0 0 15px 0",
        position: "relative",
        padding: 0,
    },
    collectOnDeliveryRoot: {
        marginTop: -8
    },
    collectOnDeliveryLabel: {
        fontSize: 14,
        color: PRIMARY_COLOR,
    },
    collectPlacement: {
        marginLeft: 2,
    },
    collectChecked: {
        color: PRIMARY_COLOR,
    },
    datePickerAdmin: {
        marginBottom: 10
    }
});
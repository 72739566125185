import { Grid, Typography } from '@material-ui/core';
import SimpleDialog from 'components/common/dialogs/SimpleDialog';
import React, { Fragment, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const timeout = 3600_000;
const promptBeforeIdle = 180_000;

const convertSecondsToTimer = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    ;
    return String(minutes).padStart(2, '0') + ":" + String(remainingSeconds).padStart(2, '0');
}

function AuthIdleTimerContainer({ onTimeout, auth }) {

    const [open, setOpen] = useState(false);
    const [state, setState] = useState('Active');
    const [remaining, setRemaining] = useState(0);

    const onIdle = () => {
        setState('Idle');
        onTimeout();
        setOpen(false);
    }

    const onActive = () => {
        setState('Active');
        setOpen(false);
    }

    const { getRemainingTime, isPrompted, activate, pause, reset } = useIdleTimer({
        onIdle,
        onActive,
        timeout,
        promptBeforeIdle,
        crossTab: true,
        leaderElection: true,
        syncTimers: 500,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            let remainingTime = Math.ceil(getRemainingTime() / 1000);
            setRemaining(remainingTime);

            if (!isPrompted() && open) {
                setOpen(false);
            }

            if (isPrompted() && !open) {
                setOpen(true);
            }

            if (remainingTime === 0 && remaining === remainingTime) {

                if (state === "Active") {
                    activate();
                } else {
                    onIdle();
                }
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        if (!auth?.isAuthenticated) {
            pause();
        } else {
            reset();
        }
    }, [state, auth, pause, reset]);


    const handleContiue = () => {
        activate();
        setOpen(false);
    }

    return (
        <Fragment>
            <SimpleDialog
                open={open}
                title="Are you still here?"
                onCloseClick={onTimeout}
                onSubmitClick={handleContiue}
                cancelText="Logout"
                submitText="Continue"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography align='center'>Your current session is going to expire in</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='center' variant="h4">{convertSecondsToTimer(remaining)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='center'>Please click "Logout" to end the session now or click "Continue" to keep working.</Typography>
                    </Grid>
                </Grid>
                <br /><br />
            </SimpleDialog>
        </Fragment>
    )
}

export default AuthIdleTimerContainer;
import { GET_ARCHIVE_PARTNER_DELIVERIES, CLEAR_ARCHIVE_PARTNER_DELIVERIES } from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_ARCHIVE_PARTNER_DELIVERIES:
            return {
                ...state,
                data: action.payload
            }
        case CLEAR_ARCHIVE_PARTNER_DELIVERIES:
            return {
                ...state,
                data: {}
            }
        default:
            return state;
    }
}
import { whiteColor } from "assets/jss/material-kit-pro-react.jsx";
import {
    FONT_FAMILY,
    FONT_FAMILY_HEADER
} from "wumdrophubsreactshared/_constants/styleConstants";
import { SECONDARY_COLOR, DARK_GREEN_COLOR, WHITE_COLOR, PRIMARY_COLOR, TINY_FONT, SMALL_FONT, COMPLEMENTARY_3_COLOR, COMPLEMENTARY_5_COLOR, TERTIARY_COLOR } from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from "@material-ui/core/styles";

export default theme => ({
    sliderTile: {
        width: "calc(100% - 7px)",
        margin: 4,
        padding: 1,
        backgroundColor: WHITE_COLOR,
        "&:hover": {
            background: lighten(COMPLEMENTARY_3_COLOR, 0.85),
        }
    },
    tileHeading: {
        fontFamily: FONT_FAMILY_HEADER,
        fontSize: SMALL_FONT,
        textTransform: "capitalize",
        fontWeight: 600,
        lineHeight: "19px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: -7
        }
    },
    tileText: {
        fontFamily: FONT_FAMILY,
        fontSize: "0.7em",
        lineHeight: "12px",
        paddingBottom: 12,
        color: lighten(PRIMARY_COLOR, 0.2),
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.7em",
        }
    },
    tileIcon: {
        height: 45,
        marginTop: "5px"
    },
    tileButton: {
        background: whiteColor,
        border: "2px solid " + COMPLEMENTARY_5_COLOR,
        color: COMPLEMENTARY_5_COLOR,
        "&:hover": {
            background: COMPLEMENTARY_5_COLOR,
            color: whiteColor,
        }
    },
    // parcelBag: {
    //     padding: "0.5em"
    // },

    fragileTitle: {
        fontSize: SMALL_FONT
    },
    parcelItemWidthSet: {
        maxWidth: '18% !important',
        padding: "5px 5px 0 5px",
    },
    parcelItemWidthAuto: {
        padding: "5px 5px 0 5px",
        maxWidth: '18% !important',
    },
    parcelItem: {
        padding: "5px 5px 0 5px",
    },
    parcelItemIcon: {
        height: 28,
        paddingLeft: 12,
        paddingTop: 4,
        [theme.breakpoints.down('sm')]: {
            height: 27,
            paddingLeft: 18,
        }
    },
    parcelItemIconButton: {
        // height: 28,
        // paddingLeft: 12,
        // paddingTop: 4,
        // [theme.breakpoints.down('sm')]: {
        //     height: 27,
        //     paddingLeft: 18,
        // }
    },
    parcelItemText: {
        fontFamily: FONT_FAMILY_HEADER,
        fontSize: SMALL_FONT,
        textTransform: "capitalize",
        fontWeight: 600,
        lineHeight: "19px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 5,
            lineHeight: "27px",
        },
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    parcelItemSubText: {
        fontFamily: FONT_FAMILY,
        fontSize: "0.8em",
        lineHeight: "12px",
        paddingBottom: 12,
        color: lighten(PRIMARY_COLOR, 0.3),
        [theme.breakpoints.down('sm')]: {
            padding: "2px 0 0 5px",
            fontSize: "11px",
            lineHeight: "20px",
        },
        marginTop: 2
    },
    parcelSummaryItemSubText: {
        fontSize: "0.7em",
    },
    refCode: {
        margin: 0,
        fontSize: SMALL_FONT,
        backgroundColor: WHITE_COLOR,
        [theme.breakpoints.down('sm')]: {
            margin: 2,
        }
    },
    deleteButton: {
        marginTop: "2px",
        float: "left",
        color: SECONDARY_COLOR
    },
    moreButton: {
        marginTop: "2px",
        float: "right",
        color: COMPLEMENTARY_5_COLOR
    },

    addIcon: {
        color: DARK_GREEN_COLOR,
        marginLeft: 2,
        marginTop: 2,
        float: "left",
        fontSize: 22
    },
    MuiFormControlLabel: {
        root: {
            fontSize: TINY_FONT,
            color: COMPLEMENTARY_5_COLOR
        }
    },
    parcelContainer: {
        padding: 5,
        backgroundColor: COMPLEMENTARY_3_COLOR
    },
    parcelsContainer: {
        marginTop: 12,
        maxHeight: 260,
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            maxHeight: "40vh"
        }
    },
    parcelSummary: {
        fontSize: TINY_FONT,
        float: "right",
        color: PRIMARY_COLOR,
        margin: 5,
        fontWeight: 600,
    },
    parcelDetails: {
        fontWeight: 400,
        color: lighten(PRIMARY_COLOR, 0.5)
    },
    parcelContent: {
        marginTop: 10
    },
    fragileLabel: {
        fontSize: TINY_FONT,
        [theme.breakpoints.down('sm')]: {
            marginLeft: "-24px !important"
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: "3px !important",
        }
    },
    descriptionLabel: {
        fontSize: TINY_FONT,
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset"
        }
    },
    progress: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    successSnackbar: {
        backgroundColor: TERTIARY_COLOR
    },
    parcelDetailsTracking: {
        marginTop: 16,
        marginLeft:5,
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.7rem',
            marginTop: 10,
        }
    },
    parcelItemIconTracking: {
        height: 48,
        paddingTop: 4,
        [theme.breakpoints.down('sm')]: {
            height: 27,
        }
    },
});
import { CLEAR_REPORT_DRIVER_ANALYSIS, GET_REPORT_DRIVER_ANALYSIS } from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_REPORT_DRIVER_ANALYSIS:
            return {
                ...state,
                data: action.payload
            }
        case CLEAR_REPORT_DRIVER_ANALYSIS:
            return {
                ...state,
                data: {}
            }
        default:
            return state;
    }
}
import moment from 'moment-timezone'
import isEmpty from './isEmpty';
import { LOCAL_DATE_FORMAT } from '../_constants/apiConstants';

const convertUtcToLocalDate = (dateString, timeZone, humanize=true, format=LOCAL_DATE_FORMAT, showDay=true) => {

    //required date and timezone to format
    if(isEmpty(dateString) || isEmpty(timeZone)) {
        return dateString;
    }

    if(dateString.charAt(dateString.length-1) !== 'Z') {
        dateString = `${dateString}Z`;
    } 

    let date = moment(dateString);

    if(humanize) {
        //always use UTC dates when comparing against DB
        let isToday = date.isSame(moment(new Date()).utc(), "day");
        let isTomorrow  = date.isSame(moment(new Date()).utc().add(1,'days'), "day");
        let isYesterday = date.isSame(moment(new Date()).utc().add(-1, 'days'), "day");
        
        if(isToday) {
            return "Today";
        }
        else if (isTomorrow) {
            return "Tomorrow";
        } 
        else if (isYesterday) {
            return "Yesterday";
        }

        let dateToReturn = date.tz(timeZone);

        let dayOfWeek = '';
        if(showDay) {
            dayOfWeek = `(${dateToReturn.format('dddd')})`;
        }

        return `${dateToReturn.format(format)} ${dayOfWeek}`;
    }

    return date.tz(timeZone).format(format);
}

export default convertUtcToLocalDate;
  
import isEmpty from "./isEmpty";

const formatDisplayAddress = (address, building = null, unitNumber = null) => {
    let displayAddress = '';
    if (address?.receivedDescription) {
        displayAddress += address.receivedDescription;
    } else if (!isEmpty(address)) {
        if (unitNumber) {
            displayAddress += `${unitNumber}, `;
        }
        if (building) {
            displayAddress += `${building}, `;
        }

        if (typeof address == "string") {
            displayAddress = `${displayAddress}${address}`;
        } else {
            displayAddress = `${displayAddress}${address.formattedAddress}`;
        }
    }
    return displayAddress;
}

export default formatDisplayAddress;
import React from 'react';
import { compose, withProps, lifecycle } from 'react-recompose';
import {  GoogleMap,
    Marker,
    DirectionsRenderer } from "@react-google-maps/api"
import { ICON_GENERATOR_BASE_URL, ICON_GENERATORE_DELIVERY_ICONS, ICON_VERSION, MARKER_LEGEND_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MAP_ZOOM_LEVEL, MAP_CENTER_LAT, MAP_CENTER_LNG, MAP_STYLES, DIRECTIONS_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import getPositionPoint from 'wumdrophubsreactshared/_utils/getPositionPoint';
import MapControl from './MapControl'
import { Paper, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from "wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "App.css";
import styles from './BasicMap.css';

let markerColourEncoded = encodeURIComponent(DIRECTIONS_COLOR);
//fetch the pickup icon from our icon generator microservice
let pickUpMarkerIcon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=P&markerType=${MARKER_LEGEND_ENUMS.PICK_UP}&version=${ICON_VERSION}&markerColour=${markerColourEncoded}`;
//fetch the pickup icon from our icon generator microservice
let dropOffMarkerIcon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=D&markerType=${MARKER_LEGEND_ENUMS.DROP_OFF}&version=${ICON_VERSION}&markerColour=${markerColourEncoded}`;

//fetch the pickup icon from our icon generator microservice
let storePickUpMarkerIcon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=P&markerType=${MARKER_LEGEND_ENUMS.STORE_PICK_UP}&version=${ICON_VERSION}&markerColour=${markerColourEncoded}`;
//fetch the pickup icon from our icon generator microservice
let storeDropOffMarkerIcon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=D&markerType=${MARKER_LEGEND_ENUMS.STORE_DROP_OFF}&version=${ICON_VERSION}&markerColour=${markerColourEncoded}`;

const google = window.google;

const combinedStyles = combineStyles(styles, appStyles);

const BasicMap = compose(
    withStyles(combinedStyles),
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }}><LinearProgress /></div>,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    // withGoogleMap,
    lifecycle({
        UNSAFE_componentWillMount() {
            this.setState({
                startPoint: {},
                endPoint: {}
            });
        },
        recenterMap(originAddress, destinationAddress, googleMapRef, territories, nav) {
            let bounds = new google.maps.LatLngBounds();
            let startPoint = {};
            let endPoint = {};

            if (!isEmpty(originAddress) && !isEmpty(originAddress.coordinates) && !isEmpty(destinationAddress) && !isEmpty(destinationAddress.coordinates)) 
            {
                startPoint = this.convertCoordinate(originAddress.coordinates);
                endPoint = this.convertCoordinate(destinationAddress.coordinates);
                bounds.extend(startPoint);
                bounds.extend(endPoint);

                bounds.extend(getPositionPoint(startPoint,90, 10));
                bounds.extend(getPositionPoint(endPoint,90, 10));
                bounds.extend(getPositionPoint(startPoint,270, 10));
                bounds.extend(getPositionPoint(endPoint,270, 10));

                this.setState({ startPoint, endPoint }, function(){
                    if (!isEmpty(bounds) && !isEmpty(googleMapRef.current))
                    {
                        googleMapRef.current.fitBounds(bounds);
                    }
                });
            } 
            else if (!isEmpty(originAddress) && !isEmpty(originAddress.coordinates)) 
            {
                startPoint = this.convertCoordinate(originAddress.coordinates);
                bounds.extend(startPoint);

                bounds.extend(getPositionPoint(startPoint,0, 5));
                bounds.extend(getPositionPoint(startPoint,90, 5));
                bounds.extend(getPositionPoint(startPoint,180, 5));
                bounds.extend(getPositionPoint(startPoint,270, 5));

                this.setState({ startPoint, endPoint }, function(){
                    if (!isEmpty(googleMapRef.current))
                    {
                        googleMapRef.current.fitBounds(bounds);
                    }
                });
            }
            else if (!isEmpty(destinationAddress) && !isEmpty(destinationAddress.coordinates)) 
            {
                endPoint = this.convertCoordinate(destinationAddress.coordinates);
                bounds.extend(endPoint);

                bounds.extend(getPositionPoint(endPoint,0, 5));
                bounds.extend(getPositionPoint(endPoint,90, 5));
                bounds.extend(getPositionPoint(endPoint,180, 5));
                bounds.extend(getPositionPoint(endPoint,270, 5));

                this.setState({ startPoint, endPoint }, function(){
                    if (!isEmpty(googleMapRef.current))
                    {
                        googleMapRef.current.fitBounds(bounds);
                    }
                });
            }
            else
            {
                if (!isEmpty(territories) && !isEmpty(territories.data) && !isEmpty(territories.data.items) ) {

                    let selectedTerritoryId = 0;

                    if(!isEmpty(nav) && !isEmpty(nav.dashboardFilters))
                    {
                        nav.dashboardFilters.forEach(element => {
                            if(element.field === "territoryid")
                            {
                                selectedTerritoryId = element.value;
                            }
                        });
                    }

                    if(selectedTerritoryId > 0)
                    {
                        //apply bounds of selected territory only
                        let selectedTerritory = territories.data.items.find(territory => territory.id === selectedTerritoryId);

                        if(!isEmpty(selectedTerritory) && !isEmpty(selectedTerritory.areas) && !isEmpty(selectedTerritory.areas))
                        {
                            selectedTerritory.areas.forEach(areas => {
                                if(!isEmpty(areas.polygon))
                                {
                                    areas.polygon.forEach(polygon => {

                                        let coordinate = this.convertCoordinate(polygon);
                                        bounds.extend(coordinate);
                                    });
                                }
                            });
                        }

                        if (!isEmpty(googleMapRef.current))
                        {
                            googleMapRef.current.fitBounds(bounds);
                        }
                    }
                    else
                    {
                        //apply bounds of all territories
                        territories.data.items.forEach(territory => {

                            if(!isEmpty(territory) && !isEmpty(territory.areas) && !isEmpty(territory.areas))
                            {
                                territory.areas.forEach(areas => {
                                    if(!isEmpty(areas.polygon))
                                    {
                                        areas.polygon.forEach(polygon => {
                                            let coordinate = this.convertCoordinate(polygon);
                                            bounds.extend(coordinate);
                                        });
                                    }
                                });
                            }
                        })

                        if (!isEmpty(googleMapRef.current))
                        {
                            googleMapRef.current.fitBounds(bounds);
                        }

                    }
                }
                
            }
        },
        drawDirections(props) {
            if (!isEmpty(props.originAddress) && !isEmpty(props.destinationAddress) && !isEmpty(props.originAddress.coordinates) && !isEmpty(props.destinationAddress.coordinates)) {
                let startPoint = this.convertCoordinate(props.originAddress.coordinates);
                let endPoint = this.convertCoordinate(props.destinationAddress.coordinates);

                const DirectionsService = new google.maps.DirectionsService();
                DirectionsService.route({
                    origin: new google.maps.LatLng(startPoint.lat, startPoint.lng),
                    destination: new google.maps.LatLng(endPoint.lat, endPoint.lng),
                    travelMode: google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        let distanceInKm = Math.round((result.routes[0].legs[0].distance.value / 1000) * 100 ) / 100;
                        this.setState({
                            directions: result,
                            distance: `${distanceInKm} km` 
                        });
                        this.recenterMap(props.originAddress, props.destinationAddress, props.googleMapRef)
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                });
            }
        },
        componentDidMount() {
            
            const {originAddress, destinationAddress, territories, nav} = this.props;
           
            if(!isEmpty(originAddress) && !isEmpty(destinationAddress))
            {
                this.drawDirections(this.props);
            }
            else
            {
                this.recenterMap(this.props.originAddress, this.props.destinationAddress, this.props.googleMapRef, territories, nav)
            }

        },
        UNSAFE_componentWillReceiveProps(nextProps) {
            if (!isEmpty(nextProps.googleMapRef)) {
                
                if ((!isEmpty(nextProps.originAddress) || !isEmpty(nextProps.destinationAddress)) &&
                    (!isEqual(nextProps.originAddress, this.props.originAddress) || !isEqual(nextProps.destinationAddress, this.props.destinationAddress))) {

                    if (!isEmpty(nextProps.originAddress) && !isEmpty(nextProps.destinationAddress)) {
                        this.drawDirections(nextProps)
                    } else {
                        this.recenterMap(nextProps.originAddress, nextProps.destinationAddress, nextProps.googleMapRef);
                    }
                }
            }
        },
        convertCoordinate(coordinate) {
            if (!isEmpty(coordinate))
                return { lat: coordinate.lat, lng: coordinate.lon };
            return null;
        },
        calculateDistanceFromDirections(directions)
        {
            return "43 km";
        }
    })
)(props => 
    
    (
    <GoogleMap
        zoom={MAP_ZOOM_LEVEL}
        options={{ draggable: false, gestureHandling: 'greedy', zoomControl: false, mapTypeControl: false, streetViewControl: false, fullscreenControl: false, styles: MAP_STYLES }}
        mapContainerStyle={{width: '100%', height: "100%"}}
        onLoad={(map) => {
            const bounds = new window.google.maps.LatLngBounds({
                lat:  MAP_CENTER_LAT,
                lng: MAP_CENTER_LNG
            });
            map.fitBounds(bounds);
            props.googleMapRef.current = map

          }}
          onUnmount={() => props.googleMapRef.current == null}
    >
        {!isEmpty(props.startPoint) && !isEmpty(props.originAddress) && 
            <Marker position={props.startPoint} icon={!props.originAddress.storeId ? pickUpMarkerIcon : storePickUpMarkerIcon} />
        }

        {!isEmpty(props.endPoint) && !isEmpty(props.destinationAddress) &&
            <Marker position={props.endPoint} icon={!props.destinationAddress.storeId ? dropOffMarkerIcon : storeDropOffMarkerIcon} />
        }

        {!isEmpty(props.startPoint) && !isEmpty(props.endPoint) && props.directions &&
            <DirectionsRenderer
                directions={props.directions}
                options={{ suppressMarkers: true, preserveViewport: true, polylineOptions: { strokeColor: DIRECTIONS_COLOR } }}
            />
        }
         {!isEmpty(props.startPoint) && !isEmpty(props.endPoint) && props.directions &&
                <MapControl position={google.maps.ControlPosition.RIGHT_TOP} mapContext={props.googleMapRef.current}>
                    <div className={props.classes.distanceLabel} >
                        <Paper className={props.classes.root} elevation={2}>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                            <Grid item xs={12}>
                                <Typography className={props.classes.distanceHeader}>
                                        Distance: {props.distance}
                                </Typography> 
                            </Grid>
                            
                            </Grid>
                        </Paper>
                    </div>
            </MapControl>
         }
    </GoogleMap>
));

export default BasicMap;


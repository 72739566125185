import { GET_UPLOAD_BATCHES, CLEAR_UPLOAD_BATCHES, CLEAR_UPLOAD_BATCHES_SUCCESS, POST_UPLOAD_BATCH, DELETE_UPLOAD_BATCH, SUBMIT_UPLOAD_BATCH } from '../_actions/types';

const initialState = {
    data: {},
    success: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_UPLOAD_BATCHES:
            return {
                ...state,
                data: action.payload
            }
        case POST_UPLOAD_BATCH:
            return {
                ...state,
                data: {},
                success: "File uploaded sucessfully."
            }
        case SUBMIT_UPLOAD_BATCH:
            return {
                ...state,
                data: {},
                success: "Batch submitted sucessfully."
            }
        case DELETE_UPLOAD_BATCH:
            let prefix = action.payload.length > 1 ? `${action.payload.length} batches` : "Batch";
            return {
                ...state,
                success: `${prefix} deleted succesfully.`
            }
        case CLEAR_UPLOAD_BATCHES:
            return {
                ...state,
                data: {},
                success: null
            }
        case CLEAR_UPLOAD_BATCHES_SUCCESS:
            return {
                ...state,
                success: null
            }
        default:
            return state;
    }
}
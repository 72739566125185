import { useEffect } from "react";

const WebChat = () => {
  useEffect(() => {
    // Function to dynamically load the web chat script
    const loadWebChatScript = () => {
      const script = document.createElement("script");
      script.src = "https://wchat.app/widget/nj7jj1pjkn";
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const script = loadWebChatScript();
    let removeLocalStarageQcontcat=()=>localStorage.removeItem('qcontact-webchat-config')
    // Cleanup function to remove the script when the component unmounts
    return () => {
      const existingScript = document.querySelector('#qcontact-webchat-container');
      if (existingScript && existingScript.parentNode) {
        existingScript.parentNode.removeChild(existingScript);
      }
      removeLocalStarageQcontcat();
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component doesn't render anything itself
};

export default WebChat;

// converts a meters to Km
// returns 
const convertMetersToKm = (meters) => {
    
    let km = meters / 1000;

    if( km < 0 ) {
        return `${meters} m`;
    } else {
        return `${km.toFixed(2)} Km`;
    }
}
export default convertMetersToKm;
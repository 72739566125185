import React from "react";
import CountryPicker from "./CountryPicker";
import MaskedInput from "react-text-mask";
import { TextField, makeStyles } from "@material-ui/core";
import { feedback } from "assets/jss/styles";

function PhoneNumberMask({ inputRef, ...other }) {
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
}


const PhoneNumberPicker = ({ values, errors, touched, setFieldValue, handleBlur, handleChange, contactCountryFieldName, phoneNumberFieldName, disabled }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <CountryPicker
                name={contactCountryFieldName}
                onChange={(selected) => setFieldValue(contactCountryFieldName, selected.key)}
                onBlur={handleBlur}
            />
            <TextField
                error={errors[phoneNumberFieldName] && touched[phoneNumberFieldName]}
                placeholder="(080) 000-0000"
                type="tel"
                fullWidth={true}
                className={classes.phoneControl}
                InputProps={{
                    inputComponent: PhoneNumberMask,
                    classes: {
                        notchedOutline: classes.notchedOutline
                    },
                    name: phoneNumberFieldName,
                    value: values[phoneNumberFieldName],
                    onChange: handleChange,
                    onBlur: handleBlur,
                    disabled: disabled
                }}
            />
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row"
    },
    feedback: {
        ...feedback,
        marginRight: 10
    },
    phoneControl: {
        marginTop: 6,
        marginLeft: -200,
        padding: 0,
        width: `calc(100% - 40px) !important`
    },
    errorsContainer: {
        display: "flex"
    }
});

export default PhoneNumberPicker;
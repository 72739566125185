import { CLEAR_DELIVERY_UPLOADS_SUCCESS, PUT_LOGISTICAL_STATUS_UPLOADS, GET_LOGISTICAL_STATUS_UPLOADS, CANCEL_LOGISTICAL_STATUS_UPLOAD, CLEAR_LOGISTICAL_STATUS_UPLOADS } from '../_actions/types';

const initialState = {
    data: {},
    success: null,
}


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LOGISTICAL_STATUS_UPLOADS:
            return {
                ...state,
                data: action.payload
            }
        case PUT_LOGISTICAL_STATUS_UPLOADS:
            return {
                ...state,
                data: action.payload,
                success: "Logistic Status upload updated successfully"
            }
        case CANCEL_LOGISTICAL_STATUS_UPLOAD:
            return {
                ...state,
                //data: {},
                success: "Logistic Status cancelled successfully"
            }
        case CLEAR_LOGISTICAL_STATUS_UPLOADS:
            return {
                ...state,
                data: {},
                success: null
            }
        case CLEAR_DELIVERY_UPLOADS_SUCCESS:
            return {
                ...state,
                success: null
            }
        default:
            return state;
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import mapQuoteToParcels from 'wumdrophubsreactshared/_utils/mapQuoteToParcels';
import { clearErrors } from 'wumdrophubsreactshared/_actions/errors';
import { getParcelTypes } from 'wumdrophubsreactshared/_actions/parcelTypes';
import { Formik } from 'formik';
import * as yup from 'yup';
import styles from './ParcelStep.css';

// @material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import PackagePicker from './PackagePicker';

//Formik and Yup Validation
const validationSchema = yup.object().shape({
    parcels: yup.array().of(yup.object().shape({
        name: yup.string().required("Parcel type name is required"),
        height: yup.number().required("Height is required"),
        width: yup.number().required("Width is required"),
        length: yup.number().required("Length is required"),
        weight: yup.number().required("Weight is required")
    })).min(1, "You need to select a parcel size before continuing.").required("Parcel is required")
});

class ParcelStep extends Component {

    shouldComponentUpdate(nextProps) {
        if (nextProps.parcelTypes.loading !== this.props.parcelTypes.loading) {
            return true;
        }

        if (!isEqual(nextProps.parcelTypes.data, this.props.parcelTypes.data)) {
            return true;
        }

        if (!isEmpty(nextProps.quoteResponse) && !isEqual(this.props.quoteResponse, nextProps.quoteResponse)) {
            return true;
        }

        if (nextProps.loading !== this.props.loading) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        const { parcelTypes, getParcelTypes } = this.props;

        if (isEmpty(parcelTypes.data) && !parcelTypes.loading) {
            getParcelTypes();
        }
    }

    handleFormikSubmit(values) {
        this.props.clearErrors();
        this.props.handleStepSubmit(values);
    }

    render() {
        const { parcelTypes, addParcel, updateParcel, removeParcel, wizardRef, loading } = this.props;

        let initialValues = mapQuoteToParcels(this.props.quoteResponse);

        let isLoading = !loading && parcelTypes.loading ? parcelTypes.loading : loading;

        return (
            <Formik
                onSubmit={values => this.handleFormikSubmit(values)}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setValues }) => (

                    <form onSubmit={handleSubmit} id={`${wizardRef}2`} noValidate>
                        <PackagePicker
                            loading={isLoading}
                            deliveryId={initialValues.deliveryId}
                            refreshProps={false}
                            parcelTypes={parcelTypes}
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            setValues={setValues}
                            handleBlur={handleBlur}
                            addParcel={addParcel}
                            updateParcel={updateParcel}
                            removeParcel={removeParcel}
                        />
                    </form>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    parcelTypes: state.parcelTypes,
    serverErrors: state.errors
})

export default compose(
    withStyles(styles, { withTheme: true }, { name: 'ParcelStep' }),
    withRouter,
    connect(mapStateToProps, { clearErrors, getParcelTypes })
)(ParcelStep);
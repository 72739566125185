import { GET_PARCEL_BARCODES, CLEAR_PARCEL_BARCODES, SET_CHECKED_PARCELS, CLEAR_CHECKED_PARCELS } from '../_actions/types';

const initialState = {
    data: {},
    checked: []
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_PARCEL_BARCODES:
            return {
                ...state,
                data: action.payload
            }
        case CLEAR_PARCEL_BARCODES:
            return {
                ...state,
                data: {},
                checked: []
            }
        case SET_CHECKED_PARCELS:
            return {
                ...state,
                checked: action.payload
            }
        case CLEAR_CHECKED_PARCELS:
            return {
                ...state,
                checked: []
            }

        default:
            return state;
    }
}
import {
    BLACK_COLOR, FONT_FAMILY,
    PRIMARY_COLOR,
    SMALL_FONT,
    WHITE_COLOR,
    LIGHTGREY_COLOR, TINY_FONT
} from "wumdrophubsreactshared/_constants/styleConstants";
import {TERTIARY_COLOR} from "../../../wumdrophubsreactshared/_constants/styleConstants";

export default theme => ({
    gridItem: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important"
    },
    defaultContainer: {
        padding: 8,
        marginLeft: -5
    },
    componentContainer: {
        border: "1px solid",
        borderColor: LIGHTGREY_COLOR,
        borderRadius: 4,
        padding: 5,
        textAlign: "left",
        marginBottom: 0,
        [theme.breakpoints.down('xs')]: {
            padding: 21,
            marginBottom: 30,
        }
    },
    detailsContainer: {
        // background: 'inherit',
        marginBottom: 0,
        marginTop: 0,
        padding: "8px 16px",
        lineHeight: "25px",
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    textRow: {
        width: "100%",
        fontFamily: FONT_FAMILY,
    },
    textKeyQty: {
        color: TERTIARY_COLOR,
        fontSize: SMALL_FONT,
    },
    textKey: {
        fontWeight: 400,
        fontSize: TINY_FONT,
        color: PRIMARY_COLOR,
        paddingLeft: 6
    },
    textTotal:{
        color: BLACK_COLOR,
        fontWeight: 100,
    },
    textValue: {
        color: "BLACK_COLOR",
        fontSize: "14px !important",
        float: "right"
    },
    textBold: {
        fontWeight: "bold",
        fontFamily: FONT_FAMILY,
    },
    textDivider: {
        margin: "14px 0"
    },
    tileIcon: {
        height: 20,
        marginRight: "10px",
        float: "left",
    },
    table: {
        marginTop: 0,
        marginBottom: 10
    },
    numberBadge: {
        height: 20,
        borderRadius: 5,
        padding: "0 5px 0 5px",
        float: "right",
        fontSize: "0.9em",
        color: WHITE_COLOR,
        background: TERTIARY_COLOR
    },
    summaryTitle: {
        fontWeight: "bold",
        padding: "12px 0 0 14px",
        [theme.breakpoints.down('xs')]: {
            padding: "8px 16px 16px 0"
        }
    },
    subheading: {
        color: TERTIARY_COLOR,
        fontWeight: "bold",
    }
});
const grantAllPermissions = {
    isNormalUser: true,
    isAdministrator: true,
    isManagementOperator: true,
    isStaffOperator: true,
    isCompanyAdministrator: true,
    isCompanyOperator: true,
    isCompanyNormalUser: true,
    isStoreAdministrator: true,
    isStoreOperator: true,
    isStoreNormalUser: true,
    isPartner: true,
    isDriver: true,
    isSuperHero: true,
    isAssistant: true
}
export default grantAllPermissions;
import moment from 'moment-timezone';
import isPassedUtcDate from './isPassedUtcDate';
import isEmpty from './isEmpty';

//this function returns the difference in days, hours, minutes between now UTC and the utc date passed in
const getCountDownTimeUtc = (utcDateString, forceSeconds = false) => {

    if(isEmpty(utcDateString)) 
        return null;

    if(utcDateString.charAt(utcDateString.length-1) !== 'Z') {
        utcDateString = `${utcDateString}Z`;
    } 

    let nowUtc = moment.utc();
    let targetUtc = moment(utcDateString);

    let difDays = targetUtc.diff(nowUtc, 'days');
    let difHours = targetUtc.diff(nowUtc, 'hours');
    let difMinutes = targetUtc.diff(nowUtc, 'minutes');
    let difSeconds = targetUtc.diff(nowUtc, 'seconds');

    let response = '';

    if(isPassedUtcDate(utcDateString)) {
        //check for expired
        response = 'Expired';
    } else if((difDays === 0 && difHours === 0 && difMinutes === 0) || forceSeconds === true) {
        //return difference in seconds
        response = `${difSeconds} seconds`
    } else if(difDays === 0 && difHours === 0) {
        //return difference in minutes
        response = `${difMinutes} minutes`
    } else if (difDays === 0) {
        //return difference in hours
        response = `${difHours} hours`
    } else {
        //return difference in days
        response = `${difDays} days`
    }

    return response;
}

export default getCountDownTimeUtc;
  
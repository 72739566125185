import { 
    GET_CONTACTS, 
    POST_CONTACTS, 
    PUT_CONTACTS, 
    CLEAR_CONTACTS, 
    CLEAR_CONTACTS_SUCCESS,
    DELETE_CONTACTS, 
    GET_CONTACTS_BY_ID, 
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    data: {},
    success: ""
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_CONTACTS:

            //here we do some preprocessing to key items by id
            return {
                ...state,
                data: {...action.payload, items: action.payload.items.reduce((obj, item) => {
                    obj[`#${item.id}`] = item; //here we key on #id so as not to lose ordering from backend
                    return obj;
                }, {})},
                search: !isEmpty(action.search) ? action.search : ""
            }
        case GET_CONTACTS_BY_ID:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_CONTACTS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} contact`
            }
        case PUT_CONTACTS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} contact`
            }
        case DELETE_CONTACTS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} contacts.`
            }
        case CLEAR_CONTACTS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_CONTACTS: 
            return {
                ...state,
                data: {},
                success: ''
            }
        default: 
            return state;
    }
}
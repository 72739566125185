import { GET_DRIVER_ROUTES } from "../_actions/types";

const initialState = {
    data: {},
    success: ""
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_DRIVER_ROUTES:
            return {
                ...state,
                data: action.payload,
            }
        default: 
            return state;
    }
}
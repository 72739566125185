import {
    CALL_TO_ACTION_GREEN_COLOR,
    SLATE_COLOR, SMALL_FONT, WHITE_COLOR, PRIMARY_COLOR
} from 'wumdrophubsreactshared/_constants/styleConstants';
import { lighten } from '@material-ui/core';

export default () => ({
    root: {
        padding: 0,
    },
    deliveryContainer: {
        minHeight: "218px !important"
    },
    thankyouHeader: {
        fontSize: 30,
        marginBottom: 20,
        color: SLATE_COLOR,
        textAlign: "center",
        lineHeight: "36px",
    },
    componentContainer: {
        minHeight: "218px !important",
    },
    confirmationCopy: {
        textAlign: "center",
        marginBottom: 26,
    },
    confirmationImg: {
        paddingTop: 25,
        textAlign: "center",
        margin: "0 auto",
        display: "block"
    },
    center: {
      textAlign:'center',
      marginTop: 10  
    },
    callToActionButton: {
        backgroundColor: CALL_TO_ACTION_GREEN_COLOR,
        color: WHITE_COLOR,
        textTransform: "none",
        boxShadow: "none",
        fontSize: SMALL_FONT,
        padding: "7px 28px",
        marginTop: 10,
        marginLeft: 5,
        marginRight: 10,
        '&:hover': {
            boxShadow: "none",
            backgroundColor: lighten(CALL_TO_ACTION_GREEN_COLOR, 0.2),
            color: WHITE_COLOR,
        },
        '&:active': {
            boxShadow: "none",
        }
    },
    actionButton: {
        backgroundColor: PRIMARY_COLOR,
        color: WHITE_COLOR,
        textTransform: "none",
        boxShadow: "none",
        fontSize: SMALL_FONT,
        padding: "7px 28px",
        marginTop: 10,
        marginLeft: 5,
        marginRight: 10,
        '&:hover': {
            boxShadow: "none",
            backgroundColor: lighten(PRIMARY_COLOR, 0.2),
            color: WHITE_COLOR,
        },
        '&:active': {
            boxShadow: "none",
        }
    },
});
import React, { Component, lazy } from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AdminContainer from '../AdminContainer';
import { RATES, RATE_BATCH_UPLOAD, UPLOAD_BATCHES, USERS } from 'wumdrophubsreactshared/_constants/typeConstants';
import { getBatchRateUploadsPage, cancelRateUpload, clearRateUploads, clearRateUploadsSuccess } from 'wumdrophubsreactshared/_actions/rateUploads';
import { getUploadStatuses } from 'wumdrophubsreactshared/_actions/uploadStatuses';
import styles from './RateUploadTable.css';
import { SELECT_UPLOAD_STATUSES, SELECT_DRIVER } from 'wumdrophubsreactshared/_constants/selectConstants';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import { CancelOutlined } from '@material-ui/icons';
import { IconButton, Typography, Tooltip } from '@material-ui/core';
import ConfirmationDialog from 'components/common/dialogs/ConfirmationDialog';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import buildPageMeta from 'wumdrophubsreactshared/_utils/buildPageMeta';
import { UPLOAD_STATUS_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';

const AdminTable = lazy(() => import('components/tables/admin-table/AdminTable'));

class RateUploadTable extends Component {

    constructor(props) {
        super(props);

        let url = props.match.url;
        let urlFilters = [];
        let firstParentId = null;
        let firstParentType = null;
        let secondParentId = null;
        let secondParentType = null;
        let secondParentText = null;

        if (url.toLowerCase().includes(`/${UPLOAD_BATCHES}/`)) {
            firstParentId = this.props.match.params.id;
            firstParentType = RATES;
            secondParentId = props.match.params.uploadBatchId;
            secondParentType = UPLOAD_BATCHES;
            secondParentText = `#${secondParentId}`;
            urlFilters.push({ field: "uploadBatchId", value: secondParentId });
        }

        this.state = {
            loading: false,
            cancelConfirmOpen: false,
            selecteRateUploadId: null,
            urlFilters: urlFilters,
            firstParentId: firstParentId,
            firstParentType: firstParentType,
            secondParentId: secondParentId,
            secondParentText: secondParentText,
            secondParentType: secondParentType
        };

  

        if (isEmpty(this.props.uploadStatuses.data)) {
            this.props.getUploadStatuses();
        }
    }

    handleCancelConfirm = () => {
        const { selectedRateUploadId } = this.state;
        this.setState({ loading: true });
        this.props.cancelRateUpload(selectedRateUploadId);
    }

    handleCancelConfirmClose = () => this.setState({ cancelConfirmOpen: false });
    handleCancelConfirmOpen = (rateUploadId) => this.setState({ cancelConfirmOpen: true, selectedRateUploadId:rateUploadId });

    customCancelColumn = (row, classes) => {
        return (
            <IconButton color="inherit" aria-label="cancel upload" disabled={row.uploadStatusId >= UPLOAD_STATUS_ENUMS.IN_PROGRESS || row.uploadStatusId === UPLOAD_STATUS_ENUMS.CANCELLED || row.rateCreated} className={classes.secondaryIconButton} onClick={() => this.handleCancelConfirmOpen(row.id)}>
                <Tooltip title="Cancel rate upload">
                    <CancelOutlined />
                </Tooltip>
            </IconButton>
        )
    }

    componentDidUpdate(prevProps) {
        if (!isEmpty(this.props.errors) && !isEqual(this.props.errors, prevProps.errors)) {
            if (this.props.errors.status === 404) {
                this.props.history.push('/404');
            }
            this.setState({ loading: false, cancelConfirmOpen: false });
        }

        if (!isEqual(this.props.rateUploads, prevProps.rateUploads) && this.props.rateUploads.success) {
            this.props.clearRateUploadsSuccess();
            this.setState({ loading: false, cancelConfirmOpen: false });
            let meta = buildPageMeta();
            this.props.getBatchRateUploadsPage(this.state.secondParentId, meta);
        }
    }

    render() {
        const {
            loading,
            cancelConfirmOpen,
            firstParentId,
            firstParentType,
            secondParentId,
            secondParentText,
            secondParentType,
            selectedRateUploadId
        } = this.state;

        const { portal } = this.props;
        let portalCode = !isEmpty(portal) && !isEmpty(portal.data) ? portal.data.cca2 : "";

        let filters = [];

        let uploadStatusesFilters = { ...SELECT_UPLOAD_STATUSES };
        uploadStatusesFilters.values = this.props.uploadStatuses.data;

        let driverFilter = { ...SELECT_DRIVER };
        driverFilter.fieldName = "appUserId";
        driverFilter.apiEntity = `${USERS}/${portalCode}`;

        filters.push(uploadStatusesFilters);
        filters.push(driverFilter);

        let customColumns = [{ headerText: "", render: this.customCancelColumn }];

        return (
            <AdminContainer
                type={UPLOAD_BATCHES}
                linkText="Uploads"
                firstParentId={firstParentId}
                firstParentType={firstParentType}
                secondParentId={secondParentId}
                secondParentText={secondParentText}
                secondParentType={secondParentType}
                currentBaseType={UPLOAD_BATCHES}
            >
                <AdminTable
                    type={RATE_BATCH_UPLOAD}
                    customColumns={customColumns}
                    tableData={this.props.rateUploads}
                    getPage={this.props.getBatchRateUploadsPage}
                    filters={filters}
                    clearSuccess={this.props.clearRateUploadsSuccess}
                    clearData={this.props.clearRateUploads}
                    firstParentId={firstParentId}
                    firstParentType={firstParentType}
                    secondParentId={secondParentId}
                    isRowSelectable={false}
                    isSearchable={true}
                    isEditable={true}
                    isDeletable={false}
                />

                <ConfirmationDialog
                    open={cancelConfirmOpen}
                    onConfirmClick={() => this.handleCancelConfirm(selectedRateUploadId)}
                    onCloseClick={this.handleCancelConfirmClose}
                    title="Cancel confirmation"
                    loading={loading}
                >
                    <Typography>
                    This action cannot be undone. Are you sure you want to cancel rate upload #{selectedRateUploadId}?
                    </Typography>
                </ConfirmationDialog>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    portal: state.portal,
    rateUploads: state.rateUploads,
    uploadStatuses: state.uploadStatuses
});

export default compose(
    connect(mapStateToProps, { getUploadStatuses, getBatchRateUploadsPage, cancelRateUpload, clearRateUploads, clearRateUploadsSuccess }),
    withStyles(styles, { withTheme: true }, { name: 'RateUploadTable' })
)(RateUploadTable);
import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor, 
  drawerWidth,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

import {
  SECONDARY_COLOR,
  FONT_FAMILY,
  APP_BAR_HEIGHT,
  WHITE_COLOR, TINY_FONT, TERTIARY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";

const headerStyle = theme => ({
  fullWidth: {
    boxShadow: "none!important",
    marginBottom: "0px!important",
  },
  toolBarFullWidth: {
    "@media (min-width: 576px)": {
      maxWidth: "none!important"
    },
    "@media (min-width: 768px)": {
      maxWidth: "none!important"
    },
    "@media (min-width: 992px)": {
      maxWidth: "none!important"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "none!important"
    }
  },
  buttonFullWidth: {
    paddingTop: "6px!important",
    paddingBottom: "0px!important"
  },
  appBar: {
    display: "flex",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderColor: "#edf1f5",
    boxShadow: "none !important",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: grayColor[15],
    width: "100%",
    backgroundColor: whiteColor,
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    height: APP_BAR_HEIGHT,
    paddingRight: '0px !important'
  },
  absolute: {
    position: "absolute",
    top: "auto"
  },
  fixed: {
    position: "fixed"
  },
  container: {
    ...container,
    minHeight: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap"
  },
  title: {
    "&,& a": {
      ...defaultFont,
      minWidth: "unset",
      lineHeight: "30px",
      fontSize: "18px",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "inherit",
      height: 30,        
      padding: '3px 0 !important',
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent"
      }
    }
  },
  appResponsive: {
    margin: "20px 0px",
    marginTop: "0px"
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.46)"
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.46)"
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.46)"
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.46)"
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.46)"
  },
  rose: {
    backgroundColor: roseColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.46)"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: whiteColor
  },
  dark: {
    color: whiteColor,
    backgroundColor: grayColor[9] + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(grayColor[9]) +
      ", 0.46)"
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: grayColor[15],
    backgroundColor: whiteColor + " !important",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderColor: "#edf1f5",
    boxShadow: "0 4px 20px rgba(16,19,20,.04)"
  },
  drawerPaper: {
     width: drawerWidth,
    fontFamily: FONT_FAMILY,
    backgroundColor: 'rgb(242, 242, 242)',
    overflowX: "hidden"
  },
  logoLink: {
    textDecoration: 'none'
  },
  flag: {
    position: 'relative',
    left: '5px',
    top: '-15px',
    fontSize: '0.7em',
    fontWeight: 'normal'
  },
  backToUserItem: {
    fontSize: 13,
    lineHeight: 13,
    height: 50
  },
  backToUserIcon: {
    fontSize: 16,
    marginRight: 5
  },
  platformBtn: {
    color: WHITE_COLOR,
    fontSize: TINY_FONT,
    padding: '6px 9px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    zIndex: 3000,
    position: 'absolute',
    marginTop: '-9px',
    top: 0,
    left: 185,
    backgroundColor: SECONDARY_COLOR
  },
  menuItemsBar: {
    backgroundColor: "transparent",
    marginLeft: 10,
    paddingTop: 15,
    fontFamily: FONT_FAMILY,
    '&.MuiButton-root, &.MuiButton-text':{
        '&:hover,&:focus': {
            backgroundColor: "transparent",
            color: TERTIARY_COLOR
        }
    },
    '& .MuiTypography-body1': {
        fontSize: '0.885rem',
    },
  }
});

export default headerStyle;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-recompose';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DeliveryStepper from './DeliveryStepper';
import styles from './DeliveryWizard.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Cancel';
import getGuid from '../../wumdrophubsreactshared/_utils/getGuid';

class DeliveryWizard extends Component {

    constructor(props) {
        super(props);
        let wizardRef = getGuid();
        this.state = { wizardRef: wizardRef };
    }

    render() {
        const { classes, fullScreen, open, step, handleClose, loading } = this.props;
        const { wizardRef } = this.state;

        return (
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialogRoot}
                fullScreen={fullScreen}
                classes={{ paper: classes.dialogPaper, paperFullWidth: classes.dialogPaperFullWidth }}
                disableEscapeKeyDown={false}
                ref={wizardRef}>
                <DialogContent
                    className={classes.dialogContent}
                    classes={{
                        root: classes.dialogContentRoot
                    }}
                >
                    <IconButton color="inherit" onClick={handleClose} aria-label="Close" className={classes.closeButton}>
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                    <DeliveryStepper
                        step={step}
                        handleClose={handleClose}
                        loading={loading}
                        wizardRef={wizardRef}
                    />

                </DialogContent>
            </Dialog>
        );
    }
}

DeliveryWizard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }, { name: 'DeliveryWizard' }),
)(DeliveryWizard);

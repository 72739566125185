import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'react-recompose';
import {Grid, Typography} from "@material-ui/core";
import appStyles from "../../../App.css";
import styles from './DeliveryStepperNegativeSummary.css';
import withStyles from "@material-ui/core/styles/withStyles";
import combineStyles from "../../../wumdrophubsreactshared/_utils/combineStyles";
import Button from "@material-ui/core/Button";

class DeliveryStepperNegativeSummary extends Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount = (prevProps) =>{
        
    }

    componentWillUnmount() {
        
    }
    
    componentDidUpdate(prevProps) {

    }

    render(){
        const { classes } = this.props;
        return (
            <div className={classes.boxContainer}>
                <Grid container>
                    <Grid item xs={12}>
                        <div><img src={require("assets/img/failedpayment.svg")} width={"130"} className={classes.confirmationImg} alt="Failed payment"/></div>
                        <Typography className={classes.thankyouHeader}>
                            Delivery failed
                        </Typography>
                        <Typography className={classes.confirmationCopy}>
                            Oops, your delivery has expired. No fear, we have you covered!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.centerAlign}>
                        <Button variant="contained" className={classes.reattemptButton}>
                            Reattempt your delivery
                        </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.centerAlign}>
                       &nbsp;
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: state.errors,
    deliveries: state.deliveries,
    quotes: state.quotes,
    portal: state.portal
});

const combinedStyles = combineStyles(styles, appStyles);

export default  compose(
    withStyles(combinedStyles),
    withRouter,
    connect(mapStateToProps, {  })
)(DeliveryStepperNegativeSummary);

import {
    BLACK_COLOR, COMPLEMENTARY_3_COLOR,
    SMALL_FONT,
    TERTIARY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from "@material-ui/core/styles";

export default theme => ({
    deliveryContainer: {
        padding: 8,
        backgroundColor: COMPLEMENTARY_3_COLOR,
        margin: "10px 15px 0 0",
        borderRadius: 4,
    },
    priceAlignment: {
        [theme.breakpoints.down('sm')]: {
            float: 'left',
        }
    },
    deliveryInfo: {
        padding: "0 0"
    },
    displayAddressContainer: {
        display: 'flex',
    },
    deliveryDay: {
      color: TERTIARY_COLOR,
      fontWeight: "bold",  
    },
    addressTitle: {
        color: TERTIARY_COLOR,
        fontWeight: "bold",
        fontSize: 13,
        lineHeight: "22px"
    },
    timeWindowText: {
        fontSize: SMALL_FONT,
        color: lighten(BLACK_COLOR, 0.4),
        //marginTop: 5
    }
});
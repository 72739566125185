import { GET_DELIVERY_ITEMS, CLEAR_DELIVERY_ITEMS, SET_LOADING_DELIVERY_ITEMS, UPDATE_DELIVERY_ITEMS } from '../_actions/types';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    data: {},
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_DELIVERY_ITEMS:
            if (!isEmpty(action.payload) && !isEmpty(action.payload.items)) {
                action.payload.items.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
            }

            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case CLEAR_DELIVERY_ITEMS:
            return {
                ...state,
                data: {},
                loading: false
            }
        case SET_LOADING_DELIVERY_ITEMS:
            return {
                ...state,
                loading: action.payload
            }

        case UPDATE_DELIVERY_ITEMS:
            if (!isEmpty(state.data) && !isEmpty(state.data.items)) {
                let newItems = [...state.data.items];
                // here action.payload is expected to be an array of delivery items to update in redux
                action.payload.forEach(newDeliveryItem => {
                    newItems.forEach((deliveryItem, index) => {
                        if (deliveryItem.id === newDeliveryItem.id) {
                            // update the new delivery item in redux
                            newItems[index] = newDeliveryItem;
                        }
                    });
                })

                return {
                    ...state,
                    data: { ...state.data, items: newItems }
                }

            } else {
                // else do nothing
                return state;
            }
        default:
            return state;
    }
}
import {SECONDARY_COLOR} from '../../wumdrophubsreactshared/_constants/styleConstants';
import { darken } from '@material-ui/core';

export default theme => ({
    appBar: {
        position: 'absolute',
        backgroundColor: SECONDARY_COLOR,
        paddingLeft:  theme.spacing(1),
        borderTop: `1px solid ${darken(SECONDARY_COLOR, 0.1)}`
    }
});
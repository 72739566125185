import { CLEAR_REPORT_DRIVET_AUTO_ASSIGN_EFFICIENCY, GET_REPORT_DRIVER_AUTO_ASSIGN_EFFICIENCY } from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_REPORT_DRIVER_AUTO_ASSIGN_EFFICIENCY:
            return {
                ...state,
                data: action.payload
            }
        case CLEAR_REPORT_DRIVET_AUTO_ASSIGN_EFFICIENCY:
            return {
                ...state,
                data: {}
            }
        default:
            return state;
    }
}
import { GET_BANKS, SET_LOADING_BANKS } from '../_actions/types';

const initialState = {
    data: {},
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_BANKS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case SET_LOADING_BANKS:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
import { GET_FUNDS_ALLOCATION, CLEAR_FUNDS_ALLOCATION, POST_FUNDS_ALLOCATION } from '../_actions/types';
import { POST_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_FUNDS_ALLOCATION:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case CLEAR_FUNDS_ALLOCATION:
            return {
                ...state,
                data: {},
                success: ""
            }
        case POST_FUNDS_ALLOCATION:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} fundsReceived`
            }
        default:
            return state;
    }
}
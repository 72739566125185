import { GET_DELIVERY_UPLOADS, CANCEL_DELIVERY_UPLOAD, PUT_DELIVERY_UPLOADS, CLEAR_DELIVERY_UPLOADS, CLEAR_DELIVERY_UPLOADS_SUCCESS } from '../_actions/types';

const initialState = {
    data: {},
    success: null,
}


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DELIVERY_UPLOADS:
            return {
                ...state,
                data: action.payload
            }
        case PUT_DELIVERY_UPLOADS:
            return {
                ...state,
                data: action.payload,
                success: "Delivery upload updated successfully"
            }
        case CANCEL_DELIVERY_UPLOAD:
            return {
                ...state,
                //data: {},
                success: "Delivery upload cancelled successfully"
            }
        case CLEAR_DELIVERY_UPLOADS:
            return {
                ...state,
                data: {},
                success: null
            }
        case CLEAR_DELIVERY_UPLOADS_SUCCESS:
            return {
                ...state,
                success: null
            }
        default:
            return state;
    }
}
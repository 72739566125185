import { GET_COMPANY_COMMUNICATIONS_HEADINGS, GET_COMPANY_COMMUNICATIONS, PUT_COMPANY_COMMUNICATIONS, CLEAR_COMPANY_COMMUNICATIONS_SUCCESS, CLEAR_COMPANY_COMMUNICATIONS } from '../_actions/types';
import { PUT_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    headings: {},
    success: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_COMPANY_COMMUNICATIONS_HEADINGS:
            return {
                ...state,
                headings: action.payload
            }
        case GET_COMPANY_COMMUNICATIONS:
            return {
                ...state,
                data: action.payload
            }
        case PUT_COMPANY_COMMUNICATIONS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} settings`
            }
        case CLEAR_COMPANY_COMMUNICATIONS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_COMPANY_COMMUNICATIONS:
            return {
                ...state,
                data: {},
                success: ''
            }
        default:
            return state;
    }
}
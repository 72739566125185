import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-recompose';

import Header from './public-header/Header';
import styles from './NavBar.css';
import { switchToUser } from '../../wumdrophubsreactshared/_actions/authentication';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import DeliveryWizard from "../delivery-wizard/DeliveryWizard";

import { clearErrors } from 'wumdrophubsreactshared/_actions/errors';
import { clearQuotes } from 'wumdrophubsreactshared/_actions/quotes';
import { setDeliveryWizardProps } from 'wumdrophubsreactshared/_actions/navigation';
import VerifyEmailBar from './VerifyEmailBar';

class NavBar extends PureComponent {

    handleWizardOpen = () => {
        //clear errors and quotes, we are creating a new order from scratch
        this.props.clearErrors();
        this.props.clearQuotes();
        this.props.setDeliveryWizardProps(true, false, 0);
    };

    handleWizardClose = () => {
        this.props.clearQuotes();
        this.props.setDeliveryWizardProps(false, false, 0);
    };

    render() {
        const { portal, fullWidth, auth, switchToUser, history, fixed, absolute } = this.props;
        const { deliveryWizardProps } = this.props.nav;

        let isFixed = false;
        if (!isEmpty(fixed)) {
            isFixed = fixed;
        } else if (!fullWidth && !absolute) {
            isFixed = true;
        }

        let isAbsolute = false;
        if (!isEmpty(absolute)) {
            isAbsolute = absolute;
        }

        return (
            <div>
            <VerifyEmailBar absolute={absolute}/>
                <Header
                    fullWidth={fullWidth}
                    fixed={isFixed}
                    absolute={isAbsolute}
                    color={!fullWidth ? "white" : "white"}
                    {...(!fullWidth && {
                        changeColorOnScroll: {
                            height: 300,
                            color: 'white'
                        }
                    })}
                    portal={portal}
                    auth={auth}
                    onSwitchToUser={switchToUser}
                    onOpenDeliveryWizard={this.handleWizardOpen}
                    history={history}
                />
                <DeliveryWizard
                    open={deliveryWizardProps.open}
                    handleClose={this.handleWizardClose}
                    loading={deliveryWizardProps.loading}
                    step={deliveryWizardProps.step}
                />
            </div>
        );
    }
}

NavBar.propTypes = {
    classes: PropTypes.object.isRequired,
    fullWidth: PropTypes.bool
}

const mapStateToProps = (state) => ({
    portal: state.portal,
    auth: state.auth,
    nav: state.nav
})

export default compose(
    withStyles(styles, { name: 'NavBar' }),
    withRouter,
    connect(mapStateToProps, { switchToUser, clearErrors, clearQuotes, setDeliveryWizardProps }),
)(NavBar);
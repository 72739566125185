import {
    SET_CURRENT_USER,
    EMAIL_SENT,
    SMS_SENT,
    RESET_PASSWORD_CONFIRMED,
    EMAIL_CONFIRMED,
    PHONE_NUMBER_CONFIRMED,
    IMPERSONATE_USER,
    SET_PREVIOUS_USER,
    SWITCH_TO_USER,
    LOGOUT,
    UPDATE_USER_CONTEXT,
    LEAD_USER_REGISTERED,
    CHANGED_PASSWORD,
    SET_ACTIVE_DEVICE,
    CLEAR_CHANGED_PASSWORD,
    CHANGED_EMAIL,
    CLEAR_CHANGED_EMAIL
} from '../_actions/types';

import { PUT_SUCCESS } from '../_constants/stringConstants';

import isEmpty from '../_utils/isEmpty';

const initialState = {
    isAuthenticated: false,
    currentUser: {},
    previousUsers: [],
    impersonating: false,
    changedPassword: false,
    changedEmail: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload.token),
                currentUser: action.payload.user,
                token: action.payload.token
            }
        case SET_ACTIVE_DEVICE:
            return {
                ...state,
                currentUser: { ...state.currentUser, device: { ...action.payload } }

            }
        case UPDATE_USER_CONTEXT:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                currentUser: action.payload
            }
        case SET_PREVIOUS_USER:
            return {
                ...state,
                ...state.previousUsers.push(action.payload),
                impersonating: true
            }
        case SWITCH_TO_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload.token),
                currentUser: { user: action.payload.user },
                previousUsers: [],
                impersonating: false,
                token: action.payload.token
            }
        case IMPERSONATE_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload.new),
                currentUser: action.payload.new,
                ...state.previousUsers.push(action.payload.old),
                impersonating: true,
                token: action.payload.token
            }
        case LOGOUT: {
            return {
                isAuthenticated: false,
                currentUser: {},
                previousUsers: [],
                impersonating: false
            }
        }
        case EMAIL_SENT:
            return {
                ...state,
                emailSent: action.payload
            }
        case SMS_SENT:
            return {
                ...state,
                smsSent: action.payload
            }
        case RESET_PASSWORD_CONFIRMED:
            return {
                ...state,
                resetPasswordConfirmed: action.payload
            }
        case EMAIL_CONFIRMED:
            return {
                ...state,
                emailConfirmed: action.payload
            }
        case PHONE_NUMBER_CONFIRMED:
            return {
                ...state,
                phoneNumberConfirmed: action.payload
            }
        case LEAD_USER_REGISTERED:
            return {
                ...state,
                leadUserRegister: action.payload
            }
        case CHANGED_PASSWORD:
            return {
                ...state,
                changedPassword: `${PUT_SUCCESS} password`
            }
        case CLEAR_CHANGED_PASSWORD:
            return {
                ...state,
                changedPassword: false
            }
        case CHANGED_EMAIL:
            return {
                ...state,
                changedEmail: `${PUT_SUCCESS} email`
            }
        case CLEAR_CHANGED_EMAIL:
            return {
                ...state,
                changedEmail: false
            }
        default:
            return state;
    }
}
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import { Button, CircularProgress, DialogActions, makeStyles } from '@material-ui/core';
import { CALL_TO_ACTION_GREEN_COLOR, PRIMARY_COLOR, WHITE_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';

function SimpleDialog({ open, onCloseClick, onSubmitClick, title, children, fullWidth, maxWidth, loading, disabled, cancelText, submitText, formId }) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let cancel = !isEmpty(cancelText) ? cancelText : "Cancel";
    let submit = !isEmpty(submitText) ? submitText : "Save";
    let hasForm = !isEmpty(formId);
    return (
        <div>
            <Dialog
                open={open}
                onClose={onCloseClick}
                fullScreen={fullScreen}
                fullWidth={!isEmpty(fullWidth) ? fullWidth : false}
                maxWidth={!isEmpty(maxWidth) ? maxWidth : false}
                aria-labelledby="simple-dialog-title"
                aria-describedby="simple-dialog-description"
            >
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <DialogContent className={classes.content}>
                    {children}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button fullWidth onClick={onCloseClick} color="primary" variant="contained">
                        {cancel}
                    </Button>

                    {
                        hasForm ?
                            <Button fullWidth disabled={loading || disabled} className={classes.btnSubmit} type="submit" form={formId} variant="contained">
                                {loading ? <CircularProgress size={25} /> : submit}
                            </Button>
                            :
                            <Button fullWidth disabled={loading || disabled} onClick={onSubmitClick} className={classes.btnSubmit} variant="contained">
                                {loading ? <CircularProgress size={25} /> : submit}
                            </Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles({
    title: {
        color: WHITE_COLOR,
        background: PRIMARY_COLOR,
        fontWeight: "bold !important",
        textAlign: "center",
        padding: 20
    },
    content: {
        marginTop: 25
    },
    subtitle: {

    },
    actions: {
        justifyContent: "center",
        textAlign: "center",
        padding: 25
    },
    btnSubmit: {
        background: CALL_TO_ACTION_GREEN_COLOR,
        color: WHITE_COLOR
    }
});

export default SimpleDialog;
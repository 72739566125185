import React from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './AdminCard.css';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(styles);

const AdminCard = ({ children, loading, loadingText = "Loading..." }) => {
    const classes = useStyles();
    return (
        <div>
            {loading ?
                <div className={classes.loader}>
                    <CircularProgress color='secondary' />
                    <br />
                    <Typography variant='caption'>{loadingText}</Typography>
                </div>
                :
                <div className={classes.root}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    );
}



export default AdminCard;
import React, { Component } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { MenuItem, Button, Link, Grid, TextField } from '@material-ui/core';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SelectTo from '../../../common/selects/SelectTo';
import {  DEFAULT_COUNTRY_CODE } from '../../../../wumdrophubsreactshared/_constants/apiConstants';
import { COMPANIES, COMPANY_PRICING_TIERS, DAY_OF_WEEK_TYPES, PARCEL_TYPES, RATE_TYPES, SLA_TYPES } from '../../../../wumdrophubsreactshared/_constants/typeConstants';
import styles from '../BaseForm.css';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-recompose';




//Formik and Yup Validation
const initialValues = {
    id: 0,
    rateId:'',
    name: '',
    companyId: '',
    parcelTypeId: '',
    company :'',
    contactCountryCode:'',
    pricingTier: '',
    pricingTierId: '',
    price: 0,
    newPrice:0,
    startKmRange: 0,
    endKmRange: 0,
    parcelType:'',
    rateType:'',
    dayOfWeekType :'',
    rateTypeId:0,
    dayOfWeekTypeId :'',
    slaType: '',
    slaTypeId: '',
    portalCountryId:'',
    uploadStatusId:'',
    uploadBatchId:'',
    isReturnToSenderRate: false,
    isHighRisk:false,
    isActive :false,
};

const validationSchema = yup.object().shape({
   
    startKmRange: yup.string().required("Start km  is required"),
    endKmRange: yup.string().required("End km  is required"),
    slaTypeId: yup.number().required("SlaType is required"),
    parcelTypeId: yup.number().required("Parcel Type is required"),
    rateTypeId: yup.string().required("Rate type is required"),
    dayOfWeekTypeId: yup.string().required("Select Day of the week")
});

class RateUploadForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: initialValues
        }
      
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.rateUpload.data !== nextProps.rateUpload.data && !isEmpty(nextProps.rateUpload.data)) {
            let rateUpload = nextProps.rateUpload.data;
            this.setState({rateUpload});
        }
    }

    

    _safeValue = (value) => !isEmpty(value) ? value : '';


    handleChange = (event) => {
        const { initialValues } = this.state;
        
        initialValues[event.target.name] = event.target.value;

        this.setState({ initialValues });
    }

    handleSelectToChange = (selected) => {
        const { initialValues } = this.state;
        initialValues[selected.field] = selected.value;
        this.setState({ initialValues });
    }

    
    componentDidUpdate() {
        const { rateUpload } = this.props;
        if (!isEmpty(rateUpload) && this.state.initialValues.id <= 0) {
            const { initialValues } = this.state;
            let newValues = { ...initialValues };
            newValues.id = rateUpload.id;
            newValues.company = this._safeValue(rateUpload.company);
            newValues.companyId = this._safeValue(rateUpload.companyId);
            newValues.startKmRange = this._safeValue(rateUpload.startKmRange);
            newValues.endKmRange = this._safeValue(rateUpload.endKmRange);
            newValues.pricingTierId= this._safeValue(rateUpload.pricingTierId);
            newValues.parcelTypeId = this._safeValue(rateUpload.parcelTypeId);
            newValues.pricingTier= this._safeValue(rateUpload.pricingTier);
            newValues.parcelType = this._safeValue(rateUpload.parcelType);
            newValues.rateType = this._safeValue(rateUpload.rateType);
            newValues.rateTypeId = this._safeValue(rateUpload.rateTypeId);
            newValues.isActive = this._safeValue(rateUpload.isActive)
            newValues.dayOfWeekType = this._safeValue(rateUpload.dayOfWeekType);
            newValues.dayOfWeekTypeId = this._safeValue(rateUpload.dayOfWeekTypeId);
            newValues.slaType = this._safeValue(rateUpload.slaType);
            newValues.slaTypeId = this._safeValue(rateUpload.slaTypeId);
            newValues.isReturnToSenderRate = this._safeValue(rateUpload.isReturnToSenderRate);
            newValues.isHighRisk = this._safeValue(rateUpload.isHighRisk);
            newValues.price = this._safeValue(rateUpload.price);
            newValues.newPrice = this._safeValue(rateUpload.newPrice);
            newValues.contactCountryCode= this._safeValue(rateUpload.contactCountryCode);
            newValues.portalCountryId= this._safeValue(rateUpload.portalCountryId);
            newValues.uploadBatchId = this._safeValue(rateUpload.uploadBatchId);
            newValues.uploadStatusId   = this._safeValue(rateUpload.uploadStatusId);


            this.setState({ initialValues: newValues });
        }
    }

    handlePlaceSelect() {
        const { initialValues } = this.state;
        let newValues = { ...initialValues };
        this.setState({ initialValues: newValues });
      }



    handleCheckboxChange = event => {
        const { initialValues } = this.state;
        initialValues[event.target.name] = event.target.checked;
        this.setState({ initialValues });
    }
    handleSelectToChange = (selected) => {
        const { initialValues } = this.state;
        initialValues[selected.field] = selected.value;
        this.setState({ initialValues });
    }
    handleSelectChange = (selected, values, setValues) => {
        let newValues = { ...values }
        newValues[selected.field] = selected.value;
        setValues(newValues);
    }


    buildSelectOptions(options) {
        let selectOptions = <div/>;

        if(!isEmpty(options)) {
            selectOptions = options.map((menuItem, index) => {
                return (
                    <MenuItem value={menuItem.key} key={index}>{menuItem.value}</MenuItem>
                )
            }); 
        }
        return selectOptions;
    }

    render() {
        const { saveText, cancelText, handleFormikSubmit,handleCancel , classes, rateUpload } = this.props;
        const { initialValues } = this.state;

        let portalCountryCode = !isEmpty(rateUpload) ? rateUpload.contactCountryCode:DEFAULT_COUNTRY_CODE;


        let btnSaveText = isEmpty(saveText) ? "Save" : saveText;
        let btnCancelText = isEmpty(cancelText) ? "Close" : cancelText;

        let slaTypeValue = {};
        if (!isEmpty(rateUpload.slaTypeId) && !isEmpty(rateUpload.slaType)) {
            slaTypeValue.key = rateUpload.slaTypeId;
            slaTypeValue.value = rateUpload.slaType;
        }
        let rateTypeValue = {};
        if (!isEmpty(rateUpload.rateTypeId) && !isEmpty(rateUpload.rateType)) {
            rateTypeValue.key = rateUpload.rateTypeId;
            rateTypeValue.value = rateUpload.rateType;
        }

        let parcelTypeValue = {};
        if (!isEmpty(rateUpload.parcelTypeId) && !isEmpty(rateUpload.parcelType)) {
            parcelTypeValue.key = rateUpload.parcelTypeId;
            parcelTypeValue.value = rateUpload.parcelType;
        }

        let dayOfWeekValue = {};
        if (!isEmpty(rateUpload.dayOfWeekTypeId) && !isEmpty(rateUpload.dayOfWeekType)) {
            dayOfWeekValue.key = rateUpload.dayOfWeekTypeId;
            dayOfWeekValue.value = rateUpload.dayOfWeekType;
        }

        let pricingTierValue = {};
        if (!isEmpty(rateUpload.pricingTierId) && !isEmpty(rateUpload.pricingTier)) {
            pricingTierValue.key = rateUpload.pricingTierId;
            pricingTierValue.value = rateUpload.pricingTier;
        }


       
        return (
            <Formik
                onSubmit={handleFormikSubmit}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, handleBlur, values, setValues ,errors}) => (
                   
                    <form  onSubmit={handleSubmit} id="rateUploadForm" noValidate >

                    <Grid
                        container
                        spacing={0}
                        alignItems="flex-start"
                        direction="row"
                        justifyContent="flex-start"
                    >
                        {/* Header region */}
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                        
                 <div className={classes.selectField}>
                 <TextField
                        label="Company ID"
                        className={classes.field}
                        onChange={handleChange}
                        name="companyId"
                        id="companyId"
                        value={values.companyId}
                        
                       
                    />
                        <SelectTo
                        fieldName="companyId"
                        id="companyId"
                        label="Company"
                        onSelect={ this.handleSelectToChange } 
                        apiEntity={`${COMPANIES}/${portalCountryCode}`} // NB! you need to supply the name of the entity to search on in the API
                        multiSelect={false}
                        value= {values.companyId}
                    />
                </div>

                    <div/>
                    <TextField
                        label="Start KM Range"
                        className={classes.field}
                        onChange={handleChange}
                        name="startKmRange"
                        id="startKmRange"
                        value={values.startKmRange}
                        
                       
                    />
                    <div/>
                    <TextField
                        label="End KM Range"
                        className={classes.field}
                        onChange={handleChange}
                        name="endKmRange"
                        id="endKmRange"
                        value={values.endKmRange}
                        
                    />
                    <div/>
                    <TextField
                        label="Price"
                        className={classes.field}
                        onChange={handleChange}
                        name="price"
                        id="price"
                        value={values.price}
                        
                    />
                    <TextField
                        label="New Price"
                        className={classes.field}
                        onChange={handleChange}
                        name="newPrice"
                        id="newPrice"
                        value={values.newPrice}
                        
                    />
                    <TextField
                        label="Rate Type Id"
                        className={classes.field}
                        onChange={handleChange}
                        name="rateTypeid"
                        id="txtRateTypeID"
                        disabled
                        value={values.rateTypeId}      
                    />
                    <TextField
                        label="SLA Type Id"
                        className={classes.field}
                        onChange={handleChange}
                        name="slaTypeid"
                        id="txtSlaTypeID"
                        disabled
                        value={values.slaTypeId}  
                    />
                    <TextField
                        label="Parcel Type Id"
                        className={classes.field}
                        onChange={handleChange}
                        name="parcelType"
                        id="txtParcelTypeID"
                        disabled
                        value={values.parcelTypeId}
                    />

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <div/>
                         
                  

                    
                <TextField
                        label="Day Of Week Id"
                        className={classes.field}
                        onChange={handleChange}
                        name="dayOfWeekTypeId"
                        id="txtDOWTypeID"
                        disabled
                        value={values.dayOfWeekTypeId}
                    />
                    <TextField
                        label="Pricing Tier Id"
                        className={classes.field}
                        onChange={handleChange}
                        name="pricingTierId"
                        id="txtPricTypeID"
                        disabled
                        value={values.pricingTierId}
                    />
                      <div/>
                       <SelectTo
                        fieldName={'parcelTypeId'}
                        label={'Parcel Size'}
                        value={values.parcelTypeId}
                        values={parcelTypeValue}  
                        onSelect={ this.handleSelectToChange }      
                        apiEntity={`${PARCEL_TYPES}/getIdKeyValue`} // NB! you need to supply the name of the entity to search on in the API
                        multiSelect={false}
                  
                    />

                    <SelectTo 
                        fieldName="rateTypeId"
                        label="Rate Type"
                        onSelect={ this.handleSelectToChange } 
                        values = {rateTypeValue}
                        value ={values.rateTypeId}
                        apiEntity={`${RATE_TYPES}/getIdKeyValue`} // NB! you need to supply the name of the entity to search on in the API
                        multiSelect={false}
                    />
       
                    <div/>

                    <SelectTo 
                     onSelect={ this.handleSelectToChange } 
                     apiEntity={`${SLA_TYPES}/getIdKeyValue`} // NB! you need to supply the name of the entity to search on in the API
                     multiSelect={false}
                     value= {values.slaTypeId}
                     fieldName="slaTypeId"
                     label="SLA Type"
                      values={slaTypeValue}
                    />
                       
                    <div/>
                    <SelectTo 
                        fieldName="pricingTierId"
                        label="Pricing Tier"
                        onSelect={ this.handleSelectToChange } 
                        apiEntity={`${COMPANY_PRICING_TIERS}/getIdKeyValue`} // NB! you need to supply the name of the entity to search on in the API
                        multiSelect={false}
                        value={values.pricingTierId}
                       values={pricingTierValue}
                    />
                     
                    <SelectTo 
                        fieldName="dayOfWeekTypeId"
                        label="Day of Week"
                        apiEntity={`${DAY_OF_WEEK_TYPES}/getIdKeyValue`} // NB! you need to supply the name of the entity to search on in the API
                        multiSelect={false}
                        values={dayOfWeekValue}
                        value={values.dayOfWeekTypeId}
                        onSelect={(selected) => this.handleSelectToChange(selected, values, setValues, true)}
                     
                    />
                        
                    <div/>
   
                    <div/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className={classes.actionsBar}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    
                                        onChange={handleChange}
                                        onBlur={handleBlur}                             
                                        className={classes.isReturnToSenderRateField}
                                        checked={values.isReturnToSenderRate}                                 
                                        value={values.isReturnToSenderRate}
                                        name="isReturnToSenderRate"
                                        id="isReturnToSenderRate"
                                        color="primary"
                                    />
                                }
                                label="Return To Sender Rate"
                            />
                            <div/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.isReturnToSenderRateField}
                                        checked={values.isHighRisk}
                                        onChange={handleChange}
                                        onBlur={handleBlur}     
                                        value={values.isHighRisk}
                                        name="isHighRisk"
                                        id="isHighRisk"
                                        color="primary"
                                    />
                                }
                                label="Area Surcharge"
                            />
                            <div/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.isActiveField}
                                        checked={values.isActive}
                                        onChange={handleChange}
                                        onBlur={handleBlur}     
                                        value={values.isActive}
                                        name="isActive"
                                        id="isActive"
                                        color="primary"
                                    />
                                }
                                label="Active"
                            />
                    
                        </Grid>
                    </Grid>
              
                    <div className={classes.actionsBar}>
                            {
                                isEmpty(handleCancel) ?
                                    <Button variant="contained" color="primary" className={classes.cancelButton} component={Link} to={'/'}>{btnCancelText}</Button>
                                    :
                                    <Button variant="contained" color="primary" className={classes.cancelButton} onClick={handleCancel}>{btnCancelText}</Button>
                            }
                            <Button id="submitB" variant="contained" className={classes.submitButton} type="submit">{btnSaveText}</Button>
                        </div>
                    </form>
                )}
            </Formik>)
    }
}

export default compose(
    withWidth(),
    withStyles(styles)
)(RateUploadForm);

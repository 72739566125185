import { POST_RATINGS, PUT_RATINGS, GET_RATINGS, CLEAR_RATINGS, CLEAR_RATINGS_SUCCESS, DELETE_RATINGS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_RATINGS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }

        case POST_RATINGS:
            return {
                ...state,
                success: `${POST_SUCCESS} rating`
            }

        case PUT_RATINGS:
            return {
                ...state,
                success: `${PUT_SUCCESS} rating`
            }

        case DELETE_RATINGS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} rating(s).`
            }

        case CLEAR_RATINGS:
            return {
                ...state,
                data: {},
                success: ""
            }

        case CLEAR_RATINGS_SUCCESS:
            return {
                ...state,
                success: ""
            }
        default:
            return state;
    }
}
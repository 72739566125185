import { POST_SEND_EMAILS, CLEAR_EMAIL, SET_LOADING_SEND_EMAILS, SET_EMAIL_DIALOG_OPEN } from '../_actions/types';

const initialState = {
    data: {},
    loading: false,
    success: false,
    emailDialogOpen: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case POST_SEND_EMAILS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                success: true,
                emailDialogOpen: false
            }
        case CLEAR_EMAIL: 
            return {
                ...state,
                data: action.payload,  
                loading: false,
                success: false
            } 
        case SET_LOADING_SEND_EMAILS:
            return {
                ...state,
                loading: action.payload
            }
        case SET_EMAIL_DIALOG_OPEN:
            return {
                ...state,
                emailDialogOpen: action.payload
            }    
        default:
            return state;
    }
}
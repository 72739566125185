import { GET_NOTES, CLEAR_NOTES, CLEAR_NOTES_SUCCESS, POST_NOTES, PUT_NOTES, GET_MORE_NOTES } from "../_actions/types"
import { POST_SUCCESS, PUT_SUCCESS } from "../_constants/stringConstants"

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_NOTES:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case GET_MORE_NOTES:
            return {
                ...state,
                data: { ...state.data, items: [...state.data.items, ...action.payload.items] },
                search: action.search
            }
        case CLEAR_NOTES:
            return {
                ...state,
                data: {},
                success: ""
            }
        case CLEAR_NOTES_SUCCESS:
            return {
                ...state,
                success: ""
            }
        case POST_NOTES:
            let newItems = [];
            if (Array.isArray(action.payload)) {
                newItems = action.payload;
            } else {
                newItems = state.data.items.filter(item => item.id !== action.payload.id);
                newItems.unshift(action.payload);
            }
            return {
                ...state,
                data: { ...state.data, items: newItems },
                success: `${POST_SUCCESS} note`
            }
        case PUT_NOTES:
            let updateItems = [...state.data.items];
            if (Array.isArray(action.payload)) {
                updateItems = action.payload;
            } else {
                let foundIndex = updateItems.findIndex(x => x.id === action.payload.id);
                updateItems[foundIndex] = action.payload;
            }
            return {
                ...state,
                data: { ...state.data, items: updateItems },
                success: `${PUT_SUCCESS} note`
            }
        default:
            return state;
    }
}
export default theme => ({
    selectContainer: {
      padding: 0
    },
    inputIcon: {
      height: "33px",
      position: "absolute",
      zIndex: "1",
      marginTop: 9,
      marginLeft: 6,
      marginRight: 2
    }
  });
import { GET_AVAILABLE_DATES, GET_AVAILABLE_TIMESLOTS } from '../_actions/types';

const initialState = {
    dates: [],
    timeslots: []
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_AVAILABLE_DATES:
            return {
                ...state,
                dates: action.payload
            }
        case GET_AVAILABLE_TIMESLOTS:
            return {
                ...state,
                timeslots: action.payload
            }
        default:
            return state;
    }
}
import {
    SIDEBAR_TOGGLE,
    LEFT_SIDEBAR_TOGGLE,
    RIGHT_SIDEBAR_TOGGLE,
    USER_MENU_TOGGLE,
    DELIVERY_DETAILS_TOGGLE,
    JOB_DETAILS_TOGGLE,
    TASK_MODAL_TOGGLE,
    JOB_TASK_LIST_TOGGLE,
    BARCODE_SCANNER_TOGGLE,
    JOB_TASK_LIST_MULTIPLE_TOGGLE,
    JOB_ROUTE_TOGGLE,
    WHATSAPP_TOGGLE,
    DRIVER_LOADING_ON,
    DRIVER_LOADING_OFF,
    DELIVERY_LOADING_ON,
    DELIVERY_LOADING_OFF,
    JOB_LOADING_ON,
    JOB_LOADING_OFF,
    DELIVERY_WIZARD_TOGGLE,
    CASH_DEPOSIT_MODAL_TOGGLE,
    REFRESH_DRIVER_LIST_ON,
    REFRESH_DRIVER_LIST_OFF,
    CLEAR_ALL_TASK_LIST_TOGGLES
} from '../_actions/types';

import { SIDEBAR_WIDTH } from '../_constants/styleConstants';
import isEmpty from '../_utils/isEmpty';

const tooggleDefault = { open: false };
const initialState = {
    sideBar: tooggleDefault,
    leftSideBar: { ...tooggleDefault, width: SIDEBAR_WIDTH },
    rightSideBar: { ...tooggleDefault, width: SIDEBAR_WIDTH },
    userMenu: tooggleDefault,
    jobDetails: tooggleDefault,
    deliveryDetails: tooggleDefault,
    taskWizard: { ...tooggleDefault, loading: false, step: 0, data: {} },
    deliveryWizard: { ...tooggleDefault, loading: false, step: 0 },
    barcodeScannerModal: { ...tooggleDefault, jobId: null, deliveryId: null },
    jobRouteModal: { ...tooggleDefault, jobId: null },
    whatsAppModal: { ...tooggleDefault, name: null, number: null },
    jobsLoading: [],
    driversLoading: [],
    deliveriesLoading: [],
    jobTasksMultipleExpanded: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SIDEBAR_TOGGLE:
            return {
                ...state,
                sideBar: action.payload
            }
        case LEFT_SIDEBAR_TOGGLE:
            return {
                ...state,
                leftSideBar: action.payload
            }
        case RIGHT_SIDEBAR_TOGGLE:
            return {
                ...state,
                rightSideBar: action.payload
            }
        case USER_MENU_TOGGLE:
            return {
                ...state,
                userMenu: action.payload
            }
        case DELIVERY_WIZARD_TOGGLE:
            return {
                ...state,
                deliveryWizard: action.payload
            }
        case DELIVERY_DETAILS_TOGGLE:
            return {
                ...state,
                deliveryDetails: action.payload
            }
        case JOB_DETAILS_TOGGLE:
            return {
                ...state,
                jobDetails: action.payload
            }
        case TASK_MODAL_TOGGLE:
            return {
                ...state,
                taskWizard: action.payload
            }
        case BARCODE_SCANNER_TOGGLE: {
            return {
                ...state,
                barcodeScannerModal: action.payload
            }
        }
        case JOB_ROUTE_TOGGLE: {
            return {
                ...state,
                jobRouteModal: action.payload
            }
        }
        case CASH_DEPOSIT_MODAL_TOGGLE: {
            return {
                ...state,
                depositModal: action.payload
            }
        }
        case WHATSAPP_TOGGLE: {
            return {
                ...state,
                whatsApp: action.payload
            }
        }

        case REFRESH_DRIVER_LIST_ON:
            return {
                ...state,
                refreshDriverList: true
            }

        case REFRESH_DRIVER_LIST_OFF:
            return {
                ...state,
                refreshDriverList: false
            }

        case DELIVERY_LOADING_ON:
            let deliveriesLoadingOn = [...state.deliveriesLoading];

            action.payload.forEach(deliveryId => {
                let deliveriesLoadingOnIndex = deliveriesLoadingOn.indexOf(deliveryId);
                if (deliveriesLoadingOnIndex === -1) deliveriesLoadingOn.push(deliveryId);
            });

            return {
                ...state,
                deliveriesLoading: deliveriesLoadingOn
            }

        case DELIVERY_LOADING_OFF:
            let deliveriesLoadingOff = [...state.deliveriesLoading];

            action.payload.forEach(deliveryId => {
                let deliveriesLoadingOffIndex = deliveriesLoadingOff.indexOf(deliveryId);
                if (deliveriesLoadingOffIndex !== -1) deliveriesLoadingOff.splice(deliveriesLoadingOffIndex, 1);

            });

            return {
                ...state,
                deliveriesLoading: deliveriesLoadingOff
            }

        case JOB_LOADING_ON:
            let jobsLoadingOn = [...state.jobsLoading];

            action.payload.forEach(jobId => {
                let jobsLoadingOnIndex = jobsLoadingOn.indexOf(jobId);
                if (jobsLoadingOnIndex === -1) jobsLoadingOn.push(jobId);

            });

            return {
                ...state,
                jobsLoading: jobsLoadingOn
            }

        case JOB_LOADING_OFF:
            let jobsLoadingOff = [...state.jobsLoading];

            action.payload.forEach(jobId => {
                let jobsLoadingOffIndex = jobsLoadingOff.indexOf(jobId);
                if (jobsLoadingOffIndex !== -1) jobsLoadingOff.splice(jobsLoadingOffIndex, 1);
            });

            return {
                ...state,
                jobsLoading: jobsLoadingOff
            }


        case DRIVER_LOADING_ON:
            let driversLoadingOn = [...state.driversLoading];

            action.payload.forEach(driverId => {
                let driversLoadingOnIndex = driversLoadingOn.indexOf(driverId);
                if (driversLoadingOnIndex === -1) driversLoadingOn.push(driverId);
            });

            return {
                ...state,
                driversLoading: driversLoadingOn
            }

        case DRIVER_LOADING_OFF:
            let driversLoadingOff = [...state.driversLoading];

            action.payload.forEach(driverId => {
                let driversLoadingOffIndex = driversLoadingOff.indexOf(driverId);
                if (driversLoadingOffIndex !== -1) driversLoadingOff.splice(driversLoadingOffIndex, 1);
            });

            return {
                ...state,
                driversLoading: driversLoadingOff
            }

        case JOB_TASK_LIST_TOGGLE:
            let existingJobTasksExpanded = state.jobTasksExpanded;
            let newJobTaskExpanded = action.payload;
            if (!isEmpty(existingJobTasksExpanded) && existingJobTasksExpanded === action.payload) {
                newJobTaskExpanded = "";
            }
            return {
                ...state,
                jobTasksExpanded: newJobTaskExpanded
            }
        case CLEAR_ALL_TASK_LIST_TOGGLES:
            return {
                ...state,
                jobTasksMultipleExpanded: [],
                jobTasksExpanded: ''
            }
        case JOB_TASK_LIST_MULTIPLE_TOGGLE:
            let existingJobTasksMultipleExpanded = state.jobTasksMultipleExpanded;
            let newJobTaskMultipleExpanded = action.payload;
            let taskExpandedIndex = existingJobTasksMultipleExpanded.indexOf(newJobTaskMultipleExpanded);

            if (taskExpandedIndex !== -1) {
                // if we are toggling already opened panel to closed state
                existingJobTasksMultipleExpanded.splice(taskExpandedIndex, 1);
            } else {
                existingJobTasksMultipleExpanded.push(newJobTaskMultipleExpanded);
            }

            return {
                ...state,
                jobTasksMultipleExpanded: existingJobTasksMultipleExpanded
            }

        default:
            return state;
    }
}
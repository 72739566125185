import { GET_COMPANY_PRICING_TIERS, SET_LOADING_COMPANY_PRICING_TIERS } from '../_actions/types';

const initialState = {
    data: {},
    loading: false
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_COMPANY_PRICING_TIERS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case SET_LOADING_COMPANY_PRICING_TIERS:
                return {
                    ...state,
                    loading: action.payload
                }
        default: 
            return state;
    }
}
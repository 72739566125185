import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './ConnectButton.css';
import { CircularProgress } from '@material-ui/core';

class ConnectButton extends React.Component {

    handleRefresh = () => {
        if(this.props.onRefresh) {
            this.props.onRefresh();
        }
    }    

    render() {
        const { classes, message } = this.props;
    
        return (
            <div className={classes.buttonContainer}>
                <Button variant="contained" size="small" color="primary" fullWidth={true} className={classes.button} onClick={this.handleRefresh}>
                    {message}
                    <CircularProgress className={classes.progress}/>
                </Button>
            </div>
        );
    }
}

ConnectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired
};

export default withStyles(styles)(ConnectButton);
import { GET_AREA, CLEAR_AREA, POST_AREA, PUT_AREA, GET_AREA_INTERACTIONS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {}, 
    success: ""
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_AREA:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_AREA:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} area`
            }
        case PUT_AREA:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} area`
            }
        case CLEAR_AREA:
            return {
                ...state,
                data: '',
                success: ''
            }
        case GET_AREA_INTERACTIONS:
            return {
                ...state,
                interactions: action.payload
            }
        default: 
            return state;
    }
}
import { CLEAR_ERRORS, CLEAR_ERRORS_SESSION, CLEAR_ERRORS_SUCCESS, GET_ERRORS } from './types';

export const setError = (payload) => dispatch => {
    return dispatch({
        type: GET_ERRORS,
        payload: payload
    });
}

export const clearErrors = () => dispatch => {
    dispatch({
        type: CLEAR_ERRORS,
        payload: ''
    });
}

export const clearErrorsSuccess = () => dispatch => {
    dispatch({
        type: CLEAR_ERRORS_SUCCESS,
        payload: ''
    });
}

export const clearErrorsSession = () => dispatch => {
    dispatch({
        type: CLEAR_ERRORS_SESSION,
        payload: ''
    });
}
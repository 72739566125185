import { GET_HANDLING_TYPE } from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_HANDLING_TYPE:
            return {
                ...state,
                data: action.payload
            }            
        default:
            return state;
    }
}
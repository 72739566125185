import { GET_TERRITORIES, 
        POST_TERRITORIES, 
        PUT_TERRITORIES, 
        DELETE_TERRITORIES,
        CLEAR_TERRITORIES,
        CLEAR_TERRITORIES_SUCCESS,
        GET_TERRITORY_PERMISSIONS
        } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: "" 
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_TERRITORIES:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_TERRITORIES:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} territory`
            }
        case PUT_TERRITORIES:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} territory`
            }
        case DELETE_TERRITORIES:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} territories.`
            }
        case CLEAR_TERRITORIES_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_TERRITORIES: 
            return {
                ...state,
                data: {},
                success: ''
            }
        case GET_TERRITORY_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            }
        default: 
            return state;
    }
}
import { GET_REPORT_DELIVERY_SUMMARY, CLEAR_REPORT_DELIVERY_SUMMARY } from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_REPORT_DELIVERY_SUMMARY:
            return {
                ...state,
                data: action.payload
            }
        case CLEAR_REPORT_DELIVERY_SUMMARY:
            return {
                ...state,
                data: {}
            }
        default:
            return state;
    }
}
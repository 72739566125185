import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../_reducers';
import signalRMiddleware from '../_middleware/signalRMIddleware';


const inititalState = {};
const middleware = [thunk, signalRMiddleware]

const store =   
 process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined ? 
        //development store with redux dev tools
        createStore(
                rootReducer, 
                inititalState, 
                compose(applyMiddleware(...middleware), 
                window.__REDUX_DEVTOOLS_EXTENSION__&& window.__REDUX_DEVTOOLS_EXTENSION__())) 
        :
        //production store without redux dev tools
        createStore(
                rootReducer, 
                inititalState, 
                compose(applyMiddleware(...middleware))); 
      

export default store;
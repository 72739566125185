import moment from 'moment-timezone'

//this function checks to determine if a UTC date is in the past
const isPassedUtcDate = (utcDate) => {

    let nowUtc = moment.utc();
    let targetUtc = moment(utcDate);

    if(targetUtc.isBefore(nowUtc)) {
        return true;
    } else {
        return false;
    }   
}

export default isPassedUtcDate;
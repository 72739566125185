import { GetEnvironment, GetEnvironmentAsync } from "../../devenvironmentutil";

const getConfigBase = (environment) => {
  let configObject = {
    REACT_APP_API_PREFIX: "api",
    REACT_APP_ENVIRONMENT: `${process.env.REACT_APP_ENVIRONMENT}`,
    REACT_APP_HOST_PROTOCOL: `${process.env.REACT_APP_HOST_PROTOCOL}`,

    REACT_APP_API_HOST: `${process.env.REACT_APP_API_HOST}`,
    REACT_APP_BEACON_HOST: `${process.env.REACT_APP_BEACON_HOST}`,
    REACT_APP_PUBLIC_HOST: `${process.env.REACT_APP_PUBLIC_HOST}`,
    REACT_APP_CONTENT_HOST: `${process.env.REACT_APP_CONTENT_HOST}`,
    REACT_APP_SUPERSET_HOST: `${process.env.REACT_APP_SUPERSET_HOST}`,

    REACT_APP_PEACH_URL: `${process.env.REACT_APP_PEACH_URL}`,
    REACT_APP_SUPERTSET_PROXY: `${process.env.REACT_APP_SUPERTSET_PROXY}`,

    REACT_APP_GOOGLE_ANALYTICS_ID: `${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`,
    REACT_APP_GOOGLE_API_KEY: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
    
    //Mobile 
    REACT_GOOGLE_IOS_CLIENT_ID: `${process.env.REACT_GOOGLE_IOS_CLIENT_ID}`,
    REACT_GOOGLE_ANDROID_CLIENT_ID: `${process.env.REACT_GOOGLE_ANDROID_CLIENT_ID}`,
    //Web
    REACT_APP_GOOGLE_ID: `${process.env.REACT_APP_GOOGLE_ID}`,
    REACT_APP_INTERCOM_APP_ID: `${process.env.REACT_APP_INTERCOM_APP_ID}`
  };

  switch (environment) {
    default:
    case "local":
      return configObject;

    case "staging":
      configObject = {
        ...configObject,
        REACT_APP_ENVIRONMENT: environment,
        REACT_APP_HOST_PROTOCOL: "https",
        REACT_APP_API_HOST: "staging-connect.wumdrop.com",
        REACT_APP_BEACON_HOST: "staging-beacon.wumdrop.com",
        REACT_APP_PUBLIC_HOST: "staging-public.wumdrop.com",
        REACT_APP_CONTENT_HOST: "staging-content.wumdrop.com",
        REACT_APP_SUPERSET_HOST: "staging-supersetapi.wumdrop.com",
        RREACT_APP_SUPERTSET_PROXY: "https://superset.wumdrop.com",
        REACT_APP_GOOGLE_ANALYTICS_ID: "G-D0QXGYM5ZZ",
      };

      return configObject;

    case "integrations":
      configObject = {
        ...configObject,
        REACT_APP_ENVIRONMENT: environment,
        REACT_APP_HOST_PROTOCOL: "https",
        REACT_APP_API_HOST: "integrations-connect.wumdrop.com",
        REACT_APP_BEACON_HOST: "integrations-beacon.wumdrop.com",
        REACT_APP_PUBLIC_HOST: "integrations-public.wumdrop.com",
        REACT_APP_CONTENT_HOST: "integrations-content.wumdrop.com",
        REACT_APP_SUPERSET_HOST: "integration-supersetapi.wumdrop.com",
        RREACT_APP_SUPERTSET_PROXY: "https://integrationsuperset.wumdrop.com",
        REACT_APP_GOOGLE_ANALYTICS_ID: "G-EN9T8FMK4K"
      };
      return configObject;

    case "production":
      configObject = {
        ...configObject,
        REACT_APP_ENVIRONMENT: environment,
        REACT_APP_HOST_PROTOCOL: "https",
        REACT_APP_API_HOST: "connect.wumdrop.com",
        REACT_APP_BEACON_HOST: "beacon.wumdrop.com",
        REACT_APP_PUBLIC_HOST: "public.wumdrop.com",
        REACT_APP_CONTENT_HOST: "content.wumdrop.com",
        REACT_APP_PEACH_URL: "https://oppwa.com",
        REACT_APP_SUPERSET_HOST: "production-supersetapi.wumdrop.com",
        RREACT_APP_SUPERTSET_PROXY: "https://productionsuperset.wumdrop.com",
        REACT_APP_GOOGLE_ANALYTICS_ID: "G-ZSKWKBKPTD",
      }
  };

  return configObject;
};

export const getConfig = () => {
  let environment = GetEnvironment();
  return getConfigBase(environment);
};

export const getConfigAsync = async () => {
  let environment = await GetEnvironmentAsync();
  return getConfigBase(environment);
};
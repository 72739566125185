import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import mapQuoteToDelivery from 'wumdrophubsreactshared/_utils/mapQuoteToDelivery';
import { clearErrors } from 'wumdrophubsreactshared/_actions/errors';
import { Formik } from 'formik';
import * as yup from 'yup';
import LocationTimeForm from "../../common/forms/LocationTimeForm";
import { Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import hasPermission from 'wumdrophubsreactshared/_utils/hasPermission';

// @material-ui / core components
import withStyles from "@material-ui/core/styles/withStyles";

import appStyles from "App.css";

//Formik and Yup Validation
const validationSchema = yup.object().shape({
    originAddress: yup.object().nullable().required("Pick-up address is required"),
    destinationAddress: yup.object().nullable().required("Drop-off is required"),
    deliveryDate: yup.string().nullable().required("Date is required")
});

class DeliveryStep extends Component {

    handleFormikSubmit(values) {
        this.props.clearErrors();
        values.deliverByDate = values.deliveryDate;
        this.props.handleStepSubmit(values);
    }

    render() {
        const { wizardRef, classes, auth } = this.props;

        let timeZone = "Africa/Johannesburg";
        if (!isEmpty(this.props.portal.data) && !isEmpty(this.props.portal.data.timeZone)) {
            timeZone = this.props.portal.data.timeZone;
        }

        let initialValues = mapQuoteToDelivery(this.props.quoteResponse, timeZone);
        let showStorePickers = hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isCompanyOperator: true, isCompanyNormalUser: true, isStoreAdministrator: true, isStoreOperator: true, isStoreNormalUser: true });

        return (
            <Formik
                onSubmit={values => this.handleFormikSubmit(values)}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, handleBlur, values, setValues }) => (
                    <form onSubmit={handleSubmit} id={`${wizardRef}1`} noValidate>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography className={classes.deliveryStepHeader}>
                                    Enter your logisitic requirements
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <LocationTimeForm
                                    refreshProps={false}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setValues={setValues}
                                    showMap={true}
                                    showStorePickers={showStorePickers}
                                    dateTimePickerWidth={6}
                                    showTimeSlotDropDown={true}
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    serverErrors: state.errors,
    portal: state.portal
})

export default compose(
    withStyles(appStyles, { name: "DeliveryStep" }),
    withRouter,
    connect(mapStateToProps, { clearErrors })
)(DeliveryStep);
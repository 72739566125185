import { feedback } from "assets/jss/material-kit-pro-react.jsx";
import {
    BLUE_COLOR,
    PRIMARY_COLOR,
    TERTIARY_COLOR,
    SMALL_FONT, LIGHT_GREY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";

export default theme => ({
    feedback: {
        ...feedback
    },
    gridItem: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important"
    },
    gridItemNoPadding: {
        padding: 0
    },
    group: {
        flexDirection: "row",
        marginLeft: 3,
        marginBottom: 5
    },
    formControl: {
        margin: "0 0 10px 0",
        position: "relative",
        padding: 0
    },
    formControlNoMargin: {
        margin: 0,
        position: "relative",
        padding: 0
    },
    addressName: {
        width: "calc(100% - 110px)"
    },
    addressUnit: {
        marginLeft: "10px !important",
        width: "100px"
    },
    switch: {},
    button: {
        padding: 5,
        marginLeft: -5,
        marginRight: 15,
    },
    buttonChecked: {
        padding: 5,    
        color: BLUE_COLOR,
        marginLeft: -5
    },
    buttonOutlined: {
        backgroundColor: "white",
        border: "1px solid black",
        padding: "8px 14px",
        color: "black",
        textTransform: "capitalize",
        fontSize: 12,
    },
    mainContainer: {
        paddingLeft: 5
    },
    displayAddress: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: '5px 0px 0px 5px',
        fontSize: "0.85em",
        fontWeight: 600
    },
    displayAddressContainer: {
        display: 'flex',
    },
    heading: {
        fontSize: "0.9em",
        fontWeight: 600,
        color: PRIMARY_COLOR,
        marginTop: 5
    },
    checkboxLabel: {
        fontSize: 14,
        lineHeight: "43px",
        paddingLeft: 4,
        [theme.breakpoints.down('sm')]: {
            lineHeight: "19px",
            marginTop: 12,
            marginBottom: 12
        }
    },
    radioLabel: {
        //fontSize: "0.9em",
        fontSize: SMALL_FONT
    },
    labelPlacementStart: {
        marginLeft: 0,
    },
    checkbox: {
        padding: 3,
        marginLeft: 5,
        marginRight: 3
    },
    inputTextField: {
        fontSize: 12,
        "&::placeholder": {
            fontSize: "1em",
        }
    },
    addressDetailsContainer: {
        marginLeft: -5,
        marginBottom: 5,
        marginTop: 5,
        
    },
    textFieldNoMargin: {
        marginBottom: 0
    },
    divider: {
        marginTop: 5,
        marginBottom: 5
    },
    spacing: {
        height: 5
    },
    addressTitle: {
        color: TERTIARY_COLOR,
        fontWeight: "bold",
        fontSize: SMALL_FONT,
        lineHeight: "19px",
        [theme.breakpoints.up('md')]: {
            marginTop: "5px",
            lineHeight: "20px"
        }
    },
    myDetails: {
        border: "1px solid",
        borderColor: LIGHT_GREY_COLOR,
        borderRadius: 3,
        padding: 12,
    },
    detailsBox: {
      marginTop: 7, 
      marginBottom: 12,  
    },
    radioText: {
        float: "left",
        lineHeight: "28px",
        paddingRight: 10,
        fontSize: SMALL_FONT,
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 6
        }
    }
});
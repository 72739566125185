import { GET_TASKS, POST_TASKS, PUT_TASKS, CLEAR_TASKS, DELETE_TASKS, CLEAR_TASKS_SUCCESS, SET_LOADING_TASKS, UPDATE_JOB_TASKS, CONCAT_TASKS, CLEAR_JOB_TASK } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';
import isEmpty from '../_utils/isEmpty';
import { TERMINAL_TASK_STATUSES } from '../_constants/apiConstants';
import { JOB_TASK_TYPES } from '../_constants/typeConstants';

const initialState = {
    data: {},
    success: "",
    loading: false,
    jobId: null
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_TASKS:
            return {
                ...state,
                data: {
                    // sort by task job order
                    ...action.payload, items: action.payload.items
                        .sort((a, b) => parseInt(a.taskJobOrder) - parseInt(b.taskJobOrder))
                        .reduce((obj, item) => {
                            obj[`#${item.id}`] = item; //key by id for O(1) lookup
                            return obj;
                        }, {})
                },
                jobId: !isEmpty(action.payload.items) ? action.payload.items[0].jobId : null,
                loading: false,
                search: action.search
            }
        case CONCAT_TASKS:
            let taskItems = []
            
            if(Object.keys(state.data).includes('items')){
                taskItems = state.data.items;
                for (const item of action.payload.items) {
                    taskItems[`#${item.id}`] = item;
                    
                }
            }else{
                taskItems =  action.payload.items;
                taskItems.sort((a, b) => parseInt(a.taskJobOrder) - parseInt(b.taskJobOrder))
                taskItems = taskItems.reduce((obj, item) => {
                    obj[`#${item.id}`] = item; //key by id for O(1) lookup
                    return obj;
                }, {})
            }

           

            return {
                ...state,
                data: {
                    // sort by task job order
                    ...action.payload, 
                    items: taskItems
                },
                jobId: !isEmpty(action.payload.items) ? action.payload.items[0].jobId : null,
                loading: false,
                search: action.search
            }
        case UPDATE_JOB_TASKS:
            //here action.payload is hash map (i.e., Map()) of id = taskId, newTask = task DTO
            //we loop through our hashmap and update the tasks that have changed in the currently visible task list
            let tasks = { ...state.data.items };
            for (const [id, newTask] of action.payload.entries()) {
                let existingTask = tasks[id];
                if (existingTask) {
                    // make sure xmin of new task is greater than old task && check if the status of a task has changed
                    if (newTask.xmin > existingTask.xmin && (existingTask.taskStatusId !== newTask.taskStatusId || existingTask.arrivedDate !== newTask.arrivedDate)) {
                        if (!TERMINAL_TASK_STATUSES.includes(existingTask.taskStatusId) &&
                            !TERMINAL_TASK_STATUSES.includes(newTask.taskStatusId)) {
                            // task has changed from a non-terminal state to another non-terminal state 
                            // (i.e., not started to in progress or vice versa)
                            tasks[id] = newTask;
                        }

                        if (!TERMINAL_TASK_STATUSES.includes(existingTask.taskStatusId) &&
                            TERMINAL_TASK_STATUSES.includes(newTask.taskStatusId)) {
                            // task has changed from a non terminal state to a terminal, remove it from the list
                            delete tasks[id];
                        }
                        // if status hasn't changed, check if distance has changed
                    } else if (newTask.xmin > existingTask.xmin && existingTask.distanceFromDriver !== newTask.distanceFromDriver) {
                        // if distance has changed, go and update
                        tasks[id] = newTask;
                    }
                } else {
                    // the task that changed is not currently in the list

                    // first we check the task has been moved into the terminal state
                    if (TERMINAL_TASK_STATUSES.includes(newTask.taskStatusId)) {
                        // then make sure we are looking at the terminal tasks for the correct job
                        if (newTask.jobId === state.jobId && !isEmpty(state.data.jobTaskType) && state.data.jobTaskType === JOB_TASK_TYPES.COMPLETED) {
                            // then go ahead and add the terminal task to the list
                            tasks[id] = newTask;
                        }
                    } else if (newTask.jobId === state.jobId &&
                        !isEmpty(state.data.jobTaskType) &&
                        state.data.jobTaskType === JOB_TASK_TYPES.INCOMPLETE) {
                        // its not in a terminal state so make sure we are looking at incomplete tasks and add it
                        tasks[id] = newTask;
                    }
                }
            }

            return {
                ...state,
                data: { ...state.data, items: tasks }
            }
        case POST_TASKS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} task`
            }
        case PUT_TASKS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} task`
            }
        case DELETE_TASKS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} tasks.`
            }
        case CLEAR_TASKS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_TASKS:
            return {
                ...state,
                data: {},
                success: '',
                loading: false
            }
        case CLEAR_JOB_TASK:
            
        let tasksWithJobId = Object.values(state.data.items).filter(f => f.jobId !== action.payload)
        tasksWithJobId =  tasksWithJobId.reduce((obj, item) => {
            obj[`#${item.id}`] = item; 
            return obj;
        }, {})
        
        return {
            ...state,
            data: { 
                ...state.data, 
                items: tasksWithJobId
            },
            loading: false,
            success: ''
        }
        case SET_LOADING_TASKS:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
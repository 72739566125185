import isEmpty from '../_utils/isEmpty';
import getCountDownTimeUtc from './getCountdownTime';

const mapQouteToSlaTypes = (quoteResponse, timeZone) => {
    if(isEmpty(timeZone)) {
        timeZone = "Africa/Johannesburg";
    }

    let quoteTypes = {
        slaTypes: [],
        initialValues: {
            deliveryId: null,
            quoteId: null,
            // deliveryDate: null,
            deliveryTime: null
        }
    }
    if (!isEmpty(quoteResponse) && !isEmpty(quoteResponse.deliveryQuotes)) {
            let slaTypes = quoteResponse.deliveryQuotes.map(quoteType => ({
                id: quoteType.id,
                slaType: quoteType.slaType,
                priceExcl: quoteType.priceExcl,
                vatRate: quoteType.vatRate,
                pickupWindowStartUtc: quoteType.pickupWindowStartUtc,
                pickupWindowEndUtc: quoteType.pickupWindowEndUtc,
                dropOffWindowStartUtc: quoteType.dropOffWindowStartUtc,
                dropOffWindowEndUtc: quoteType.dropOffWindowEndUtc,
                quoteExpirationUtc: quoteType.quoteExpirationUtc,
                expirationCountdown: getCountDownTimeUtc(quoteType.quoteExpirationUtc)
            }))

            quoteTypes.slaTypes = slaTypes;
            quoteTypes.initialValues = {
                deliveryId: quoteResponse.deliveryId,
                quoteId: quoteResponse.selectedDeliveryQuoteId,
                // deliveryDate: convertUtcToLocalDate(quoteResponse.readyForCollectionDateOnlyUtc, timeZone, false),
                deliveryTime: !isEmpty(quoteResponse.readyForCollectionTimeSlotLocal) ? quoteResponse.readyForCollectionTimeSlotLocal : null
            }
    }
    return quoteTypes;
}
export default mapQouteToSlaTypes;
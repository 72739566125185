const lazyRetry = (lazyComponent, interval = 1500, attemptsLeft = 30) => {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // retry after interval (milliseconds) for a max number of attempts
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    lazyRetry(lazyComponent, interval, attemptsLeft - 1).then(resolve, reject);
                }, interval);
            });
    });
}

export default lazyRetry;
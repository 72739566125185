import moment from 'moment-timezone'
import isEmpty from './isEmpty';
import { LOCAL_TIME_FORMAT } from '../_constants/apiConstants';

const convertUtcToLocalTime = (dateString, timeZone, format=LOCAL_TIME_FORMAT) => {

    //required date and timezone to format
    if(isEmpty(dateString) || isEmpty(timeZone)) {
        return dateString;
    }

    if(dateString.charAt(dateString.length-1) !== 'Z') {
        dateString = `${dateString}Z`;
    } 

    return moment(dateString).tz(timeZone).format(format);
}

export default convertUtcToLocalTime;
  
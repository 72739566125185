import {
    SIDEBAR_TOGGLE,
    LEFT_SIDEBAR_TOGGLE,
    RIGHT_SIDEBAR_TOGGLE,
    USER_MENU_TOGGLE,
    LEFT_SIDEBAR_RESIZE,
    RIGHT_SIDEBAR_RESIZE,
    DASHBOARD_LEFT_TABS_VALUE,
    DASHBOARD_RIGHT_TABS_VALUE,
    DASHBOARD_FILTER,
    CLEAR_DASHBOARD_FILTERS,
    DELIVERY_DETAILS_TOGGLE,
    JOB_DETAILS_TOGGLE,
    DELIVERY_LOADING_ON,
    DELIVERY_LOADING_OFF,
    JOB_LOADING_ON,
    JOB_LOADING_OFF,
    DRIVER_LOADING_ON,
    DRIVER_LOADING_OFF,
    SET_DELIVERY_WIZARD_PROPS,
    SET_ADMIN_TABLE_META,
    CLEAR_ADMIN_TABLE_META,
    REFRESH_DRIVER_LIST_ON,
    REFRESH_DRIVER_LIST_OFF,
    SET_DELIVERY_ID_FILTER,
    CLEAR_DELIVERY_ID_FILTERS,
    SET_JOB_ID_FILTER,
    CLEAR_JOB_ID_FILTERS,
    SET_DRIVER_ID_FILTER,
    CLEAR_DRIVER_ID_FILTERS,
    SET_DELIVERY_SEARCH_TERM,
    CLEAR_DELIVERY_SEARCH_TERM,
    JOB_TASK_LIST_TOGGLE,
    WHATSAPP_WINDOW_OPEN,
    WHATSAPP_WINDOW_CLOSE,
    JOB_TASK_LIST_MULTIPLE_TOGGLE,
    CLEAR_ALL_TASK_LIST_TOGGLES
} from '../_actions/types';
import { SIDEBAR_WIDTH } from '../_constants/styleConstants';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    sideBarOpen: false,
    leftSideBarOpen: true,
    rightSideBarOpen: true,
    userMenuOpen: false,
    rightSideBarWidth: SIDEBAR_WIDTH,
    leftSideBarWidth: SIDEBAR_WIDTH,
    dashboardLeftTabsValue: 0,
    dashboardRightTabsValue: 0,
    deliveryDetails: {},
    jobDetails: {},
    dashboardFilters: [],
    deliveriesLoading: [],
    jobsLoading: [],
    driversLoading: [],
    deliveryWizardProps: {
        open: false,
        loading: false,
        step: 0
    },
    adminTableMeta: {},
    deliveryIdFilters: [],
    jobIdFilters: [],
    driverIdFilters: [],
    deliverySearchTerm: null,
    jobTasksExpanded: "",
    jobTasksMultipleExpanded: [],
    whatsAppWindowOpen: false,
    whatsAppName: null,
    whatsAppNumber: null,
    locationUpdateLoading: false,
    qsrMode: false
};


export default function (state = initialState, action) {
    switch (action.type) {
        case SIDEBAR_TOGGLE:
            return {
                ...state,
                sideBarOpen: action.payload
            }
        case LEFT_SIDEBAR_TOGGLE:
            return {
                ...state,
                leftSideBarOpen: action.payload
            }
        case RIGHT_SIDEBAR_TOGGLE:
            return {
                ...state,
                rightSideBarOpen: action.payload
            }
        case USER_MENU_TOGGLE:
            return {
                ...state,
                userMenuOpen: action.payload
            }
        case LEFT_SIDEBAR_RESIZE:
            return {
                ...state,
                leftSideBarWidth: action.payload
            }
        case RIGHT_SIDEBAR_RESIZE:
            return {
                ...state,
                rightSideBarWidth: action.payload
            }
        case DASHBOARD_LEFT_TABS_VALUE:
            return {
                ...state,
                dashboardLeftTabsValue: action.payload
            }
        case DASHBOARD_RIGHT_TABS_VALUE:
            return {
                ...state,
                dashboardRightTabsValue: action.payload
            }
        case SET_DELIVERY_WIZARD_PROPS:
            return {
                ...state,
                deliveryWizardProps: {
                    open: action.payload.open,
                    loading: action.payload.loading,
                    step: action.payload.step,
                }
            }
        case DASHBOARD_FILTER:
            let newFilters = [];

            if (Array.isArray(action.payload)) {
                newFilters = action.payload;
            } else {
                newFilters.push(action.payload);
            }

            let newDashboardFilters = state.dashboardFilters;
            newFilters.forEach((newFilter) => {
                newDashboardFilters = newDashboardFilters.filter(filter => filter.field !== newFilter.field);
                newDashboardFilters = [...newDashboardFilters, { field: newFilter.field, value: newFilter.value }]
            });

            return {
                ...state,
                dashboardFilters: newDashboardFilters
            }
        case CLEAR_DASHBOARD_FILTERS:
            return {
                ...state,
                dashboardFilters: [],
                qsrMode: false
            }
        case DELIVERY_DETAILS_TOGGLE:
            return {
                ...state,
                deliveryDetailsOpen: action.payload
            }
        case JOB_DETAILS_TOGGLE:
            return {
                ...state,
                jobDetailsOpen: action.payload
            }

        case DELIVERY_LOADING_ON:

            let deliveriesLoadingOn = [...state.deliveriesLoading];

            action.payload.forEach(deliveryId => {
                let deliveriesLoadingOnIndex = deliveriesLoadingOn.indexOf(deliveryId);

                if (deliveriesLoadingOnIndex === -1) {
                    //add: toggle details loading on
                    deliveriesLoadingOn.push(deliveryId);
                }
            });

            return {
                ...state,
                deliveriesLoading: deliveriesLoadingOn
            }

        case DELIVERY_LOADING_OFF:

            let deliveriesLoadingOff = [...state.deliveriesLoading];


            action.payload.forEach(deliveryId => {

                let deliveriesLoadingOffIndex = deliveriesLoadingOff.indexOf(deliveryId);

                if (deliveriesLoadingOffIndex !== -1) {
                    //remove: toggle details loading off
                    deliveriesLoadingOff.splice(deliveriesLoadingOffIndex, 1);
                }
            });

            return {
                ...state,
                deliveriesLoading: deliveriesLoadingOff
            }

        case JOB_LOADING_ON:

            let jobsLoadingOn = [...state.jobsLoading];

            action.payload.forEach(jobId => {
                let jobsLoadingOnIndex = jobsLoadingOn.indexOf(jobId);  
                if (jobsLoadingOnIndex === -1) {
                    //add: toggle details loading on
                    jobsLoadingOn.push(jobId);
                }
            });

            return {
                ...state,
                jobsLoading: jobsLoadingOn
            }

        case JOB_LOADING_OFF:

            let jobsLoadingOff = [...state.jobsLoading];


            action.payload.forEach(jobId => {

                let jobsLoadingOffIndex = jobsLoadingOff.indexOf(jobId);

                if (jobsLoadingOffIndex !== -1) {
                    //remove: toggle details loading off
                    jobsLoadingOff.splice(jobsLoadingOffIndex, 1);
                }
            });

            return {
                ...state,
                jobsLoading: jobsLoadingOff
            }


            case DRIVER_LOADING_ON:

                let driversLoadingOn = [...state.driversLoading];
    
                action.payload.forEach(driverId => {
                    let driversLoadingOnIndex = driversLoadingOn.indexOf(driverId);
    
                    if (driversLoadingOnIndex === -1) {
                        //add: toggle details loading on
                        driversLoadingOn.push(driverId);
                    }
                });
    
                return {
                    ...state,
                    driversLoading: driversLoadingOn
                }
    
            case DRIVER_LOADING_OFF:
    
                let driversLoadingOff = [...state.driversLoading];
    
    
                action.payload.forEach(driverId => {
    
                    let driversLoadingOffIndex = driversLoadingOff.indexOf(driverId);
    
                    if (driversLoadingOffIndex !== -1) {
                        //remove: toggle details loading off
                        driversLoadingOff.splice(driversLoadingOffIndex, 1);
                    }
                });
    
                return {
                    ...state,
                    driversLoading: driversLoadingOff
                }

        case SET_ADMIN_TABLE_META:
            if (!isEmpty(action.payload.entityType))
                state.adminTableMeta[action.payload.entityType] = action.payload;  
            return {
                ...state
            }

        case CLEAR_ADMIN_TABLE_META:
            return {
                ...state,
                adminTableMeta: {}
            }

        case SET_DELIVERY_ID_FILTER:
            let newIdFilters = [];
            if (Array.isArray(action.payload)) {
                newIdFilters = action.payload;
            } else {
                newIdFilters = state.deliveryIdFilters.filter(id => id !== action.payload);
                newIdFilters = [...newIdFilters, action.payload];
            }
            return {
                ...state,
                deliveryIdFilters: newIdFilters
            }

        case CLEAR_DELIVERY_ID_FILTERS:
            return {
                ...state,
                deliveryIdFilters: []
            }

        case SET_JOB_ID_FILTER:
            let newJobIdFilters = [];
            if (Array.isArray(action.payload)) {
                newJobIdFilters = action.payload;
            } else {
                newJobIdFilters = state.jobIdFilters.filter(id => id !== action.payload);
                newJobIdFilters = [...newJobIdFilters, action.payload];
            }
            return {
                ...state,
                jobIdFilters: newJobIdFilters
            }

        case CLEAR_JOB_ID_FILTERS:
            return {
                ...state,
                jobIdFilters: []
            }

        case SET_DRIVER_ID_FILTER:
            let newDriverIdFilters = [];
            if (Array.isArray(action.payload)) {
                newDriverIdFilters = action.payload;
            } else {
                newDriverIdFilters = state.driverIdFilters.filter(id => id !== action.payload);
                newDriverIdFilters = [...newDriverIdFilters, action.payload];
            }
            return {
                ...state,
                driverIdFilters: newDriverIdFilters
            }

        case CLEAR_DRIVER_ID_FILTERS:
            return {
                ...state,
                driverIdFilters: []
            }

        case REFRESH_DRIVER_LIST_ON:
            return {
                ...state,
                refreshDriverList: true
            }

        case REFRESH_DRIVER_LIST_OFF:
            return {
                ...state,
                refreshDriverList: false
            }

        case SET_DELIVERY_SEARCH_TERM:
            return {
                ...state,
                deliverySearchTerm: action.payload
            }

        case CLEAR_DELIVERY_SEARCH_TERM:
            return {
                ...state,
                deliverySearchTerm: null
            }

        case JOB_TASK_LIST_TOGGLE:
            let existingJobTasksExpanded = state.jobTasksExpanded;
            let newJobTaskExpanded = action.payload;

            if (!isEmpty(existingJobTasksExpanded) && existingJobTasksExpanded === action.payload) {
                // if we are toggling already opened panel to closed state
                newJobTaskExpanded = "";
            }

            return {
                ...state,
                jobTasksExpanded: newJobTaskExpanded
            }
        case CLEAR_ALL_TASK_LIST_TOGGLES:
            return {
                ...state,
                jobTasksMultipleExpanded: [],
                jobTasksExpanded: ''

            }
        case JOB_TASK_LIST_MULTIPLE_TOGGLE:
                let existingJobTasksMultipleExpanded = state.jobTasksMultipleExpanded;
                let newJobTaskMultipleExpanded = action.payload;
                let taskExpandedIndex = existingJobTasksMultipleExpanded.indexOf(newJobTaskMultipleExpanded);
    
                if (taskExpandedIndex  !== -1) {
                    // if we are toggling already opened panel to closed state
                    existingJobTasksMultipleExpanded.splice(taskExpandedIndex, 1);
                }else{
                    existingJobTasksMultipleExpanded.push(newJobTaskMultipleExpanded);
                }

                
    
                return {
                    ...state,
                    jobTasksMultipleExpanded: existingJobTasksMultipleExpanded
                }

        case WHATSAPP_WINDOW_OPEN: {
            return {
                ...state,
                whatsAppWindowOpen: true,
                whatsAppName: action.payload.name,
                whatsAppNumber: action.payload.number
            }
        }

        case WHATSAPP_WINDOW_CLOSE: {
            return {
                ...state,
                whatsAppWindowOpen: false,
                whatsAppName: null,
                whatsAppNumber: null
            }
        }

        default:
            return state;
    }
}
import { GET_ACCOUNTS_SELECT, CLEAR_ACCOUNTS_SELECT_SUCCESS } from '../_actions/types';

const initialState = {
    data: {},
    success: null
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_ACCOUNTS_SELECT:
            return {
                ...state,
                data: action.payload,
                success: true
            }
        case CLEAR_ACCOUNTS_SELECT_SUCCESS:
            return {
                ...state,
                success: null
            }    
        default: 
            return state;
    }
}
import { GET_APPLICATION_INVITES, CLEAR_APPLICATION_INVITES, SET_LOADING_APPLICATION_INVITES, GET_APPLICATION_INVITES_SUCCESS, CLEAR_APPLICATION_INVITES_SUCCESS } from '../_actions/types';

const initialState = {
    data: {},
    loading: false,
    success: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_APPLICATION_INVITES:
            return {
                ...state,
                data: action.payload,
                loading: false
            }

        case GET_APPLICATION_INVITES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                success: true
            }
        case CLEAR_APPLICATION_INVITES:
            return {
                ...state,
                data: {},
                loading: false,
                success: false
            }
        case CLEAR_APPLICATION_INVITES_SUCCESS:
            return {
                ...state,
                success: false
            }
        case SET_LOADING_APPLICATION_INVITES:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
import React, { Component, Suspense, lazy } from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/common/init/ScrollToTop';

import MobileDevicesTable from './components/administration/tables/MobileDevicesTable';

// Utils
import { PrivateRoute } from './wumdrophubsreactshared/_utils/privateRoute';
import { PrivateIntercomRoute } from 'wumdrophubsreactshared/_utils/privateIntercomRoute';
import { PublicIntercomRoute } from './wumdrophubsreactshared/_utils/publicIntercomRoute';
import {
  COMPLEMENTARY_3_COLOR,
  FONT_FAMILY, SMALL_FONT, TINY_FONT, DARK_GREY_COLOR
} from './wumdrophubsreactshared/_constants/styleConstants';
import withAppInitializer from './components/common/init/withAppInitializer';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {
  REPORT_CUSTOMER_DELIVERIES,
  REPORT_GROSS_PROFITS,
  REPORT_SERVICE_LEVELS,
  REPORT_KPI_PERFORMANCE,
  REPORT_DELIVERY_SUMMARY,
  ARCHIVE_CUSTOMER_DELIVERIES,
  ARCHIVE_PARTNER_DELIVERIES,
  ARCHIVE_PARTNER_JOBS,
  REPORT_DELIVERY_TERMINATION,
  REPORT_DELIVERY_ARRIVAL,
  REPORT_DRIVER_ANALYSIS,
  REPORT_DRIVER_AUTO_ASSIGN_EFF,
  REPORT_DRIVER_ROUTES,
  DASHBOARD_AUTO_ASSIGN,
  DASHBOARD_DRIVER_SCHEDULE,
  REPORT_HUB_DELIVERIES,
  ADDRESS_VARIATION_REPORT,
  DRIVER_PAY_ANALYSIS,
  DRIVER_WAIT_TIME,
  MASSMART_EXCEPTIONS_REPORT,
  FINANCE_METRICS_REPORT,
  DELIVERY_DUE_REPORT, AMENDMENT_REPORT,
  LOCATION_PROXIMITY_REPORT
} from 'wumdrophubsreactshared/_constants/typeConstants';
import hasPermission from 'wumdrophubsreactshared/_utils/hasPermission';
import grantAllPermissions from 'wumdrophubsreactshared/_utils/grantAllPermissions';
import GA from "wumdrophubsreactshared/_utils/googleAnalytics";

import LoadingMessage from 'components/common/init/LoadingMessage';
import lazyRetry from 'wumdrophubsreactshared/_utils/lazyRetry';
import RateUploadTable from 'components/administration/tables/RateUploadTable';
import RateUploadEditForm from 'components/administration/forms/RateUpload/RateUploadEditForm';

//views lazy loaded
const Login = lazy(() => lazyRetry(() => import('./components/pages/authentication/Login')));
const EmailConfirmation = lazy(() => lazyRetry(() => import('./components/pages/authentication/EmailConfirmation')));
const PhoneNumberConfirmation = lazy(() => lazyRetry(() => import('./components/pages/authentication/PhoneNumberConfirmation')));
const TwoFactorAuthentication = lazy(() => lazyRetry(() => import('./components/pages/authentication/TwoFactorAuthentication')));
const ForgotPassword = lazy(() => lazyRetry(() => import('./components/pages/authentication/ForgotPassword')));
const Home = lazy(() => lazyRetry(() => import('./components/pages/Home')));
const PricingPage = lazy(() => lazyRetry(() => import('./components/pages/home/PricingPage')));
const BusinessPage = lazy(() => lazyRetry(() => import('./components/pages/home/BusinessPage')));
const HerosPage = lazy(() => lazyRetry(() => import('./components/pages/home/HerosPage')));
const DevelopersPage = lazy(() => lazyRetry(() => import('./components/pages/home/DevelopersPage')));
const TrackingSearchPage = lazy(() => lazyRetry(() => import('./components/pages/home/TrackingSearchPage')));
const TrackingTimelinePage = lazy(() => lazyRetry(() => import('./components/pages/home/TrackingTimelinePage')));
const AboutPage = lazy(() => lazyRetry(() => import('./components/pages/home/AboutPage')));
const ContactPage = lazy(() => lazyRetry(() => import('./components/pages/home/ContactPage')));
const ShopifyPage = lazy(() => lazyRetry(() => import('./components/pages/home/ShopifyPage')));
const WoocommercePage = lazy(() => lazyRetry(() => import('./components/pages/home/WoocommercePage')));
const RatingPage = lazy(() => lazyRetry(() => import('./components/pages/home/RatingPage')));
const RatingThankYouPage = lazy(() => lazyRetry(() => import('./components/pages/home/RatingThankYouPage')));
const RatingThankYouGoogleReview = lazy(() => lazyRetry(() => import('./components/pages/home/RatingThankYouGoogleReview')));
const FaqPage = lazy(() => lazyRetry(() => import('./components/pages/home/FaqPage')));

const TermsPage = lazy(() => lazyRetry(() => import('./components/pages/home/TermsPage')));
const PrivacyPage = lazy(() => lazyRetry(() => import('./components/pages/home/PrivacyPage')));
const PartnerApplicationPage = lazy(() => lazyRetry(() => import('./components/pages/applications/PartnerApplicationPage')));
const DriverApplicationPage = lazy(() => lazyRetry(() => import('./components/pages/applications/DriverApplicationPage')));
const BusinessApplicationPage = lazy(() => lazyRetry(() => import('./components/pages/applications/BusinessApplicationPage')));
const NotFound = lazy(() => lazyRetry(() => import('./components/pages/errors/NotFound')));
const ServiceUnavailable = lazy(() => lazyRetry(() => import('./components/pages/errors/ServiceUnavailable')));
const Forbidden = lazy(() => lazyRetry(() => import('./components/pages/errors/Forbidden')));
const AccountsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/AccountsTable')));
const AccountsCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Accounts/AccountsCreateForm')));
const AccountsEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Accounts/AccountsEditForm')));
const TerritoriesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/TerritoriesTable')));
const TerritoriesCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Territories/TerritoriesCreateForm')));
const TerritoriesEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Territories/TerritoriesEditForm')));
const UsersTable = lazy(() => lazyRetry(() => import('./components/administration/tables/UsersTable')));
const UsersCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Users/UsersCreateForm')));
const UsersEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Users/UsersEditForm')));
const CompaniesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/CompaniesTable')));
const CompaniesCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Companies/CompaniesCreateForm')));
const CompaniesEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Companies/CompaniesEditForm')));
const DeliveriesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/DeliveriesTable')));
const DeliveriesEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Deliveries/DeliveriesEditForm')));
const JobsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/JobsTable')));
const JobsEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Jobs/JobsEditForm')));
const VehiclesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/VehiclesTable')));
const VehiclesCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Vehicles/VehiclesCreateForm')));
const VehiclesEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Vehicles/VehiclesEditForm')));
const TerritoriesAreasCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/TerritoriesAreas/TerritoriesAreasCreateForm')));
const TerritoriesAreasEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/TerritoriesAreas/TerritoriesAreasEditForm')));
const TerritoriesAreasTable = lazy(() => lazyRetry(() => import('./components/administration/tables/TerritoriesAreasTable')));
const TerritoriesExceptionsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/TerritoriesExceptionsTable')));
const RatesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/RatesTable')));
const RatesCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Rates/RatesCreateForm')));
const RatesEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Rates/RatesEditForm')));
const RatingsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/RatingsTable')));
const RatingCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Ratings/RatingCreateForm')));
const RatingEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Ratings/RatingEditForm')));
const StoresTable = lazy(() => lazyRetry(() => import('./components/administration/tables/StoresTable')));
const StoresCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Stores/StoresCreateForm')));
const StoresEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Stores/StoresEditForm')));
const Dashboard = lazy(() => lazyRetry(() => import('./components/dashboard/Dashboard')));
const UserProfile = lazy(() => lazyRetry(() => import('components/pages/Profiles/ProfilePage')));
const TransactionsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/TransactionsTable')));
const TransactionItemsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/TransactionItemsTable')));
const FundsReceivedTable = lazy(() => lazyRetry(() => import('./components/administration/tables/FundsReceivedTable')));
const FundsReceivedCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/FundsReceived/FundsReceivedCreateForm')));
const FundsReceivedEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/FundsReceived/FundsReceivedEditForm')));
const FundsAllocationTable = lazy(() => lazyRetry(() => import('./components/administration/tables/FundsAllocationTable')));

const QuestionnaireCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Questionnaires/QuestionnaireCreateForm')));
const QuestionnaireEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Questionnaires/QuestionnaireEditForm')));
const QuestionnaireTable = lazy(() => lazyRetry(() => import('./components/administration/tables/QuestionnaireTable')));

const HubsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/HubsTable')));
const HubsCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Hubs/HubsCreateForm')));
const HubsEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Hubs/HubsEditForm')));

const BankAccountsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/BankAccountsTable')));
const BankAccountCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/BankAccounts/BankAccountCreateForm')));
const BankAccountEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/BankAccounts/BankAccountEditForm')));
const WithdrawalTable = lazy(() => lazyRetry(() => import('./components/administration/tables/WithdrawalTable')));
const AdvancesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/AdvancesTable')));
const PaymentCallback = lazy(() => lazyRetry(() => import('./components/delivery-wizard/step6/PaymentCallback')));
const UploadBatchTable = lazy(() => lazyRetry(() => import('./components/administration/tables/UploadBatchTable')));
const UploadDistanceBatchTable = lazy(() => lazyRetry(() => import('./components/administration/tables/UploadDistanceBatchTable')));
const DeliveryUploadTable = lazy(() => lazyRetry(() => import('./components/administration/tables/DeliveryUploadTable')));
const StoreUploadTable = lazy(() => lazyRetry(() => import('./components/administration/tables/StoreUploadTable')));
const DistanceUploadTable = lazy(() => lazyRetry(() => import('./components/administration/tables/DistanceUploadTable')));
const DeliveryUploadEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/DeliveryUpload/DeliveryUploadEditForm')));
const StoreUploadEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/StoreUpload/StoreUploadEditForm')));
const WebhookTable = lazy(() => lazyRetry(() => import('./components/administration/tables/WebhookTable')));
const WebhookCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Webhooks/WebhookCreateForm')));
const WebhookEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Webhooks/WebhookEditForm')));
const ScheduledReportTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ScheduledReportTable')));
const ScheduledReportCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/ScheduledReports/ScheduledReportCreateForm')));
const ScheduledReportEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/ScheduledReports/ScheduledReportEditForm')));
const AnnouncementsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/AnnouncementsTable')));
const AnnouncementCreateForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Announcements/AnnouncementCreateForm')));
const AnnouncementEditForm = lazy(() => lazyRetry(() => import('./components/administration/forms/Announcements/AnnouncementEditForm')));
const ReportCustomerDeliveriesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportCustomerDeliveriesTable')));
const ReportDeliveryTerminationTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportDeliveryTerminationTable')));
const ReportDeliveryArrivalTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportDeliveryArrivalTable')));
const ReportGrossProfitsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportGrossProfitsTable')));
const ReportServiceLevelsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportServiceLevelsTable')));
const ReportDriverPerformanceTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportDriverPerformanceTable')));
const ReportDeliverySummaryTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportDeliverySummaryTable')));
const ReportHubDeliveryMovementTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportHubDeliveryMovementTable')));

const ArchiveCustomerDeliveriesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ArchiveCustomerDeliveriesTable')));
const ArchivePartnerDeliveriesTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ArchivePartnerDeliveriesTable')));
const ArchivePartnerJobsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ArchivePartnerJobsTable')));
const BusinessApplicationsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/BusinessApplicationsTable')));
const BusinessApplicationsEditForm = lazy(() => lazyRetry(() => import('components/administration/forms/BusinessApplications/BusinessApplicationsEditForm')));
const PartnerApplicationsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/PartnerApplicationsTable')));
const PartnerApplicationsEditForm = lazy(() => lazyRetry(() => import('components/administration/forms/PartnerApplications/PartnerApplicationsEditForm')));
const DriverApplicationsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/DriverApplicationsTable')));
const DriverApplicationsEditForm = lazy(() => lazyRetry(() => import('components/administration/forms/DriverApplications/DriverApplicationsEditForm')));
const AssistantApplicationsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/AssistantApplicationsTable')));
const AssistantApplicationsEditForm = lazy(() => lazyRetry(() => import('components/administration/forms/AssistantApplications/AssistantApplicationsEditForm')));
const VehicleApplicationsTable = lazy(() => lazyRetry(() => import('./components/administration/tables/VehicleApplicationsTable')));
const VehicleApplicationsEditForm = lazy(() => lazyRetry(() => import('components/administration/forms/VehicleApplications/VehicleApplicationsEditForm')));
const ReportDriverAnalysisTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportDriverAnalysisTable')));
const ReportDriverAutoAssignEffTable = lazy(() => lazyRetry(() => import('./components/administration/tables/ReportDriverAutoAssignTable')));
const IndexPage = lazy(() => lazyRetry(() => import('components/pages/admin/IndexPage')));
const DriverRoutesTable = lazy(() => lazyRetry(() => import('components/administration/tables/DriverRouteTable')));
const AutoAssignReport = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/AutoAssignReport')));
const DriverSchedule = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/DriverSchedule')));
const AddressVariationReport = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/AddressVariationReport')));
const DriverPayAnalysis = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/DriverPayAnalysis')));
const DriverWaitTime = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/DriverWaitTime')));
const MassMartExceptions = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/MassMartExceptions')));
const FinanceMetrics = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/FinanceMetrics')));
const DeliveriesDue = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/DeliveriesDue')));
const AmendmentReport = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/AmendmentReport')));
const LocationProximityReport = lazy(() => lazyRetry(() => import('components/administration/supertset-dashboards/LocationProximityReport')));

//override theme props
const theme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#444444"
    },
    secondary: {
      main: "#EA3659"
    }
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        border: '1px solid #e0e0e0',
        boxShadow: 'none',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none'
      }
    },
    MuiListItem: {
      root: {
        fontSize: SMALL_FONT,
        "&$selected": {
          backgroundColor: COMPLEMENTARY_3_COLOR,
          borderColor: COMPLEMENTARY_3_COLOR,
          '&:hover': {
            backgroundColor: COMPLEMENTARY_3_COLOR
          }
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: SMALL_FONT,
      }
    },
    MuiDialogContent: {
      root: {
        fontSize: TINY_FONT,
        "&:first-child": {
          paddingTop: "0"
        },
      }
    },
    MuiDialog: {
      paper: {
        width: 350
      }
    },
    MuiOutlinedInput: {
      inputMultiline: {
        fontSize: SMALL_FONT,
      },
    },
    MuiStepper: {
      root: {
        padding: "5px 5px 15px 5px",
      },
    },
    MuiStepContent: {
      root: {
        marginTop: 6,
      }
    },
    MuiStepLabel: {
      label: {
        fontSize: 13,
      },
      active: {
        //fontWeight: "600 !important",
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: 10,
        paddingBottom: 4
      },
      lineHorizontal: {
        marginRight: 8
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: SMALL_FONT
      }
    },
    MuiExpansionPanel: {
      root: {
        '&::before': {
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }
      },
      rounded: {
        borderRadius: 4,
        margin: '10px 0'
      }
    },
    MuiFab: {
      root: {
        boxShadow: 'none',
      },
      label: {
        textTransform: 'none',
        paddingRight: 10,
        paddingLeft: 10,
      },
    },
    MuiInputBase: {
      root: {
        '& .Mui-disabled': {
          color: DARK_GREY_COLOR
        }
      }
    }
  },

});

class App extends Component {
  render() {
    const { auth, children } = this.props;
    return (
      <ThemeProvider theme={theme}>
        {children} {/* children = these are the global error components passed in via the withAppInitializer class */}
        <Router>
          <Suspense fallback={<LoadingMessage />}>
            {GA.init() && <GA.RouteTracker />}

            <ScrollToTop />
            <Switch>
              <Route path="/robots.txt" onEnter={() => window.location.reload()} />
              <Route path="/sitemap.xml" onEnter={() => window.location.reload()} />

              <PublicIntercomRoute enableWchat exact path="/" component={Home} />

              <PublicIntercomRoute exact path="/register" component={Login} />
              <PublicIntercomRoute exact path="/login/:firstLevel?/:secondLevel?" component={Login} />
              <PublicIntercomRoute exact path="/forgot-password" component={ForgotPassword} />
              <PublicIntercomRoute exact path="/two-factor" component={TwoFactorAuthentication} />
              <PublicIntercomRoute exact path="/confirm-email" component={EmailConfirmation} />
              <PublicIntercomRoute exact path="/confirm-phone" component={PhoneNumberConfirmation} />
              <PublicIntercomRoute exact path="/confirm-password" component={ForgotPassword} />
              <PublicIntercomRoute exact path="/forbidden" component={Forbidden} />

              <PublicIntercomRoute exact path="/pricing" component={PricingPage} />
              <PublicIntercomRoute exact path="/businesses" component={BusinessPage} />
              <PublicIntercomRoute exact path="/drivers" component={HerosPage} />
              <PublicIntercomRoute exact path="/developers" component={DevelopersPage} />
              <PublicIntercomRoute enableWchat exact path="/track-order/:searchTerm?" component={TrackingSearchPage} />
              <PublicIntercomRoute enableWchat exact path="/track/:hash" component={TrackingTimelinePage} />
              <PublicIntercomRoute exact path="/contact" component={ContactPage} />
              <PublicIntercomRoute exact path="/about" component={AboutPage} />
              <PublicIntercomRoute exact path="/shopify" component={ShopifyPage} />
              <PublicIntercomRoute exact path="/woocommerce" component={WoocommercePage} />
              <PublicIntercomRoute exact path="/faq" component={FaqPage} />
              
              <PublicIntercomRoute exact path="/terms" component={TermsPage} />
              <PublicIntercomRoute exact path="/rate/:hashId" component={RatingPage} />
              <PublicIntercomRoute exact path="/rating-complete" component={RatingThankYouPage} />
              <PublicIntercomRoute exact path="/google-rating-complete" component={RatingThankYouGoogleReview} />
              <PublicIntercomRoute exact path="/privacy" component={PrivacyPage} />

              <PrivateIntercomRoute exact path="/partner-apply" component={PartnerApplicationPage} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateIntercomRoute exact path="/business-apply" component={BusinessApplicationPage} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateIntercomRoute exact path="/driver-apply/:inviteHashId" component={DriverApplicationPage} hasPermission={hasPermission(auth, { isNormalUser: true })} />

              <PrivateRoute exact path="/dashboard" component={Dashboard} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateRoute exact path="/dashboard/:id" component={Dashboard} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isCompanyAdministrator:true, isCompanyOperator:true  })} />
              
              <PrivateRoute exact path="/accounts" component={AccountsTable} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateRoute exact path="/accounts/add" component={AccountsCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/accounts/edit/:id" component={AccountsEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/accounts/:accountId/transactions" component={TransactionsTable} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateRoute exact path="/accounts/:accountId/transactions/:transactionId" component={TransactionItemsTable} hasPermission={hasPermission(auth, { isNormalUser: true })} />

              <PrivateRoute exact path="/announcements" component={AnnouncementsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/announcements/add" component={AnnouncementCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/announcements/edit/:id" component={AnnouncementEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/bank-accounts" component={BankAccountsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isPartner: true })} />
              <PrivateRoute exact path="/bank-accounts/add" component={BankAccountCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/bank-accounts/edit/:id" component={BankAccountEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />

              <PrivateRoute exact path="/withdrawals" component={WithdrawalTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isCompanyAdministrator: true, isCompanyOperator: true, isStoreAdministrator: true, isStoreOperator: true, isPartner: true })} />
              <PrivateRoute exact path="/advances" component={AdvancesTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isCompanyAdministrator: true, isCompanyOperator: true, isStoreAdministrator: true, isStoreOperator: true, isPartner: true })} />

              <PrivateRoute exact path="/funds-received" component={FundsReceivedTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/funds-received/add" component={FundsReceivedCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/funds-received/edit/:id" component={FundsReceivedEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/funds-received/:id/allocation" component={FundsAllocationTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />

              <PrivateRoute exact path="/users" component={UsersTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/users/add" component={UsersCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/users/edit/:id" component={UsersEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/users/:id/accounts" component={AccountsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/users/:id/accounts/:accountId/transactions" component={TransactionsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/users/:id/accounts/:accountId/transactions/:transactionId" component={TransactionItemsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/users/:id/ratings" component={RatingsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/users/:id/ratings/add" component={RatingCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/users/:id/ratings/edit/:ratingId" component={RatingEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/territories" component={TerritoriesTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/territories/add" component={TerritoriesCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/territories/edit/:id" component={TerritoriesEditForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/territories/:id/areas" component={TerritoriesAreasTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/territories/:id/areas/:areaid" component={TerritoriesAreasEditForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/territories/edit/:id/areas/add" component={TerritoriesAreasCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />

              <PrivateRoute exact path="/territories/:id/exceptions" component={TerritoriesExceptionsTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/territories/:id/exceptions/:areaid" component={TerritoriesAreasEditForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/territories/edit/:id/exceptions/add" component={TerritoriesAreasCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />

              <PrivateRoute exact path="/companies" component={CompaniesTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/companies/add" component={CompaniesCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/companies/edit/:id" component={CompaniesEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/companies/:id/accounts" component={AccountsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/companies/:id/accounts/:accountId/transactions" component={TransactionsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/companies/:id/accounts/:accountId/transactions/:transactionId" component={TransactionItemsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/deliveries" component={DeliveriesTable} hasPermission={hasPermission(auth, { ...grantAllPermissions, isDriver: false, isPartner: false, isAssistant: false, isNormalUser: false })} />
              <PrivateRoute exact path="/deliveries/add" component={UploadBatchTable} hasPermission={hasPermission(auth, { ...grantAllPermissions, isPartner: false, isDriver: false })} />
              <PrivateRoute exact path="/deliveries/edit/:id" component={DeliveriesEditForm} hasPermission={hasPermission(auth, {
                ...grantAllPermissions, isDriver: false, isAssistant:
                  false, isPartner: false, isNormalUser: false, isCompanyNormalUser: false, isStoreNormalUser: false
              })} />

              <PrivateRoute exact path="/jobs" component={JobsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isPartner: true, isDriver: true, isAssistant: true, })} />
              <PrivateRoute exact path="/jobs/edit/:id" component={JobsEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/jobs/:id/deliveries" component={DeliveriesTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isPartner: true, isDriver: true, isAssistant: true, })} />

              <PrivateRoute exact path="/vehicles" component={VehiclesTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/vehicles/add" component={VehiclesCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/vehicles/edit/:id" component={VehiclesEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/rates/batches/add" component={UploadBatchTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/rates/:id/batches" component={UploadBatchTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />

              <PrivateRoute exact path="/rates/batches/:uploadBatchId/uploads" component={RateUploadTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/rates/batches/:uploadBatchId/uploads/edit/:rateUploadId" component={RateUploadEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />

              <PrivateRoute exact path="/rates" component={RatesTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/rates/add" component={RatesCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/rates/edit/:id" component={RatesEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/ratings" component={RatingsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyNormalUser: true, isStoreNormalUser: true }, false, true)} />
              <PrivateRoute exact path="/ratings/add" component={RatingCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/ratings/edit/:id" component={RatingEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/questionnaires" component={QuestionnaireTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/questionnaires/add" component={QuestionnaireCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/questionnaires/edit/:id" component={QuestionnaireEditForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />


              <PrivateRoute exact path="/hubs" component={HubsTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/hubs/add" component={HubsCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/hubs/edit/:id" component={HubsEditForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/hubs/:id" component={HubsTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />


              <PrivateRoute exact path={`/${REPORT_DRIVER_ROUTES}`} component={DriverRoutesTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyNormalUser: true, isStoreNormalUser: true }) && !hasPermission(auth, { isPartner: true, isDriver: true, isAssistant: true, isPublicApiUser: true })} />
              <PrivateRoute exact path={`/${REPORT_CUSTOMER_DELIVERIES}`} component={ReportCustomerDeliveriesTable} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateRoute exact path={`/${REPORT_CUSTOMER_DELIVERIES}/edit/:id`} component={DeliveriesEditForm} hasPermission={hasPermission(auth, { ...grantAllPermissions, isDriver: false, isAssistant: false, isPartner: false, isNormalUser: false, isCompanyNormalUser: false, isStoreNormalUser: false })} />
              <PrivateRoute exact path={`/${REPORT_DELIVERY_SUMMARY}`} component={ReportDeliverySummaryTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyNormalUser: true, isStoreNormalUser: true }) && !hasPermission(auth, { isPartner: true, isDriver: true, isAssistant: true, isPublicApiUser: true })} />
              <PrivateRoute exact path={`/${REPORT_KPI_PERFORMANCE}`} component={ReportDriverPerformanceTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyNormalUser: true, isStoreNormalUser: true }) && !hasPermission(auth, { isPartner: true, isDriver: true, isAssistant: true, isPublicApiUser: true })} />
              <PrivateRoute exact path={`/${REPORT_GROSS_PROFITS}`} component={ReportGrossProfitsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path={`/${REPORT_SERVICE_LEVELS}`} component={ReportServiceLevelsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path={`/${REPORT_DELIVERY_TERMINATION}`} component={ReportDeliveryTerminationTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path={`/${REPORT_DELIVERY_ARRIVAL}`} component={ReportDeliveryArrivalTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyNormalUser: true, isStoreNormalUser: true }) && !hasPermission(auth, { isPartner: true, isDriver: true, isAssistant: true, isPublicApiUser: true })} />

              {/* <PrivateRoute exact path={`/${REPORT_DRIVER_SCHEDULE}`} component={ReportDriverScheduleTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyNormalUser: true, isStoreNormalUser: true }) && !hasPermission(auth, { isPartner: true, isDriver: true, isAssistant: true, isPublicApiUser: true })} />      */}
              <PrivateRoute exact path={`/${DASHBOARD_AUTO_ASSIGN}`} component={AutoAssignReport} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path={`/${REPORT_DRIVER_AUTO_ASSIGN_EFF}`} component={ReportDriverAutoAssignEffTable} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateRoute exact path={`/${REPORT_DRIVER_ANALYSIS}`} component={ReportDriverAnalysisTable} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateRoute exact path={`/${REPORT_DELIVERY_ARRIVAL}`} component={ReportDeliveryArrivalTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyNormalUser: true, isStoreNormalUser: true }) && !hasPermission(auth, { isPartner: true, isDriver: true, isAssistant: true, isPublicApiUser: true })} />
              <PrivateRoute exact path={`/${REPORT_HUB_DELIVERIES}`} component={ReportHubDeliveryMovementTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path={`/${ADDRESS_VARIATION_REPORT}`} component={AddressVariationReport} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path={`/${DRIVER_WAIT_TIME}`} component={DriverWaitTime} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path={`/${MASSMART_EXCEPTIONS_REPORT}`} component={MassMartExceptions} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isCompanyAdministrator: true, isCompanyOperator: true })} />
              <PrivateRoute exact path={`/${FINANCE_METRICS_REPORT}`} component={FinanceMetrics} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path={`/${DELIVERY_DUE_REPORT}`} component={DeliveriesDue} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path={`/${DRIVER_PAY_ANALYSIS}`} component={DriverPayAnalysis} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path={`/${DASHBOARD_DRIVER_SCHEDULE}`} component={DriverSchedule} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path={`/${AMENDMENT_REPORT}`} component={AmendmentReport} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path={`/${LOCATION_PROXIMITY_REPORT}`} component={LocationProximityReport} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />

              <PrivateRoute exact path={`/${ARCHIVE_CUSTOMER_DELIVERIES}`} component={ArchiveCustomerDeliveriesTable} hasPermission={hasPermission(auth, { isNormalUser: true })} />
              <PrivateRoute exact path={`/${ARCHIVE_CUSTOMER_DELIVERIES}/edit/:id`} component={DeliveriesEditForm} hasPermission={hasPermission(auth, { ...grantAllPermissions, isDriver: false, isAssistant: false, isPartner: false, isNormalUser: false, isCompanyNormalUser: false, isStoreNormalUser: false })} />
              <PrivateRoute exact path={`/${ARCHIVE_PARTNER_DELIVERIES}`} component={ArchivePartnerDeliveriesTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isPartner: true })} />
              <PrivateRoute exact path={`/${ARCHIVE_PARTNER_DELIVERIES}/edit/:id`} component={DeliveriesEditForm} hasPermission={hasPermission(auth, { ...grantAllPermissions, isDriver: false, isAssistant: false, isPartner: false, isNormalUser: false, isCompanyNormalUser: false, isStoreNormalUser: false })} />
              <PrivateRoute exact path={`/${ARCHIVE_PARTNER_JOBS}`} component={ArchivePartnerJobsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isPartner: true })} />

              <PrivateRoute exact path="/stores" component={StoresTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/add" component={StoresCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/stores/batches/add" component={UploadBatchTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/batches/:uploadBatchId/uploads" component={StoreUploadTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />

              <PrivateRoute exact path="/stores/batches/:uploadBatchId/uploads/edit/:storeUploadId" component={StoreUploadEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/edit/:id" component={StoresEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/:id/accounts" component={AccountsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/:id/accounts/:accountId/transactions" component={TransactionsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/:id/accounts/:accountId/transactions/:transactionId" component={TransactionItemsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/:id/batches" component={UploadBatchTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/:id/batches/:uploadBatchId/uploads" component={DeliveryUploadTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
              <PrivateRoute exact path="/stores/:id/batches/:uploadBatchId/uploads/edit/:deliveryUploadId" component={DeliveryUploadEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true, isCompanyAdministrator: true, isStoreAdministrator: true, isStoreNormalUser: true })} />
            
              <PrivateRoute exact path="/rates/batches/:uploadBatchId/uploads/edit/:rateUploadId" component={RateUploadEditForm} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/rates/batches/:uploadBatchId/uploads" component={RateUploadTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/rates/batches/add" component={UploadBatchTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />

              <PrivateIntercomRoute exact path="/profile" component={UserProfile} hasPermission={hasPermission(auth, { isNormalUser: true })} />

              <PrivateRoute exact path="/paymentcallback" component={PaymentCallback} hasPermission={hasPermission(auth, { isNormalUser: true })} />

              <PrivateRoute exact path="/mobile-devices" component={MobileDevicesTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

          

              <PrivateRoute exact path="/admin/distancediscovery" component={UploadDistanceBatchTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/admin/distancediscovery/batches/:uploadBatchId/uploads" component={DistanceUploadTable} hasPermission={hasPermission(auth, { isAdministrator: true })} />
              <PrivateRoute exact path="/admin/indexmanagement" component={IndexPage} hasPermission={hasPermission(auth, { isAdministrator: true })} />

              <PrivateRoute exact path="/webhooks" component={WebhookTable} hasPermission={hasPermission(auth, { isAdministrator: true, isPublicApiUser: true })} />
              <PrivateRoute exact path="/webhooks/add" component={WebhookCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isPublicApiUser: true })} />
              <PrivateRoute exact path="/webhooks/edit/:id" component={WebhookEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isPublicApiUser: true })} />

              <PrivateRoute exact path="/scheduledreports" component={ScheduledReportTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/scheduledreports/add" component={ScheduledReportCreateForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />
              <PrivateRoute exact path="/scheduledreports/edit/:id" component={ScheduledReportEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true })} />

              <PrivateRoute exact path="/business-applications" component={BusinessApplicationsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/business-applications/edit/:id" component={BusinessApplicationsEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />


              <PrivateRoute exact path="/partner-applications" component={PartnerApplicationsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/partner-applications/:id/driver-applications" component={DriverApplicationsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/partner-applications/:id/vehicle-applications" component={VehicleApplicationsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/partner-applications/edit/:id" component={PartnerApplicationsEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/driver-applications" component={DriverApplicationsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/driver-applications/edit/:id" component={DriverApplicationsEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/assistant-applications" component={AssistantApplicationsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/assistant-applications/edit/:id" component={AssistantApplicationsEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <PrivateRoute exact path="/vehicle-applications" component={VehicleApplicationsTable} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />
              <PrivateRoute exact path="/vehicle-applications/edit/:id" component={VehicleApplicationsEditForm} hasPermission={hasPermission(auth, { isAdministrator: true, isManagementOperator: true, isStaffOperator: true })} />

              <Route exact path="/503" component={ServiceUnavailable} />
              <Route component={NotFound} />

              <Route exact path="/privacy" component={ServiceUnavailable} />
            </Switch>
          </Suspense>
        </Router>

      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});


export default compose(
  withAppInitializer,
  connect(mapStateToProps, {}),
)(App);
import { GET_ASSISTANT_APPLICATIONS, CLEAR_ASSISTANT_APPLICATIONS, CLEAR_ASSISTANT_APPLICATIONS_SUCCESS, DELETE_ASSISTANT_APPLICATIONS } from '../_actions/types';
import { DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_ASSISTANT_APPLICATIONS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case CLEAR_ASSISTANT_APPLICATIONS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_ASSISTANT_APPLICATIONS: 
            return {
                ...state,
                data: {},
                success: ''
            }
        case DELETE_ASSISTANT_APPLICATIONS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} assistants.`
            }
        default: 
            return state;
    }
}
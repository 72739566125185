import { GET_REPORT_GROSS_PROFITS, CLEAR_REPORT_GROSS_PROFITS } from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_REPORT_GROSS_PROFITS:
            return {
                ...state,
                data: action.payload
            }
            case CLEAR_REPORT_GROSS_PROFITS:
                return {
                    ...state,
                    data: {}
                }
        default:
            return state;
    }
}
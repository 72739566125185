//endpoints
export const BEACON_DASHBOARD = `/dashboard`;
export const BEACON_TRACKING = `/tracking`;

//update ui interval
export const UPDATE_INTERVAL_MILLIS = 3000;

//retry connection constants (for when signal R drops)
export const MAX_RETRYS = 1000;
export const RETRY_CONNECTION_INTERVAL_MILLIS = 10000;

import { GET_BANK_ACCOUNT_TYPES, SET_LOADING_BANK_ACCOUNT_TYPES } from '../_actions/types';

const initialState = {
    data: {},
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_BANK_ACCOUNT_TYPES:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case SET_LOADING_BANK_ACCOUNT_TYPES:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
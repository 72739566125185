import {
    MEDIUM_FONT,
    DARK_GREY_COLOR,
    LIGHT_GREY_COLOR,
    BLACK_COLOR,
    TINY_FONT
} from 'wumdrophubsreactshared/_constants/styleConstants';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
    gridRoot: {
        flexGrow: 1,
        marginTop: theme.spacing(1 / 2),
        fontSize: TINY_FONT
    },
    dialogContent: {
        padding: "0 20px 0px",
        marginBottom: 62,
        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
            padding: "0 16px 0px",
            overflowY: 'scroll',
        }
    },
    progress: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    cardTitle: {
        color: DARK_GREY_COLOR,
        fontSize: MEDIUM_FONT
    },
    cardHeader: {
        backgroundColor: lighten(LIGHT_GREY_COLOR, 0.25),
    },
    dialogPaper: {
        width: '100%',
        maxWidth: 780,
        minHeight: 540,
        [theme.breakpoints.up('md')]: {
            maxHeight: 600
        },
        overflowX: "hidden"
    },
    closeButton: {
        padding: 10,
        position: "absolute",
        right: 0,
        top: 0,
        [theme.breakpoints.up('md')]: {
            padding: 8
        }
    },
    closeIcon: {
        width: "0.9em",
        height: "0.9em",
        color: lighten(BLACK_COLOR, 0.45)
    }
});

import { GET_SCHEDULED_REPORT_TYPES } from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_SCHEDULED_REPORT_TYPES:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}
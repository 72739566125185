import { CLEAR_JOB_COST, GET_JOB_COST } from '../_actions/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_JOB_COST:
            return {
                ...state,
                data: action.payload
            }
        case CLEAR_JOB_COST:
            return {
                ...state,
                data: {}
            }
        default:
            return state;
    }
}
import {
    PRIMARY_COLOR,
    WHITE_COLOR,
    TERTIARY_COLOR,
    LIGHT_GREY_COLOR, COMPLEMENTARY_2_COLOR, SLATE_COLOR, CALL_TO_ACTION_GREEN_COLOR
} from "../../wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from "@material-ui/core/styles";

export default theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    button: {
        marginRight:  theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop:  theme.spacing(1),
        marginBottom:  theme.spacing(1),
    },
    stepButton: {
        //backgroundColor: PRIMARY_COLOR
    },
    stepIcon: {
        color: `${PRIMARY_COLOR} !important`
    },
    stepIconActive: {
        color: `${TERTIARY_COLOR} !important`
    },
    stepper: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 0,
        paddingRight: 15,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
       }
    },
    stepHorizontal: {
        paddingLeft: 0
    },
    mobileStepper: {
        bottom: 0,
        left: 0,
        position: "absolute",
        width: "92.5%",
        padding: 15,
        background: "white",
        color: SLATE_COLOR,
        borderTop: "1px solid",
        borderColor: LIGHT_GREY_COLOR,
        [theme.breakpoints.up('sm')]: {
			 width: "96.5%",
		}
    },
    progress: {
        width: "100%",
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomButton: {
        color: PRIMARY_COLOR,
        backgroundColor: WHITE_COLOR,
        textTransform: "capitalize",
        marginRight: 12,
        fontSize: 16,
        '&:hover': {
            backgroundColor: lighten(LIGHT_GREY_COLOR, 0.45),
        },
    },
    bottomButtonGreen: {
        color: WHITE_COLOR,
        backgroundColor: CALL_TO_ACTION_GREEN_COLOR,
        textTransform: "capitalize",
        fontSize: 16,
        paddingLeft: 16,
        '&:hover': {
            background: lighten(CALL_TO_ACTION_GREEN_COLOR, 0.2)
        }
    },
    barColor: {
        backgroundColor: PRIMARY_COLOR,
    },
    barColorBackground: {
        backgroundColor: COMPLEMENTARY_2_COLOR,
        [theme.breakpoints.down('xs')]: {
            marginRight: 8,
        }
    },
    summaryContent:
    {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 5,
        paddingRight: 25,
    },
    centerAlign:
    {
        width: 50,
        height: 'auto',
        margin: '20% auto',
        padding: 10
    },
    buttonSpacer: {
        width: 82,
        height: 36
    },
  });
import { GET_OPERATION_STATUSES } from '../_actions/types';

const initialState = {
    data: {}
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_OPERATION_STATUSES:
            return {
                ...state,
                data: action.payload
            }
        default: 
            return state;
    }
}
import isEmpty from "./isEmpty";

const localiseCurrency = (value, portalCurrency = 'ZAR', portalCountryCode = 'ZA') => {
    let finalValue = isEmpty(value) ? 0 : value;
    let currencyValue = finalValue.toLocaleString(`en-${portalCountryCode}`, {
        style: 'currency',
        currency: portalCurrency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return currencyValue;
}

export default localiseCurrency;
import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import hasPermission from 'wumdrophubsreactshared/_utils/hasPermission';
import { DARK_GREEN_COLOR, SMALL_FONT, WHITE_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';

const useStyles = makeStyles({
  goButton: {
    backgroundColor: DARK_GREEN_COLOR + "!important",
    textTransform: 'none',
    '&:hover': {
      background: lighten(DARK_GREEN_COLOR, 0.2) + "!important"
    },
    color: WHITE_COLOR,
    fontSize: SMALL_FONT,
    maxWidth: 200,
    paddingRight: 30,
    paddingLeft: 30,
    marginRight: 12,
    whiteSpace: 'nowrap',
  },
  buttonIcon: {
    marginRight: 30,
    color: "white",
  },
});


const NewDeliveryButton = ({ fullWidth, onOpenDeliveryWizard, auth }) => {

  const classes = useStyles();
  let canBook = hasPermission(auth, { isNormalUser: true });
  return (
    <Button fullWidth={fullWidth} disabled={canBook !== true} variant="contained" className={classes.goButton} onClick={onOpenDeliveryWizard}>
      New delivery
    </Button>
  );
}

NewDeliveryButton.propTypes = {
  auth: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  onOpenDeliveryWizard: PropTypes.func.isRequired
};

export default NewDeliveryButton;
import { GET_JOB_DATE_TYPES } from '../_actions/types';

const initialState = {
    data: {}
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_JOB_DATE_TYPES:
            return {
                ...state,
                data: action.payload
            }
        default: 
            return state;
    }
}
import jwt_decode from 'jwt-decode';
import isEmpty from './isEmpty';

const isJwtTokenExpired = (jwtToken) => {
  if (isEmpty(jwtToken)) return true;
  const decodedToken = jwt_decode(jwtToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
};

export default isJwtTokenExpired;
import React from 'react';
import { Route } from 'react-router-dom';
import WebChat from './webChat'; 
 
export const PublicIntercomRoute = ({ component: Component, enableWchat = false, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      <>
        <WebChat />
        <Component {...props} />
      </>
    )} />
  );
};
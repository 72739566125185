import {
    GET_RATES,
    POST_RATES,
    PUT_RATES,
    DELETE_RATES,
    CLEAR_RATES,
    CLEAR_RATES_SUCCESS,
    SET_LOADING_RATES
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';


const initialState = {
    data: {},
    success: "",
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_RATES:
            return {
                ...state,
                data: action.payload,
                search: action.search,
                loading: false
            }
        case POST_RATES:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} rate`
            }
        case PUT_RATES:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} rate`
            }
        case DELETE_RATES:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} rates.`
            }
        case CLEAR_RATES_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_RATES:
            return {
                ...state,
                data: {},
                success: '',
                loading: false
            }
        case SET_LOADING_RATES:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
import { SECONDARY_COLOR, DARK_GREEN_COLOR, ERROR_COLOR, FONT_FAMILY } from "../../../wumdrophubsreactshared/_constants/styleConstants";
export default theme => ({
    customCard: {
        marginTop: "30px !important",
        marginBottom: "30px !important",
        width: 250,
        margin: "0 auto",
        padding: 20,
        maxHeight: "calc(100% - 60px)",
        overflowY: "auto"
    },
    completeCustomDialog: {
        color: DARK_GREEN_COLOR
    },
    closeCustomDialog: {
        color: SECONDARY_COLOR
    },
    feedback: {
        color: ERROR_COLOR + " !important",
        fontFamily: FONT_FAMILY + " !important",
        fontSize: "10px",
    },
    input: {
        marginTop: 15,
    },
    sizesRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
        marginTop: 15
    },
    checkbox: {
        marginTop: 15,
        marginLeft: 3
    }
})
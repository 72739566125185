import {
    GET_WEB_CONFIG,
    GET_MOBILE_CONFIG,
    SET_LOADING_CONFIG
} from '../_actions/types';


const initialState = {
    data: {},
    loading: false,
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_WEB_CONFIG:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case GET_MOBILE_CONFIG:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case SET_LOADING_CONFIG:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
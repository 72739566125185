import isEmpty from '../_utils/isEmpty';
const mapQuoteToParcels = (quoteResponse) => {
    let parcelStepValues = {
        deliveryId: null,
        parcels: []
    }
    if (!isEmpty(quoteResponse)) {
        parcelStepValues.deliveryId = quoteResponse.deliveryId;
        parcelStepValues.parcels = quoteResponse.deliveryItems;
    }
    return parcelStepValues;
}
export default mapQuoteToParcels;
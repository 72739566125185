import { lighten } from "@material-ui/core";
import {
    BLACK_COLOR, FONT_FAMILY,
    SMALL_FONT,
    WHITE_COLOR,
    LIGHTGREY_COLOR, TINY_FONT, SECONDARY_COLOR, COMPLEMENTARY_5_COLOR, PRIMARY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";
import { TERTIARY_COLOR } from "../../../wumdrophubsreactshared/_constants/styleConstants";

export default theme => ({
    gridItem: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important"
    },
    defaultContainer: {
        padding: 8,
        marginLeft: -5
    },
    componentContainer: {
        border: "1px solid",
        borderColor: LIGHTGREY_COLOR,
        borderRadius: 4,
        padding: 5,
        textAlign: "left",
        marginBottom: 5,
        [theme.breakpoints.down('xs')]: {
            padding: 21,
            marginBottom: 20,
        }
    },
    roundTripText: {
        fontFamily: FONT_FAMILY,
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'left',
        color: PRIMARY_COLOR,
        marginTop: 0,
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-16px',
        }
    },
    componentDetailsContainer: {
        padding: 0
    },
    detailsContainerBackground: {
        border: 'none',
        backgroundColor: '#f5f5f5'
    },
    detailsContainer: {
        // background: 'inherit',
        marginBottom: 0,
        marginTop: 0,
        padding: "8px 16px",
        lineHeight: "25px",
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    textRow: {
        width: "100%",
        fontFamily: FONT_FAMILY,
    },
    textKeyQty: {
        color: TERTIARY_COLOR,
        fontSize: SMALL_FONT,
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-15px',
        }
    },
    textKey: {
        fontWeight: 400,
        fontSize: TINY_FONT,
        paddingLeft: 6,
    },
    textTotal: {
        color: BLACK_COLOR,
        fontWeight: 100,
        fontSize: SMALL_FONT,
    },
    textValue: {
        color: BLACK_COLOR,
        fontSize: "14px !important",
        float: "right"
    },
    textTotalRoundTrip: {
        color: COMPLEMENTARY_5_COLOR,
        fontWeight: 100,
        fontSize: SMALL_FONT,
    },
    textValueRoundTrip: {
        color: SECONDARY_COLOR,
        fontSize: "14px !important",
        float: "right"
    },
    textBold: {
        fontWeight: "bold",
        fontFamily: FONT_FAMILY,
    },
    textAmount: {
        [theme.breakpoints.down('xs')]: {
            marginRight: '-15px'
        }
    },
    textDivider: {
        margin: "14px 0"
    },
    tileIcon: {
        height: 20,
        marginRight: "10px",
        float: "left",
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-15px'
        }
    },
    table: {
        marginTop: 0,
        marginBottom: 10
    },
    numberBadge: {
        height: 20,
        borderRadius: 5,
        padding: "0 5px 0 5px",
        float: "right",
        fontSize: "0.9em",
        color: WHITE_COLOR,
        background: TERTIARY_COLOR
    },
    summaryTitle: {
        fontWeight: "bold",
        padding: "12px 0 0 14px",
        [theme.breakpoints.down('xs')]: {
            padding: "8px 16px 16px 0"
        }
    },
    driverTip: {
        // border: "1.5px solid",
        // borderColor: PRIMARY_COLOR,
        borderRadius: 4,
        marginTop: 15,
        padding: "8px 15px 8px 9px",
        display: 'flex',
        background:lighten(PRIMARY_COLOR, 0.95),
    }
});
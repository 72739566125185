import { POST_FUNDS_RECEIVED, PUT_FUNDS_RECEIVED, GET_FUNDS_RECEIVED, CLEAR_FUNDS_RECEIVED, CLEAR_FUNDS_RECEIVED_SUCCESS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_FUNDS_RECEIVED:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case CLEAR_FUNDS_RECEIVED:
            return {
                ...state,
                data: {},
                success: ""
            }
        case CLEAR_FUNDS_RECEIVED_SUCCESS:
            return {
                ...state,
                success: ""
            }
        case POST_FUNDS_RECEIVED:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} funds received`
            }
        case PUT_FUNDS_RECEIVED:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} funds received`
            }
        default:
            return state;
    }
}
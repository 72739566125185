import { GET_RATE_UPLOADS, CANCEL_RATE_UPLOAD, PUT_RATE_UPLOADS, CLEAR_RATE_UPLOADS, CLEAR_RATE_UPLOADS_SUCCESS } from '../_actions/types';

const initialState = {
    data: {},
    success: null,
}


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_RATE_UPLOADS:
            return {
                ...state,
                data: action.payload
            }
        case PUT_RATE_UPLOADS:
            return {
                ...state,
                data: action.payload,
                success: "Rate upload updated successfully"
            }
        case CANCEL_RATE_UPLOAD:
            return {
                ...state,
                //data: {},
                success: "Rate upload cancelled successfully"
            }
        case CLEAR_RATE_UPLOADS:
            return {
                ...state,
                data: {},
                success: null
            }
        case CLEAR_RATE_UPLOADS_SUCCESS:
            return {
                ...state,
                success: null
            }
        default:
            return state;
    }
}
import isEmpty from "./isEmpty";
import hasPermission from "./hasPermission";
import grantAllPermissions from "./grantAllPermissions";

const hasFinancePermission = (auth) => {
    return isEmpty(auth) ? false
        :
        hasPermission(auth, {
            ...grantAllPermissions,
            isCompanyNormalUser: false,
            isStoreNormalUser: false
        }, true);
}
export default hasFinancePermission;
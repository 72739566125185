import URI from 'urijs';
import { saveAs as getSaveAs } from 'file-saver';


export function GetEnvironment() {
    let uri = new URI(window.location.href);
    let hostname = uri.hostname().toLowerCase();

    if (hostname === "localhost" || hostname === "127.0.0.1") {
        return "local";
    } else if (hostname.startsWith("local")) {
        return "local";
    } else if (hostname.startsWith("staging")) {
        return "staging";
    } else if (hostname.startsWith("integrations")) {
        return "integrations";
    } else {
        return "production";
    }
} 

export async function GetEnvironmentAsync() {
   return GetEnvironment();
}

export function IsDevelopment() {
    return process.env.NODE_ENV === "development";
}

export function saveAs() {
    return getSaveAs;
}
import jwt_decode from 'jwt-decode';
import isEmpty from './isEmpty';

const isJwtTokenAboutToExpire = (jwtToken) => {
  if (isEmpty(jwtToken)) return true;
  const decodedToken = jwt_decode(jwtToken);
  const currentTime = Date.now() / 1000;
  // Check if the token will expire within the next 5 minutes (300 seconds)
  const isAboutToExpire = decodedToken.exp < (currentTime + 300);
  return isAboutToExpire;
};

export default isJwtTokenAboutToExpire;


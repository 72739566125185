import { feedback } from "assets/jss/material-kit-pro-react.jsx";
import {lighten} from "@material-ui/core/styles";
import {
    BLACK_COLOR,
    COMPLEMENTARY_5_COLOR, LIGHT_GREY_COLOR, SMALL_FONT,
    TINY_FONT,
    WHITE_COLOR,
    CALL_TO_ACTION_GREEN_COLOR
} from "../../../wumdrophubsreactshared/_constants/styleConstants";
export default theme => ({
    root: {
        marginBottom: 15
    },
    feedback: {
        ...feedback
    },
    gridItem: {
        paddingTop: "0 !important",
    },
    expansionPanel: {
        display: "none",
        boxShadow: "none !important"
    },
    expansionPanelSummary: {
        padding: "0 !important"
    },
    expansionPanelDetails: {
        padding: "0 !important"
    },
    group: {
        flexDirection: "row"
    },
    formControl: {
        margin: "0 0 15px 0",
        position: "relative",
        padding: 0
    },
    addressName: {
        width: "calc(100% - 110px)"
    },
    addressUnit: {
        marginLeft: "10px !important",
        width: "100px"
    },
    gridContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    gridMarginLeft: {
        marginLeft: 25,
        position: "relative",
        [theme.breakpoints.up('md')]: {
           marginLeft: 0,
           position: "", 
        }
    },
    navCircle: {
        color: lighten(BLACK_COLOR, 0.7),
        fontSize: TINY_FONT,
        marginLeft: "-25px",
        marginTop: "9px",
        position: "absolute",
        [theme.breakpoints.up('md')]: {
            margin: "5px 5px 0 -3px",
            position: "relative"
        }
    },
    navSquare: {
        color: lighten(BLACK_COLOR, 0.7),
        fontSize: TINY_FONT,
        marginLeft: "-25px",
        marginTop: "9px",
        position: "absolute",
        [theme.breakpoints.up('md')]: {
            margin: "5px 5px 0 -3px",
            position: "relative"
        }
    },
    detailsInfo: {
        paddingBottom: 25,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: CALL_TO_ACTION_GREEN_COLOR,
            color: WHITE_COLOR,
            textTransform: "none",
            boxShadow: "none",
            fontSize: SMALL_FONT,
            padding: "7px 28px",
            '&:hover': {
                boxShadow: "none",
                backgroundColor: CALL_TO_ACTION_GREEN_COLOR,
                color: WHITE_COLOR,
            },
            '&:active': {
                boxShadow: "none",
            }
    },
    buttonNext: {
        backgroundColor: COMPLEMENTARY_5_COLOR,
        color: WHITE_COLOR,
        textTransform: "capitalize",
        boxShadow: "none",
        fontSize: SMALL_FONT,
        padding: "7px 28px",
        '&:hover': {
            boxShadow: "none",
            backgroundColor: COMPLEMENTARY_5_COLOR,
            color: WHITE_COLOR,
        },
        '&:active': {
            boxShadow: "none",
        }
    },
    buttonTextOnly: {
        color: COMPLEMENTARY_5_COLOR,
        backgroundColor: WHITE_COLOR,
        textTransform: "capitalize",
        marginRight: 10,
        boxShadow: "none",
        fontSize: SMALL_FONT,
        padding: "7px 28px",
        '&:hover': {
            boxShadow: "none",
            color: COMPLEMENTARY_5_COLOR,
            backgroundColor: lighten(LIGHT_GREY_COLOR, 0.45),
        },
        '&:active': {
            boxShadow: "none",
        }
    },
    progress: {
        width: "100%",
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
});
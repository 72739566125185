import { WHITE_COLOR, TERTIARY_COLOR, FONT_FAMILY } from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from "@material-ui/core/styles/colorManipulator";

export default theme => ({
    buttonContainer: {
        position: "absolute",
        zIndex: 1500,
        //marginLeft: -130,
        marginTop: 10,
        left: '43%'
    },
    button: {
        margin: 0,
        textTransform: "none",
        fontSize: 12,
        top: 10,
        fontFamily: FONT_FAMILY,
        backgroundColor: TERTIARY_COLOR,
        '&:hover': {
            background: lighten(TERTIARY_COLOR, 0.2)
        },
        padding: "2px 10px",
    },
    progress: {
        width: "18px !important",
        height: "18px !important",
        float: "right",
        marginLeft:  theme.spacing(1),
        color: WHITE_COLOR
    }
});
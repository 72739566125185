import { GET_ACCOUNTS, POST_ACCOUNTS, UPDATE_LOCKED_AMOUNT, PUT_ACCOUNTS, CLEAR_ACCOUNTS, DELETE_ACCOUNTS, CLEAR_ACCOUNTS_SUCCESS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';


const initialState = {
    data: {},
    success: ""
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_ACCOUNTS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_ACCOUNTS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} account`
            }
        case PUT_ACCOUNTS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} account`
            }
        case UPDATE_LOCKED_AMOUNT:
            return {
                ...state,
                success: `${UPDATE_LOCKED_AMOUNT} account`
            }
        case DELETE_ACCOUNTS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} accounts.`
            }
        case CLEAR_ACCOUNTS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_ACCOUNTS: 
            return {
                ...state,
                data: {},
                success: ''
            }
        default: 
            return state;
    }
}
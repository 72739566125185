import { GET_STORE_UPLOADS, CANCEL_STORE_UPLOAD, PUT_STORE_UPLOADS, CLEAR_STORE_UPLOADS, CLEAR_STORE_UPLOADS_SUCCESS } from '../_actions/types';

const initialState = {
    data: {},
    success: null,
}


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STORE_UPLOADS:
            return {
                ...state,
                data: action.payload
            }
        case PUT_STORE_UPLOADS:
            return {
                ...state,
                data: action.payload,
                success: "Store upload updated successfully"
            }
        case CANCEL_STORE_UPLOAD:
            return {
                ...state,
                //data: {},
                success: "Store upload cancelled successfully"
            }
        case CLEAR_STORE_UPLOADS:
            return {
                ...state,
                data: {},
                success: null
            }
        case CLEAR_STORE_UPLOADS_SUCCESS:
            return {
                ...state,
                success: null
            }
        default:
            return state;
    }
}
import {
    DARK_GREEN_COLOR,
    SLATE_COLOR, SMALL_FONT, WHITE_COLOR
} from 'wumdrophubsreactshared/_constants/styleConstants';

export default theme => ({
    root: {
        padding: 0,
    },
    thankyouHeader: {
        fontSize: 30,
        lineHeight: "36px",
        marginBottom: 24,
        marginTop: 22,
        color: SLATE_COLOR,
        textAlign: "center"
    },
    confirmationCopy: {
        textAlign: "center",
        marginBottom: 26,
    },
    confirmationImg: {
        paddingTop: 45,
        textAlign: "center",
        margin: "0 auto",
        display: "block"
    },
    componentContainer: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 25,
        }
    },
        reattemptButton: {
        textAlign: "center",
        backgroundColor: DARK_GREEN_COLOR,
        color: WHITE_COLOR,    
        padding: "8px 16px", 
        fontSize: SMALL_FONT,
        marginTop: 12, 
            marginBottom: 80,
        textTransform: "none",
            '&:hover': {
                backgroundColor: DARK_GREEN_COLOR
            }
    },
    centerAlign: {
        textAlign: "center"
    },
    boxContainer: {
        
    }
});
import { POST_ANNOUNCEMENTS, PUT_ANNOUNCEMENTS, GET_ANNOUNCEMENTS, DELETE_ANNOUNCEMENTS, CLEAR_ANNOUNCEMENTS, CLEAR_ANNOUNCEMENTS_SUCCESS } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ANNOUNCEMENTS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case POST_ANNOUNCEMENTS:
            return {
                ...state,
                success: `${POST_SUCCESS} announcement`
            }
        case PUT_ANNOUNCEMENTS:
            return {
                ...state,
                success: `${PUT_SUCCESS} announcement`
            }
        case DELETE_ANNOUNCEMENTS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} announcement(s).`
            }
        case CLEAR_ANNOUNCEMENTS:
            return {
                ...state,
                data: {},
                success: ""
            }
        case CLEAR_ANNOUNCEMENTS_SUCCESS:
            return {
                ...state,
                success: ""
            }
        default:
            return state;
    }
}
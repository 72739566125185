/* eslint-disable no-unused-vars */
import { 
    CLEAR_DELIVERIES_SUCCESS, 
    CLEAR_DELIVERIES,
    GET_DELIVERIES, 
    DELETE_DELIVERIES, 
    POST_DELIVERIES, 
    PUT_DELIVERIES, 
    UPDATE_DELIVERIES, 
    GET_DELIVERIES_BY_ID,
    NEW_DELIVERIES,
    GET_DELIVERIES_AGGREGATIONS,
    GET_SINGLE_DELIVERY_AND_UPSERT, 
    SET_LOADING_DELIVERIES,
    UPDATE_BULK_INDEXER_TERMINAL,
    CLEAR_BULK_INDEXER_TERMINAL
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    data: {}
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case UPDATE_BULK_INDEXER_TERMINAL:
            let values = Array.from(action.payload); 
            return {
                ...state,
                data: values
            }
        case CLEAR_BULK_INDEXER_TERMINAL:
            return {
                ...state,
                data: {}
            }
        default: 
            return state;
    }
}
import {
    CLEAR_BUSINESS_APPLICATIONS_WORKFLOW,
    GET_BUSINESS_APPLICATIONS_WORKFLOW,
    GET_BUSINESS_APPLICATIONS_WORKFLOW_NEXT,
    SET_LOADING_BUSINESS_APPLICATIONS,
    SET_ACTIVE_STEP_BUSINESS_APPLICATIONS,
    CLEAR_BUSINESS_APPLICATIONS_WORKFLOW_SUCCESS
} from '../_actions/types';


const initialState = {
    data: {},
    loading: false,
    activeStep: -1,
    success: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_BUSINESS_APPLICATIONS_WORKFLOW:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case GET_BUSINESS_APPLICATIONS_WORKFLOW_NEXT:
            return {
                ...state,
                data: action.payload,
                loading: false,
                activeStep: state.activeStep + 1,
                success: true
            }
        case CLEAR_BUSINESS_APPLICATIONS_WORKFLOW:
            return {
                ...state,
                data: {},
                loading: false,
                activeStep: -1
            }

        case SET_LOADING_BUSINESS_APPLICATIONS:
            return {
                ...state,
                loading: action.payload
            }
        case SET_ACTIVE_STEP_BUSINESS_APPLICATIONS:
            return {
                ...state,
                activeStep: action.payload
            }
        case CLEAR_BUSINESS_APPLICATIONS_WORKFLOW_SUCCESS:
            return {
                ...state,
                success: false
            }
        default:
            return state;
    }
}
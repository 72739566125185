import { JWT_TOKEN, REFRESH_TOKEN } from "./wumdrophubsreactshared/_constants/localStorageConstants";

export const getJwtToken = async () => {
    return Promise.resolve(localStorage.getItem(JWT_TOKEN));
}

export const getRefreshToken = async () => {
    return Promise.resolve(localStorage.getItem(REFRESH_TOKEN));
}

export const setTokens = async (jwtToken, refreshToken) => {
    localStorage.setItem(JWT_TOKEN, jwtToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    return Promise.resolve();
};

export const clearTokens = async () => {
    localStorage.removeItem(JWT_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    return Promise.resolve();
};

export const resetBiometrics = async () => {
    return Promise.resolve();
};


export const gotoLogin = async () => {
    if (!window.location.href.includes("/login")) {
        window.location.reload();
    }
    return Promise.resolve();
};

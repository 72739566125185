import isEmpty from "./isEmpty";

const hasPermission = (
    auth,
    requiredPermissions = {
        isNormalUser: false,
        isAdministrator: false,
        isManagementOperator: false,
        isStaffOperator: false,
        isCompanyAdministrator: false,
        isCompanyOperator: false,
        isCompanyNormalUser: false,
        isStoreAdministrator: false,
        isStoreOperator: false,
        isStoreNormalUser: false,
        isPartner: false,
        isDriver: false,
        isSuperHero: false,
        isAssistant: false,
    },
    ignoreNormalUser = false,
    exludePartners = false
) => {
    if (!isEmpty(auth) && auth.isAuthenticated) {
        let permissions = [];

        //todo: Force email verification.
        if (!auth.currentUser.user.emailConfirmed && !(auth.currentUser.user.isCompanyNormalUser || auth.currentUser.user.isStoreNormalUser)) {
            return undefined;
        }

        let ignore = (auth.currentUser.user.isCompanyNormalUser || auth.currentUser.user.isStoreNormalUser) && ignoreNormalUser;
        let partner = (auth.currentUser.user.isPartner || auth.currentUser.user.isDriver || auth.currentUser.user.isSuperHero) && exludePartners;

        if (requiredPermissions.isNormalUser && !ignore) permissions.push(auth.currentUser.user.isNormalUser);
        if (requiredPermissions.isAdministrator) permissions.push(auth.currentUser.user.isAdministrator);
        if (requiredPermissions.isManagementOperator) permissions.push(auth.currentUser.user.isManagementOperator);
        if (requiredPermissions.isStaffOperator) permissions.push(auth.currentUser.user.isStaffOperator);
        if (requiredPermissions.isCompanyAdministrator && !partner) permissions.push(auth.currentUser.user.isCompanyAdministrator);
        if (requiredPermissions.isCompanyOperator && !partner) permissions.push(auth.currentUser.user.isCompanyOperator);
        if (requiredPermissions.isCompanyNormalUser && !partner) permissions.push(auth.currentUser.user.isCompanyNormalUser);
        if (requiredPermissions.isStoreAdministrator) permissions.push(auth.currentUser.user.isStoreAdministrator);
        if (requiredPermissions.isStoreOperator) permissions.push(auth.currentUser.user.isStoreOperator);
        if (requiredPermissions.isStoreNormalUser) permissions.push(auth.currentUser.user.isStoreNormalUser);
        if (requiredPermissions.isPartner) permissions.push(auth.currentUser.user.isPartner);
        if (requiredPermissions.isDriver) permissions.push(auth.currentUser.user.isDriver);
        if (requiredPermissions.isPublicApiUser) permissions.push(auth.currentUser.user.isPublicApiUser);
        if (requiredPermissions.isSuperHero) permissions.push(auth.currentUser.user.isSuperHero);
        if (requiredPermissions.isAssistant) permissions.push(auth.currentUser.user.isAssistant);
        return permissions.includes(true);
    } else {
        return false;
    }
}

export default hasPermission;
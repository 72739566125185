import { GET_DRIVER_APPLICATIONS, CLEAR_DRIVER_APPLICATIONS, CLEAR_DRIVER_APPLICATIONS_SUCCESS, DELETE_DRIVER_APPLICATIONS } from '../_actions/types';
import { DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: ""
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case GET_DRIVER_APPLICATIONS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case CLEAR_DRIVER_APPLICATIONS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_DRIVER_APPLICATIONS: 
            return {
                ...state,
                data: {},
                success: ''
            }
        case DELETE_DRIVER_APPLICATIONS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} drivers.`
            }
        default: 
            return state;
    }
}
import {
    ADMIN_CONTAINER_HEIGHT
} from '../../wumdrophubsreactshared/_constants/styleConstants';

export default theme => ({
    root: {
        height: ADMIN_CONTAINER_HEIGHT,
        overflowY: 'auto',
        margin: 20
    },
    loader: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh'
    }
});
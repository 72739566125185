import isEmpty from '../_utils/isEmpty';
import convertUtcToLocalDate from './convertUtcToLocalDate';

const mapQuoteToDelivery = (quoteResponse, timeZone) => {

    if (isEmpty(timeZone)) {
        timeZone = "Africa/Johannesburg";
    }

    let deliveryStepValues = {
        deliveryId: null,
        originAddress: null,
        destinationAddress: null,
        deliveryDate: '',
        deliverByDate: '',
        deliveryTime: '',
        isRoundTrip: false,
    }
    if (!isEmpty(quoteResponse)) {
        deliveryStepValues.deliveryId = quoteResponse.deliveryId;
        deliveryStepValues.originAddress = {
            placeId: quoteResponse.originAddress.placeId,
            description: quoteResponse.originAddress.description,
            coordinates: quoteResponse.originAddress.coordinates
        };
        deliveryStepValues.destinationAddress = {
            placeId: quoteResponse.destinationAddress.placeId,
            description: quoteResponse.destinationAddress.description,
            coordinates: quoteResponse.destinationAddress.coordinates
        };

        deliveryStepValues.originStoreId = quoteResponse.originStoreId;
        deliveryStepValues.originStoreName = quoteResponse.originStoreName;
        if (!isEmpty(quoteResponse.originStoreId)) {
            deliveryStepValues.originAddress.storeId = quoteResponse.originStoreId;
        }

        deliveryStepValues.destinationStoreId = quoteResponse.destinationStoreId;
        deliveryStepValues.destinationStoreName = quoteResponse.destinationStoreName;
        if (!isEmpty(quoteResponse.destinationStoreId)) {
            deliveryStepValues.destinationAddress.storeId = quoteResponse.destinationStoreId;
        }

        let formattedDate = quoteResponse.readyForCollectionDateOnlyUtc;
        if (formattedDate.charAt(formattedDate.length - 1) !== 'Z') {
            formattedDate = `${quoteResponse.readyForCollectionDateOnlyUtc}Z`;
        }

        deliveryStepValues.deliveryDate = convertUtcToLocalDate(quoteResponse.readyForCollectionDateOnlyUtc, timeZone, false);
        deliveryStepValues.deliveryTime = !isEmpty(quoteResponse.readyForCollectionTimeSlotLocal) ? quoteResponse.readyForCollectionTimeSlotLocal : null;
        deliveryStepValues.isRoundTrip = quoteResponse.isRoundTrip;
    }

    return deliveryStepValues;
}
export default mapQuoteToDelivery;
import {SLATE_COLOR, TINY_FONT, TERTIARY_COLOR, SMALL_FONT} from '../../../wumdrophubsreactshared/_constants/styleConstants';

export default theme => ({
    labelText: {
        fontSize: TINY_FONT,
        fontWeight: "bold",
        paddingTop: 12,
        paddingLeft: 2,
        paddingBottom: 5,
        color: SLATE_COLOR
    },
    selectContainer: {
      //padding: 5
    },
    radioChecked: {
      color: TERTIARY_COLOR,
  },
  radioLabelSmall: {
      fontSize: SMALL_FONT
  },
});
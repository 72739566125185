import { POST_DRIVER_SCHEDULE, PUT_DRIVER_SCHEDULE, GET_DRIVER_SCHEDULES, CLEAR_DRIVER_SCHEDULE_SUCCESS, GET_DRIVER_SCHEDULE_LATEST_ACTIVE } from '../_actions/types';
import { GET_SUCCESS, POST_SUCCESS, PUT_SUCCESS } from '../_constants/stringConstants';


const initialState = {
    data: {},
    success: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_DRIVER_SCHEDULES:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case GET_DRIVER_SCHEDULE_LATEST_ACTIVE:
            return {
                ...state,
                data: action.payload,
                success: `${GET_SUCCESS} user schedule`
            }
        case POST_DRIVER_SCHEDULE:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} user schedule`
            }
        case PUT_DRIVER_SCHEDULE:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} user schedule`
            }
        case CLEAR_DRIVER_SCHEDULE_SUCCESS:
            return {
                ...state,
                success: ''
            }
        default:
            return state;
    }
}
/* eslint-disable no-unused-vars */
import {
    SET_JOB_DETAILS,
    GET_JOB_DETAILS,
    GET_JOB_DETAILS_DRIVER,
    GET_JOB_DETAILS_DELIVERIES,
    CLEAR_JOB_DETAILS,
    GET_JOB_DETAILS_TRANSACTION_ITEMS,
    SET_LOADING_JOB_DETAILS_DELIVERIES
} from '../_actions/types';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    job: {},
    driver: {},
    deliveries: [],
    transactionItems: [],
    deliveriesLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_JOB_DETAILS:
            let job = !isEmpty(action.payload) ? action.payload.items[0] : {};
            return {
                ...state,
                job: job
            }
        case SET_JOB_DETAILS:
            return {
                ...state,
                job: action.payload
            }
        case GET_JOB_DETAILS_DRIVER:
            let user = !isEmpty(action.payload) ? action.payload.items[0] : {};
            return {
                ...state,
                driver: user
            }
        case GET_JOB_DETAILS_DELIVERIES:
            return {
                ...state,
                deliveries: action.payload,
                deliveriesLoading: false
            }
        case GET_JOB_DETAILS_TRANSACTION_ITEMS:
            return {
                ...state,
                transactionItems: action.payload
            }
        case CLEAR_JOB_DETAILS:
            if (action.payload === "job") {
                return {
                    ...state,
                    job: {}
                }
            }
            else if (action.payload === "driver") {
                return {
                    ...state,
                    driver: {}
                }

            } else if (action.payload === "deliveries") {
                return {
                    ...state,
                    deliveries: []
                }
            } else if (action.payload === "transactionItems") {
                return {
                    ...state,
                    deliveries: []
                }
            } else {
                return {
                    ...state,
                    job: {},
                    driver: {},
                    deliveries: []
                }
            }
        case SET_LOADING_JOB_DETAILS_DELIVERIES:
            return {
                ...state,
                deliveriesLoading: action.payload
        }    
        default:
            return state;
    }
}
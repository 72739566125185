/* eslint-disable no-unused-vars */
import {
    CLEAR_DELIVERIES_SUCCESS,
    CLEAR_DELIVERIES,
    GET_DELIVERIES,
    DELETE_DELIVERIES,
    POST_DELIVERIES,
    PUT_DELIVERIES,
    PUT_DELIVERIES_DUE_DATE,
    UPDATE_DELIVERIES,
    GET_DELIVERIES_BY_ID,
    NEW_DELIVERIES,
    GET_DELIVERIES_AGGREGATIONS,
    GET_SINGLE_DELIVERY_AND_UPSERT,
    SET_LOADING_DELIVERIES,
    GET_DELIVERIES_BY_HASH_ID, SYNC_DELIVERY
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    data: {},
    success: "",
    loading: false,
    loaded: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_DELIVERIES:

            //here we do some preprocessing to key items by id
            return {
                ...state,
                data: {
                    ...action.payload, items: action.payload.items.reduce((obj, item) => {
                        obj[`#${item.id}`] = item; //here we key on #id so as not to lose ordering from backend
                        return obj;
                    }, {})
                },
                search: !isEmpty(action.search) ? action.search : "",
                loading: false,
                loaded: true
            }
        case GET_DELIVERIES_BY_ID:
            return {
                ...state,
                data: action.payload,
                search: action.search,
                loading: false
            }

        case GET_DELIVERIES_BY_HASH_ID:
            return {
                ...state,
                data: action.payload,
                loading: false,
                loaded: true
            }

        case GET_DELIVERIES_AGGREGATIONS:
            return {
                ...state,
                data: {
                    ...state.data,
                    aggregationDictionary: action.payload.aggregationDictionary,
                    totalItems: action.payload.totalItems,
                    updateAggregations: false
                }
            }
        case POST_DELIVERIES:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} delivery`,
                loading: false
            }
        case PUT_DELIVERIES:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} delivery`,
                loading: false
            }
            case PUT_DELIVERIES_DUE_DATE:
                return {
                    ...state,
                    success: `${PUT_SUCCESS} delivery`,
                    loading: false
                }
        case SYNC_DELIVERY:
            return {
                ...state,
                success: `Successfully synced delivery`,
                loading: false
            }
        case DELETE_DELIVERIES:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} deliveries.`,
                loading: false
            }
        case SET_LOADING_DELIVERIES:
            return {
                ...state,
                loading: action.payload
            }
        case CLEAR_DELIVERIES_SUCCESS:
            return {
                ...state,
                success: ''
            }

        case CLEAR_DELIVERIES:
            return {
                ...state,
                data: {},
                success: '',
                loaded: false
            }

        case UPDATE_DELIVERIES: //this is for updating deliveries in redux with data from signalR
            //here action.payload is hash map (i.e., Map()) of key = deliveryId, value = delivery object
            let deliveries = Object.assign({}, state.data.items);

            let existingDeliveries = [];
            if (!isEmpty(state.data.existing)) {
                existingDeliveries = [...state.data.existing];
            }

            for (const [key, value] of action.payload.entries()) {
                if (deliveries[key]) {
                    deliveries[key] = value;
                } else if (isEmpty(state.data.existing) || !state.data.existing.includes(key)) {
                    if (isEmpty(state.data.new) || !state.data.new.includes(key))
                        existingDeliveries.push(key);
                }
            }

            return {
                ...state,
                data: { ...state.data, items: deliveries, existing: existingDeliveries, updateAggregations: true }
            }

        case NEW_DELIVERIES: //this is for adding new deliveries message in redux with data from signalR
            //here action.payload is hash set (i.e., Set()) of key = deliveryId

            let newDeliveries = [];
            if (!isEmpty(state.data.new)) {
                newDeliveries = [...state.data.new];
            }

            for (const value of action.payload.values()) {
                newDeliveries.push(value);
            }

            return {
                ...state,
                data: { ...state.data, new: newDeliveries, updateAggregations: true }
            }

        case GET_SINGLE_DELIVERY_AND_UPSERT: // this is for fetching a single delivery and upserting into redux
            let updatedDeliveries = Object.assign({}, state.data.items);
            updatedDeliveries[`#${action.payload.id}`] = action.payload;

            return {
                ...state,
                data: { ...state.data, items: updatedDeliveries }
            }

        default:
            return state;
    }
}
import {BLACK_COLOR, PRIMARY_COLOR} from "wumdrophubsreactshared/_constants/styleConstants";
export default theme => ({
    formControl: {
        paddingTop: 0
    },
    heading: {
        fontSize: "0.9em",
        fontWeight: 600,
        color: PRIMARY_COLOR,
    },
    gridItem: {
        paddingTop: 12,
        paddingBottom: "0 !important"
    },
    inputTextField: {
        fontSize: "12px",
        "&::placeholder": {
            fontSize: "1em",
        }
    },
    expansionPanelSummary: {
        marginTop: 0,
        marginBottom: 0
    },
    infoIcons: {
        color: BLACK_COLOR,
        fontSize: "17px",
    },
});
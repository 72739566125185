//CRUD messages
export const GET_SUCCESS = "Successfully loaded";
export const POST_SUCCESS = "Successfully created";
export const PUT_SUCCESS = "Successsfully updated";
export const DELETE_SUCCESS = "Successsfully deleted";

//Error code messages
export const ERROR_DEFAULT = "An error occured communicating with the server.";
export const ERROR_500 = "Oops, we were unable to complete your request. Please contact support if the issue persists.";
export const ERROR_401 = "Unauthorized request. Please login and try again.";
export const ERROR_403 = "Access denied. You don't have the appropriate permissions to perform the current action";
export const ERROR_404 = "Not found";
export const ERROR_413 = "Unable to upload image. Image size is too large.";
export const ERROR_CONNECTION = "Unable to connect to the server. Reconnecting in ";

//SLA explanations
export const ON_DEMAND_TEXT = "Delivered within 30 mins. of your chosen time/date.";
export const SAME_DAY_TEXT = "Delivered within 24 hours of your chosen time/date.";
export const SLA_EXPLANATION = "We currently offer two service level agreements: On demand and Same day. On demand means we will deliver your package(s) within a 30 minute window of your chosen date/time. Same day means time accuracy will be ignored and we will only commit to delivering your package(s) within 24 hours of your chosen date/time.";

//quote loading message
export const QUOTE_LOADING_MESSAGE = "Hold on while we find the best price for your delivery...";

//currency symbols
export const CURRENCY_RANDS = "R";

//payment messages on DELIVERY cards
export const DELIVERY_CARD_PAY_NOW = "Pay now";
export const DELIVERY_CARD_ACCEPT_NOW = "Accept now";
export const DELIVERY_CARD_CHOOSE_QUOTE = "Choose a quote";
export const DELIVERY_CARD_ADD_PARCELS = "Add parcels";
export const DELIVERY_CARD_COMPLETE_ORDER = "Complete order";
export const DELIVERY_CARD_ASSIGN_TO_JOB = "Assign to job";
export const DELIVERY_CARD_GO_TO_JOB = "Go to jobs";

//filterby and sortby labels
export const FILTER_BY = "Filter by";
export const SORT_BY = "Sort by";

//alphabet we use to label pick-up and drop-off icons for deliveries and jobs on dashboard
export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from './registerServiceWorker';
import store from "./wumdrophubsreactshared/_utils/store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getConfig } from "wumdrophubsreactshared/_utils/config";

let config = getConfig();

ReactDOM.render(
  <div>
    <GoogleOAuthProvider clientId={config.REACT_APP_GOOGLE_ID}>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </div>,
  document.getElementById("root")
);
// We dont need offline mode for this web app as we have an app
registerServiceWorker();

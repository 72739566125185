import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import { BLUE_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, WHITE_COLOR, TERTIARY_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';
import Transition from './Transiton';


function NotificationDialog({ open, closeText, fullScreen, maxWidth, onCloseClick, title, children, subtitle, severity }) {
  const classes = useStyles();
  let close = !isEmpty(closeText) ? closeText : "Okay";

  let btnColor = "primary";
  let btnVarient = "outlined";
  let color = WHITE_COLOR;
  let background =title === 'Warning!' ? TERTIARY_COLOR : PRIMARY_COLOR;
  if (severity === "error") {
    btnColor = "secondary";
    btnVarient = "outlined";
    background = SECONDARY_COLOR;
  }

  if (severity === "info") {
    background = BLUE_COLOR;
  }

  return (
    <Dialog
      open={open}
      onClose={onCloseClick}
      fullScreen={isEmpty(fullScreen) ? false : fullScreen}
      maxWidth={!isEmpty(maxWidth) ? maxWidth : false}
      aria-labelledby="notification-dialog-title"
      aria-describedby="notification-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.title} style={{ background: background, color: color }}>{title.toUpperCase()} <span className={classes.subtitle}><br />{subtitle}</span></DialogTitle>
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button fullWidth onClick={onCloseClick} color={btnColor} variant={btnVarient} autoFocus>
          {close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  title: {
    fontWeight: "bold !important",
    textAlign: "center",
    padding: 20
  },
  content: {
    marginTop: 25
  },
  subtitle: {

  },
  actions: {
    justifyContent: "center",
    textAlign: "center",
    padding: 25
  }
});

export default NotificationDialog;
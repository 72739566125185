import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './TransactionSummary.css';
import localiseCurrency from 'wumdrophubsreactshared/_utils/localiseCurrency';
import { Table, TableRow, TableBody, TableCell, Grid, Divider, Typography, Paper } from '@material-ui/core';
import isEmpty from "../../../wumdrophubsreactshared/_utils/isEmpty";
// import ParcelIcon from "../../icons/ParcelIcon.svg";
import combineStyles from "../../../wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "../../../App.css";
import DebitAndCredit from '../DebitAndCredit';
import { TRANSACTION_ITEM_TYPE_ID_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';

const combinedStyles = combineStyles(appStyles, styles);
const useStyles = makeStyles(combinedStyles);

function TransactionSummary({ deliveryId, jobId, tipAmount, vatRate, transactionItems, isMobile, isForDetailsPanel, isRoundTrip, transactionItemTypeId, disableCreditDebit = false }) {
    const classes = useStyles();

    let subTotalAmount = 0;
    let transactionId = null;
    let items = [];
    if (!isEmpty(transactionItems)) {
        //client side filter on transactionItemTypeId
        if (!isEmpty(transactionItemTypeId)) {
            items = [...transactionItems].filter((value, index, self) => value.transactionItemTypeId === transactionItemTypeId);
        }
        else {
            items = [...transactionItems];
        }

        if (isRoundTrip) {
            items.forEach(function (item, index, arr) {
                if (!item.multiplied) {
                    arr[index].quantity = item.quantity * 2;
                    arr[index].amountExcl = item.amountExcl * 2;
                    arr[index].multiplied = true;
                }
            });
        }

        if (!isEmpty(items)) {
            subTotalAmount = items.map(item => item.amountExcl).reduce((a, b) => a + b);
            let distinct = items.map(item => item.transactionId).filter((value, index, self) => self.indexOf(value) === index);
            transactionId = !isEmpty(distinct) && distinct.length === 1 ? distinct[0] : null;
        }

    }
    let taxRate = vatRate * 100;
    let taxAcount = subTotalAmount * vatRate;
    let totalDueAmout = subTotalAmount + taxAcount;

    let subtotalText = transactionItemTypeId !== TRANSACTION_ITEM_TYPE_ID_ENUMS.JOB ? "Subtotal" : "Total (VAT Excl)"

    const renderExpenseSummary = (
        <div className={classes.detailsContainer}>
            <div className={classes.textRow}>
                <span className={classes.textTotal}>{subtotalText}</span>
                <span className={`${classes.textValue} ${classes.textBold}`}>{localiseCurrency(subTotalAmount)}</span>
            </div>
            {transactionItemTypeId !== TRANSACTION_ITEM_TYPE_ID_ENUMS.JOB && // don't show vat amount on job transaction items
                <div className={classes.textRow}>
                    <span className={classes.textTotal}>VAT ({taxRate}%)</span>
                    <span className={`${classes.textValue} ${classes.textBold}`}>{localiseCurrency(taxAcount)}</span>
                </div>
            }

            <div className={classes.textRow}>
                {
                    transactionItemTypeId !== TRANSACTION_ITEM_TYPE_ID_ENUMS.JOB && isRoundTrip ?
                        <Fragment>
                            <span className={classes.textTotal}>Total</span><span className={classes.textTotalRoundTrip}> </span>
                            <span className={`${classes.textValue} ${classes.textBold}`}>{localiseCurrency(totalDueAmout)}</span>
                        </Fragment>
                        :
                        transactionItemTypeId !== TRANSACTION_ITEM_TYPE_ID_ENUMS.JOB &&
                        <Fragment>
                            <span className={classes.textTotal}>Total</span>
                            <span className={`${classes.textValue} ${classes.textBold}`}>{localiseCurrency(totalDueAmout)}</span>
                        </Fragment>
                }
            </div>
        </div>
    )

    const renderMobileDisplay = (
        isEmpty(items) ?
            <Typography>Transaction items not loaded</Typography>
            :
            <div>
                {/* <Typography className={classes.summaryTitle}>
                Order Summary
            </Typography> */}
                {items.map((item, index) => (
                    <div key={index}>
                        <div className={classes.textRow}>
                            {/* <img src={ParcelIcon} alt="Parcel Icon" className={classes.tileIcon} /> */}
                            <span className={classes.textKeyQty}>{item.quantity}x</span>
                            <span className={classes.textKey}>{!isForDetailsPanel ? item.description.split("-")[0] : item.description}</span>
                            <span className={classes.textValue}>{localiseCurrency(item.amountExcl)}</span>
                        </div>
                        <Divider light className={classes.textDivider} />
                    </div>
                ))}
                {renderExpenseSummary}
            </div>
    )
    const renderDisplay = (
        isEmpty(items) ?
            <Typography>Transaction items not loaded</Typography>
            :
            <Grid container>

                <Grid item xs={12} className={classes.gridItem}>
                    {/* <Typography className={classes.summaryTitle}>
                    Order summary
                </Typography> */}
                    <Table className={classes.table}>
                        <TableBody>
                            {items.map((item, index) => (
                                <TableRow key={item.id}>
                                    <TableCell align="left">
                                        {/* <img src={ParcelIcon} alt="Parcel Icon" className={classes.tileIcon} /> */}
                                        <span className={classes.textKeyQty}>{item.quantity}x</span>
                                        <span className={classes.textKey}>{!isForDetailsPanel ? item.description.split("-")[0] : item.description}</span>
                                        {
                                            isRoundTrip && !item.description.toLowerCase().includes("area surcharge") &&
                                            <Typography gutterBottom className={classes.roundTripText}>
                                                Includes collect on delivery charge
                                            </Typography>
                                        }
                                    </TableCell>
                                    <TableCell align="right"><span className={classes.textAmount}>{localiseCurrency(item.amountExcl)}</span></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>


                <Grid item xs={12} className={classes.gridItem}>
                    {renderExpenseSummary}
                    {!disableCreditDebit && <DebitAndCredit transactionId={transactionId} transactionItemTypeId={transactionItemTypeId} deliveryId={deliveryId} jobId={jobId} currentAmountExcl={subTotalAmount} />}
                </Grid>

                <Grid item xs={12} className={classes.gridItem}>
                    <Paper elevation={1} className={classes.driverTip}>
                        <span className={classes.textKey}>{"Driver tip"}</span>
                        <span className={`${classes.textValue} ${classes.textBold}`} style={{ marginLeft: "auto" }}>{localiseCurrency(tipAmount)}</span>
                    </Paper>
                </Grid>
            </Grid>
    )

    return (
        <Fragment>
            <div className={isForDetailsPanel === true ? classes.componentDetailsContainer : classes.componentContainer}>{isMobile ? renderMobileDisplay : renderDisplay}</div>
        </Fragment>
    );
}

export default TransactionSummary;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './SlaCard.css';
import { AccessTime, CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import { Typography, Tooltip } from '@material-ui/core';
import ListItemText from "@material-ui/core/ListItemText";
import combineStyles from "../../../wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "../../../App.css";
import hasFinancePermission from 'wumdrophubsreactshared/_utils/hasFinancePermission';

const combinedStyles = combineStyles(styles, appStyles);
const useStyles = makeStyles(combinedStyles);


function SlaCard(props) {
    const {
        auth,
        selectOrderText,
        slaTypeLabel,
        deliveryDateText,
        index,
        selectedIndex,
        pickUpStartTime,
        pickUpEndTime,
        dropOffStartTime,
        dropOffEndTime,
        totalQuotePrice,
        expirationText,
        selectable = true,
        isRoundTrip = false,
        isHighRisk = false
    } = props;
    const classes = useStyles();

    let priceDifference = isRoundTrip || isHighRisk ?
        <Tooltip title="Subject addtional charge(s)" placement="top">
            <sup className={classes.priceDifference}> *</sup>
        </Tooltip>
        : null;

    return (
        <Grid container className={classes.gridRoot} spacing={0}>
            {selectOrderText && selectedIndex === index &&
                <Grid item xs={12} md={12}>
                    <Typography className={classes.deliveryTimeConfirmation}>
                        {selectOrderText}
                    </Typography>
                </Grid>
            }
            <Grid item xs={12} md={4}>
                {selectable &&
                    <div>
                        {selectedIndex === index ?
                            <CheckCircle className={classes.checkMark} /> :
                            <RadioButtonUnchecked className={classes.checkMark} />}
                    </div>
                }
                <ListItemText
                    primary={slaTypeLabel}
                    secondary={deliveryDateText}
                    className={classes.slaDescription}
                    classes={{ primary: classes.listItemText }}
                    key={`${index}l1`}
                />
            </Grid>
            <Grid item xs={12} md={5}>
                <div className={classes.timeWindowSection}>
                    <Typography className={classes.timeWindowText}>Pick-up &nbsp;<AccessTime
                        className={classes.timeIcon} /> <span
                            className={classes.timeHoursLarge}>{pickUpStartTime} to {pickUpEndTime}</span></Typography>
                    <Typography className={classes.timeWindowText}>Drop-off <AccessTime className={classes.timeIcon} />
                        <span className={classes.timeHoursLarge}>{dropOffStartTime} to {dropOffEndTime}</span></Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                {
                    hasFinancePermission(auth) ?
                        <ListItemText
                            primary={
                                <Typography className={`${classes.price} ${classes.priceAlignment}`}>
                                    {totalQuotePrice} 
                                    {priceDifference}
                                </Typography>
                            }
                            secondary={expirationText}
                            classes={{
                                secondary: classes.expiryText
                            }}
                            key={`${index}l2`}
                        />
                        :
                        <ListItemText
                            primary={expirationText}
                            classes={{
                                primary: classes.expiryText
                            }}
                            key={`${index}l2`}
                        />
                }

            </Grid>
        </Grid>
    );
}

export default SlaCard;
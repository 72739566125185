import { PARCEL_TYPES_ENUMS } from "../_constants/apiConstants";
import isEmpty from "./isEmpty";

const getParcelsCountString = (items) => {
    let small = 0;
    let medium = 0;
    let large = 0;
    let extraLarge = 0;
    let packagesString = "";

    if (isEmpty(items)) return packagesString;

    items.forEach(item => {
        if (item.parcelType === PARCEL_TYPES_ENUMS.SMALL) {
            small++;
        }
        if (item.parcelType === PARCEL_TYPES_ENUMS.MEDIUM) {
            medium++;
        }
        if (item.parcelType === PARCEL_TYPES_ENUMS.LARGE) {
            large++;
        }
        if (item.parcelType === "Extra Large") {
            extraLarge++;
        }
    });

    if (small > 0) {
        packagesString += small + " sm";
    }
    if (medium > 0) {
        packagesString += packagesString ? ", " + medium + " md" : medium + " md";
    }
    if (large > 0) {
        packagesString += packagesString ? ", " + large + " lg" : large + " lg";
    }
    if (extraLarge > 0) {
        packagesString += packagesString ? ", " + extraLarge + " l*" : extraLarge + " l*";
    }

    return packagesString;
}

export default getParcelsCountString;

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "./AdvancedDetails.css";

import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import { AccountBoxOutlined, ReceiptOutlined } from "@material-ui/icons";
import combineStyles from "../../../wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "../../../App.css";

import TextField from "@material-ui/core/TextField";

const combinedStyles = combineStyles(styles, appStyles);
const useStyles = makeStyles(combinedStyles);

const AdvancedDetails = (props) => {
  const { values, errors, touched, handleChange, handleBlur } = props;

  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      className={classes.gridContainer}
      alignItems="flex-start"
      direction="row"
      justifyContent="flex-start"
    >
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        className={classes.gridItemPadding}
      >
        <TextField
          id="orderReference"
          error={errors.orderReference && touched.orderReference}
          success={!errors.orderReference && touched.orderReference}
          fullWidth
          InputProps={{
            style: { fontSize: "14px" },
            autoComplete: "off",
            placeholder: "Order reference",
            type: "text",
            name: "orderReference",
            value: values.orderReference,
            onChange: handleChange,
            onBlur: handleBlur,
            startAdornment: (
              <InputAdornment position="start">
                <ReceiptOutlined className={classes.infoIcons} />
              </InputAdornment>
            ),
            classes: { input: classes.inputTextField },
          }}
        />
 
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          id="customerIdentifier"
          error={errors.customerIdentifier && touched.customerIdentifier}
          success={!errors.customerIdentifier && touched.customerIdentifier}
          fullWidth
          InputProps={{
            style: { fontSize: "14px" },
            autoComplete: "off",
            placeholder: "Customer identifier",
            type: "text",
            name: "customerIdentifier",
            value: values.customerIdentifier,
            onChange: handleChange,
            onBlur: handleBlur,
            startAdornment: (
              <InputAdornment position="start">
                <AccountBoxOutlined className={classes.infoIcons} />
              </InputAdornment>
            ),
            classes: { input: classes.inputTextField },
          }}
        />

      </Grid>
    </Grid>
  );
};

export default AdvancedDetails;
